import { type FC, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import useFontFaceObserver from 'use-font-face-observer';

import { getUserApi, postUserApi } from '@/api/client';
import ProgressBar from '@/components/ProgressBar';
import {
    ScreenName,
    useBackgroundImage,
    useContent,
    useGameProgress,
    useLabels,
    useNavigation,
    useSettings
} from '@/context';
import { commonStyles } from '@/styles/commonStyles';

import whichLanguageImage from '../../assets/images/landscapes/onboarding/which-language.png';
import splashImage from '../../assets/images/splash.png';
import splashLogoImage from '../../assets/images/splash-logo.png';

const PROGRESS_BAR_DELAY_MS = 700;
const MIN_LOADING_TIME_MS = 2000;
const logoWidth = window.innerWidth * 0.2;

const SplashScreen: FC = () => {
    const { applyCurrentLandscape, setBackgroundImage } = useBackgroundImage();
    const { navigate } = useNavigation();
    const { loadLabels } = useLabels();
    const { settings, loadSettings } = useSettings();
    const { gameProgress, loadGameProgress } = useGameProgress();
    const { loadContent } = useContent();

    const [isProgressBarDelayFinished, setIsProgressBarDelayFinished] =
        useState(false);
    const [isAnimationDone, setIsAnimationDone] = useState(false);
    const [isUserAvailable, setIsUserAvailable] = useState(false);
    const [isLabelsLoadingFinished, setIsLabelsLoadingFinished] =
        useState(false);
    const [isGameSettingsLoadingFinished, setIsGameSettingsLoadingFinished] =
        useState(false);
    const [isContentLoadingFinished, setIsContentLoadingFinished] =
        useState(false);
    const [isGameProgressLoadingFinished, setIsGameProgressLoadingFinished] =
        useState(false);
    const [progressBarValue, setProgressBarValue] = useState(0);
    const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);
    const [logoAspectRatio, setLogoAspectRatio] = useState(1);

    const logoRef = useRef<HTMLImageElement>(null);

    const fontsLoaded = useFontFaceObserver([
        { family: 'Nunito' },
        { family: 'Nunito Sans' },
        { family: 'Roboto Mono' }
    ]);

    useEffect(() => {
        setTimeout(
            () => setIsProgressBarDelayFinished(true),
            PROGRESS_BAR_DELAY_MS
        );
    }, []);

    useEffect(() => {
        if (isBackgroundLoaded) {
            setTimeout(() => setIsAnimationDone(true), MIN_LOADING_TIME_MS);
        }
    }, [isBackgroundLoaded]);

    useEffect(() => {
        if (!isProgressBarDelayFinished) return;

        const flags = [
            isAnimationDone,
            fontsLoaded,
            isUserAvailable,
            isLabelsLoadingFinished,
            isGameSettingsLoadingFinished,
            isContentLoadingFinished,
            isGameProgressLoadingFinished
        ];
        const trueFlagsCount = flags.filter(Boolean).length;
        const progressBarValue = trueFlagsCount / flags.length;

        setProgressBarValue(progressBarValue);

        if (trueFlagsCount === flags.length) {
            let nextScreen: ScreenName;

            // check if onboarding is done and set background image beforehand to avoid flickering
            if (
                settings.studyLanguage &&
                settings.uiLanguage &&
                settings.languageLevel
            ) {
                applyCurrentLandscape();
                console.log('SplashScreen gameProgress', gameProgress);
                if (gameProgress.gameLevel > 1) {
                    nextScreen = 'ChooseCategory';
                } else {
                    nextScreen = 'Hello';
                }
            } else {
                setBackgroundImage(whichLanguageImage);
                nextScreen = 'ChooseStudyLanguage';
            }

            setTimeout(() => {
                navigate(nextScreen);
            }, 400);
        }
    }, [
        gameProgress?.gameLevel,
        isProgressBarDelayFinished,
        isAnimationDone,
        fontsLoaded,
        isLabelsLoadingFinished,
        isGameSettingsLoadingFinished,
        isContentLoadingFinished,
        isGameProgressLoadingFinished
    ]);

    useEffect(() => {
        if (!isGameSettingsLoadingFinished) return;
        loadContent(settings.uiLanguage, settings.studyLanguage, () =>
            setIsContentLoadingFinished(true)
        );
    }, [isGameSettingsLoadingFinished]);

    useEffect(() => {
        if (!isContentLoadingFinished || !settings) {
            return;
        }

        if (!settings.studyLanguage) {
            console.log(
                'No study language selected yet. Skipping game progress loading.'
            );
            setIsGameProgressLoadingFinished(true);
            return;
        }

        loadGameProgress(settings.studyLanguage, () =>
            setIsGameProgressLoadingFinished(true)
        );
    }, [settings?.studyLanguage, loadGameProgress, isContentLoadingFinished]);

    useEffect(() => {
        const checkUserAvailability = async () => {
            try {
                const response = await getUserApi();

                if (!response) {
                    // No Content - User doesn't exist, so we need to create one
                    console.log('User not found, creating new user...');
                    const postUserResponse = await postUserApi();
                    if (postUserResponse) {
                        console.log('New user created:', postUserResponse);
                        setIsUserAvailable(true); // User creation successful
                    } else {
                        console.error('Error: Failed to create user.');
                    }
                } else {
                    // User exists
                    console.log('User found:', response);
                    setIsUserAvailable(true);
                }
            } catch (error) {
                console.error(
                    'Error fetching user or creating new user:',
                    error
                );
            }
        };

        checkUserAvailability();
    }, []);

    useEffect(() => {
        if (!isUserAvailable) return;
        loadLabels(() => setIsLabelsLoadingFinished(true));
        loadSettings(() => setIsGameSettingsLoadingFinished(true));
    }, [isUserAvailable]);

    const onLogoLoad = () => {
        if (logoRef.current) {
            const { naturalWidth, naturalHeight } = logoRef.current;
            setLogoAspectRatio(naturalWidth / naturalHeight);
        }
        setIsBackgroundLoaded(true);
    };

    return (
        <div className={commonStyles.fullScreen}>
            <div className={commonStyles.centeredContent}>
                <motion.img
                    src={splashImage}
                    onLoad={() => setIsBackgroundLoaded(true)}
                    className={`${commonStyles.fullScreen} object-cover`}
                    initial={{ opacity: 0, scale: 1 }}
                    animate={{ opacity: 1, scale: 1.1 }}
                    transition={{ duration: MIN_LOADING_TIME_MS / 1000 }}
                />
                <div className={commonStyles.backgroundOverlay}></div>
                {isBackgroundLoaded && (
                    <motion.img
                        ref={logoRef}
                        src={splashLogoImage}
                        onLoad={onLogoLoad}
                        className="relative"
                        style={{
                            width: logoWidth,
                            height: logoWidth / logoAspectRatio
                        }}
                        initial={{ opacity: 0, scale: 1 }}
                        animate={{ opacity: 1, scale: 1.15 }}
                        transition={{ duration: MIN_LOADING_TIME_MS / 1000 }}
                    />
                )}
                <motion.div
                    className="absolute bottom-20 w-52"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: MIN_LOADING_TIME_MS / 1000 }}
                >
                    <ProgressBar
                        progressBarValue={progressBarValue}
                        height="h-2.5"
                    />
                </motion.div>
            </div>
        </div>
    );
};

export default SplashScreen;
