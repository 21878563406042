import { type FC, useEffect, useRef, useState } from 'react';

interface AutoScalingTextProps {
    text: string;
    maxFontSize: number;
    maxLines?: number;
    style?: React.CSSProperties;
}

const AutoScalingText: FC<AutoScalingTextProps> = ({
    text,
    maxFontSize,
    maxLines = 1,
    style
}) => {
    const [fontSize, setFontSize] = useState(maxFontSize);
    const textRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const adjustFontSize = () => {
            const element = textRef.current;
            if (!element) return;

            let currentFontSize = maxFontSize;
            element.style.fontSize = `${currentFontSize}px`;

            while (
                (element.scrollHeight > element.clientHeight ||
                    element.scrollWidth > element.clientWidth) &&
                currentFontSize > 1
            ) {
                currentFontSize -= 1;
                element.style.fontSize = `${currentFontSize}px`;
            }

            setFontSize(currentFontSize);
        };

        adjustFontSize();
    }, [text, maxFontSize]);

    return (
        <div
            ref={textRef}
            className="w-full"
            style={{
                ...style,
                fontSize: `${fontSize}px`,
                whiteSpace: maxLines === 1 ? 'nowrap' : 'normal',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: maxLines,
                WebkitBoxOrient: 'vertical'
            }}
        >
            {text}
        </div>
    );
};

export default AutoScalingText;
