import { type FC, ReactNode } from 'react';
import classNames from 'classnames';

interface PanelProps {
    children: ReactNode;
    className?: string;
}

const Panel: FC<PanelProps> = ({ children, className }) => {
    return (
        <div
            className={classNames(
                'w-full rounded-2xl bg-grey bg-opacity-90 px-5 py-4',
                className
            )}
        >
            {children}
        </div>
    );
};

export default Panel;
