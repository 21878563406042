import { type FC } from 'react';

import { flagImages } from '@/utils/images';

interface RoundFlagProps {
    isoCode: string;
    size: number;
}

const RoundFlag: FC<RoundFlagProps> = ({ isoCode, size }) => {
    const lowerCaseIsoCode = isoCode.toLowerCase();
    const imageSource = flagImages[lowerCaseIsoCode];

    const imageStyle = {
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%'
    };

    if (!imageSource) {
        // Handle the case where the flag image does not exist for the provided isoCode
        return null; // Or render some placeholder
    }

    return (
        <img
            src={imageSource}
            alt={`${lowerCaseIsoCode} flag`}
            style={imageStyle}
            className="object-cover"
        />
    );
};

export default RoundFlag;
