import { type FC } from 'react';

interface Props {
    width: number;
    height: number;
    color: string;
}

const CheckIcon: FC<Props> = ({ width, height, color }: Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 57 51" fill={color}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.4177 48.4113C28.3432 48.4907 28.2668 48.569 28.1884 48.6461C25.0391 51.745 19.974 51.7042 16.8751 48.5549L2.29773 33.7406C-0.801179 30.5913 -0.760345 25.5261 2.38894 22.4272C5.53822 19.3283 10.6034 19.3692 13.7023 22.5185L21.7361 30.6829L41.8973 3.26164C44.5145 -0.298043 49.5218 -1.06207 53.0815 1.55515C56.6412 4.17237 57.4052 9.17973 54.788 12.7394L29.3165 47.3833C29.0417 47.757 28.7406 48.0999 28.4177 48.4113Z"
            />
        </svg>
    );
};

export default CheckIcon;
