import { type FC } from 'react';

interface Props {
    width: number;
    height: number;
    color: string;
}

const MagicWandIcon: FC<Props> = ({ width, height, color }: Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 102 126" fill={color}>
            <mask id="path-1-inside-1_2961_2" fill="white">
                <path
                    d="M36.5062 52.3552C34.6291 50.1181 34.9209 46.783 37.1579 44.9059C39.3949 43.0288 42.7301 43.3206 44.6072 45.5576L85.7733 94.6176C86.8383 95.8868 86.6728 97.7791 85.4036 98.8441L81.8989 101.785C80.6296 102.85 78.7374 102.684 77.6724 101.415L36.5062 52.3552Z"
                    fill="white"
                />
            </mask>
            <path
                d="M36.5062 52.3552C34.6291 50.1181 34.9209 46.783 37.1579 44.9059C39.3949 43.0288 42.7301 43.3206 44.6072 45.5576L85.7733 94.6176C86.8383 95.8868 86.6728 97.7791 85.4036 98.8441L81.8989 101.785C80.6296 102.85 78.7374 102.684 77.6724 101.415L36.5062 52.3552Z"
                fill="white"
                stroke="white"
                strokeWidth={20}
                mask="url(#path-1-inside-1_2961_2)"
            />
            <path
                d="M20.6004 66.2429C22.3413 67.75 24.8388 68.0125 26.855 66.9003C25.9598 69.0218 26.4819 71.4781 28.1626 73.052C25.8683 72.8561 23.6935 74.1118 22.7161 76.1966C22.1934 73.9541 20.3272 72.2737 18.0423 71.9884C20.0136 70.7983 21.035 68.5042 20.6004 66.2429Z"
                fill="white"
                stroke="white"
                strokeWidth={7}
            />
            <path
                d="M48.7928 19.0214L49.6525 19.7955C51.4232 21.3899 54.0192 21.6627 56.0827 20.4714L57.0846 19.8929L56.6141 20.9498C55.6449 23.1265 56.1876 25.6798 57.9584 27.2741L60.3003 24.6731L57.9584 27.2741L58.8181 28.0483L57.6676 27.9273C55.2979 27.6783 53.0373 28.9834 52.0681 31.1601L51.5976 32.217L51.3571 31.0854C50.8617 28.7547 48.9219 27.0081 46.5522 26.759L45.4016 26.6381L46.4035 26.0597C48.467 24.8683 49.5287 22.4837 49.0333 20.153L48.7928 19.0214Z"
                fill="white"
                stroke="white"
                strokeWidth={7}
            />
            <path
                d="M82.67 48.2416C81.7506 50.3815 82.2795 52.8697 83.9897 54.4506C81.6705 54.2375 79.4675 55.5094 78.4924 57.6245C77.9785 55.3529 76.0881 53.6507 73.7752 53.377C75.7768 52.1862 76.8114 49.8624 76.357 47.5781C78.1081 49.1137 80.6379 49.3796 82.67 48.2416ZM73.3886 56.3615L73.6975 53.4224L73.3886 56.3615Z"
                fill="white"
                stroke="white"
                strokeWidth={7}
            />
        </svg>
    );
};

export default MagicWandIcon;
