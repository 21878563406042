import { type FC } from 'react';

interface Props {
    width: number;
    height: number;
    color: string;
}

const CloseIcon: FC<Props> = ({ width, height, color }: Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 50 50" fill={color}>
            <path d="M47.2408 37.0429C43.5314 33.2436 39.672 29.5892 35.1278 25.1399C39.2071 21.0556 43.0315 17.2513 46.8258 13.4169C50.7552 9.44757 50.9901 5.39825 47.5407 2.0788C44.3013 -1.03568 40.4569 -0.65574 36.5026 3.25361C32.6482 7.06797 28.8189 10.8973 24.9945 14.7417C21.1701 10.9023 17.3358 7.06797 13.4864 3.25361C9.53209 -0.65574 5.68773 -1.03068 2.44827 2.0838C-1.00115 5.39825 -0.761189 9.44757 3.16315 13.4219C6.95752 17.2563 10.7819 21.0606 14.8612 25.1449C10.317 29.5942 6.4626 33.2486 2.74822 37.0479C-0.786185 40.6673 -0.881169 44.7317 2.2883 47.7761C5.53776 50.8956 9.4521 50.7507 13.0265 47.2312C17.0408 43.2769 21.0252 39.2926 24.9945 35.2932C28.9638 39.2926 32.9482 43.2769 36.9625 47.2312C40.5369 50.7507 44.4512 50.8956 47.7007 47.7761C50.8702 44.7317 50.7752 40.6673 47.2408 37.0479V37.0429Z" />
        </svg>
    );
};

export default CloseIcon;
