import HeaderWithCloseAndBackButton from '@/components/HeaderWithCloseAndBackButton';
import { useLabels, useNavigation, useProfile, useSettings } from '@/context';
import { getStudyLanguagesByUILanguage } from '@/utils/languages';

import ProfileLanguageButton from './components/ProfileLanguageButton';

const AddStudyLanguageScreen = () => {
    const { closeProfile } = useNavigation();
    const { getLabel } = useLabels();
    const { chooseNewLanguageToStudy } = useProfile();
    const { navigateToProfile } = useNavigation();
    const { settings } = useSettings();

    const studyLanguages = getStudyLanguagesByUILanguage(settings.uiLanguage);

    const availableLanguages = studyLanguages.filter(st => {
        return !settings.studyLanguages.includes(st.language.languageCode);
    });

    const showAvailableLanguages = () => {
        if (availableLanguages.length === 0) {
            return (
                <p className="mt-2.5 font-semibold leading-6 text-white">
                    {getLabel('change-language.no-languages-available')}
                </p>
            );
        }

        return (
            <div className="mt-7.5">
                {availableLanguages.map((language, index) => (
                    <ProfileLanguageButton
                        key={index}
                        disabled={settings.studyLanguages.includes(
                            language.language.languageCode
                        )}
                        onButtonPress={() => {
                            chooseNewLanguageToStudy(
                                language.language.languageCode
                            );
                            navigateToProfile('ChooseNewLanguageLevel');
                        }}
                        label={getLabel(
                            `language.${language.language.languageCode}`
                        )}
                        languageCode={language.language.languageCode}
                    />
                ))}
            </div>
        );
    };

    return (
        <div className="flex h-full flex-col">
            <HeaderWithCloseAndBackButton
                backTitle=""
                onBack={() => navigateToProfile('LanguageSettings')}
                onClose={closeProfile}
            />
            <h2 className="mb-1 text-2xl font-bold">
                {getLabel('change-language.all-languages')}
            </h2>
            {availableLanguages.length > 0 && (
                <p className="mb-8 mt-2.5 text-lg font-semibold leading-6 text-white">
                    {getLabel('change-language.press-on-the-language')}
                </p>
            )}
            <div className="overflow-auto">{showAvailableLanguages()}</div>
        </div>
    );
};

export default AddStudyLanguageScreen;
