import { type FC } from 'react';

import CloseIcon from '@/components/icons/CloseIcon';
import { useVibration } from '@/hooks/useVibration';

interface HeaderWithCloseButtonProps {
    title: string;
    onClose: () => void;
}

const HeaderWithCloseButton: FC<HeaderWithCloseButtonProps> = ({
    title,
    onClose
}) => {
    const { vibrateLight } = useVibration();

    const handleClose = () => {
        vibrateLight();
        onClose();
    };

    return (
        <div>
            <div className="flex items-center justify-between">
                <h2>{title}</h2>
                <button
                    className="p-2"
                    onClick={handleClose}
                    aria-label="Close"
                >
                    <CloseIcon width={16} height={16} color="white" />
                </button>
            </div>
            <div
                className="mt-3 border-b"
                style={{
                    borderBottomColor: 'rgba(255, 255, 255, 0.3)',
                    borderBottomWidth: '1.5px',
                    marginBottom: '12px'
                }}
            />
        </div>
    );
};

export default HeaderWithCloseButton;
