export interface Language {
    languageCode: string;
    languageNativeName: string;
}

interface StudyLanguage {
    language: Language;
    availableUILanguages: Language[];
}

const languages: { [key: string]: Language } = {
    es: {
        languageCode: 'es',
        languageNativeName: 'Español'
    },
    fi: {
        languageCode: 'fi',
        languageNativeName: 'Suomi'
    },
    en: {
        languageCode: 'en',
        languageNativeName: 'English'
    },
    ru: {
        languageCode: 'ru',
        languageNativeName: 'Русский'
    },
    de: {
        languageCode: 'de',
        languageNativeName: 'Deutsch'
    },
    it: {
        languageCode: 'it',
        languageNativeName: 'Italiano'
    },
    fr: {
        languageCode: 'fr',
        languageNativeName: 'Français'
    }
};

const studyLanguages: StudyLanguage[] = [
    {
        language: languages['es'],
        availableUILanguages: [languages['ru'], languages['en']]
    },
    {
        language: languages['fi'],
        availableUILanguages: [languages['ru'], languages['en']]
    },
    {
        language: languages['en'],
        availableUILanguages: [languages['ru']]
    },
    {
        language: languages['de'],
        availableUILanguages: [languages['ru'], languages['en']]
    },
    {
        language: languages['fr'],
        availableUILanguages: [languages['ru'], languages['en']]
    }
];

function getUniqueAvailableUILanguages(): Language[] {
    const uniqueLanguages: Language[] = [];

    studyLanguages.forEach(studyLanguage => {
        studyLanguage.availableUILanguages.forEach(uiLanguage => {
            if (
                !uniqueLanguages.some(
                    lang => lang.languageCode === uiLanguage.languageCode
                )
            ) {
                uniqueLanguages.push(uiLanguage);
            }
        });
    });

    return uniqueLanguages;
}

function getStudyLanguagesByUILanguage(
    uiLanguageCode: string
): StudyLanguage[] {
    return studyLanguages.filter(studyLanguage =>
        studyLanguage.availableUILanguages.some(
            lang => lang.languageCode === uiLanguageCode
        )
    );
}

function getAvailableUILanguagesForStudyLanguage(
    studyLanguageCode: string
): Language[] {
    const studyLanguage = studyLanguages.find(
        lang => lang.language.languageCode === studyLanguageCode
    );
    return studyLanguage ? studyLanguage.availableUILanguages : [];
}

function getLanguageByKey(languageCode: string): Language | undefined {
    return languages[languageCode];
}

export {
    getAvailableUILanguagesForStudyLanguage,
    getLanguageByKey,
    getStudyLanguagesByUILanguage,
    getUniqueAvailableUILanguages
};
