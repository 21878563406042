import { type FC } from 'react';
import classNames from 'classnames';
import { rgba } from 'polished';

import { SoundName } from '@/audio/AudioManager';
import { RoundFlag } from '@/components';
import CheckIcon from '@/components/icons/CheckIcon';
import ForwardIcon from '@/components/icons/ForwardIcon';
import emitter from '@/events/emitter';
import { COLORS } from '@/utils/colors';

interface ProfileLanguageButtonProps {
    onButtonPress: () => void;
    languageCode: string;
    label: string;
    disabled: boolean;
}

const ProfileLanguageButton: FC<ProfileLanguageButtonProps> = ({
    onButtonPress,
    languageCode,
    label,
    disabled
}) => {
    const handlePress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        onButtonPress();
    };

    return (
        <button
            onClick={handlePress}
            className={`mb-2.5 flex w-full items-center justify-between rounded-3xl border-2 border-white/20 p-3`}
            disabled={disabled}
        >
            <div className="flex items-center gap-3.5">
                <div className="rounded-full bg-gray-100 p-0.5">
                    <RoundFlag isoCode={languageCode} size={25} />
                </div>
                <span
                    className={classNames(
                        'text-xl font-extrabold tracking-wide',
                        disabled ? 'text-white/20' : 'text-white'
                    )}
                >
                    {label}
                </span>
            </div>
            {disabled ? (
                <CheckIcon
                    width={15}
                    height={15}
                    color={rgba(COLORS.white, 0.2)}
                />
            ) : (
                <ForwardIcon width={15} height={15} color={COLORS.white} />
            )}
        </button>
    );
};

export default ProfileLanguageButton;
