import mitt from 'mitt';

import { SoundName } from '@/audio/AudioManager';

import { WordInfoLocalized } from '../types/category';

export type ValidWordSelectedPayload = {
    wordId: string;
};

export type CorrectAnswerGivenPayload = {
    word: WordInfoLocalized;
};

export type AudioHintPlayWordPayload = {
    wordId: string;
};

export type AudioHintWordSelected = {
    wordId: string;
};

export type PlaySoundPayload = {
    sound: SoundName;
};

export type PlayWordAudioPayload = {
    audioUrl: string;
};

export type IsShowDevControlsChangedPayload = {
    isShowDevControls: boolean;
};

export type WordUnderReviewUpdatedPayload = {
    wordId: string;
};

export type WordSelectionChangedPayload = {
    word: string;
};

export type CorrectTranslationSelectedPayload = {
    word: WordInfoLocalized;
    cleanWord: string;
};

export type WrongTranslationSelectedPayload = {
    word: WordInfoLocalized;
};

type Events = {
    validWordSelected: ValidWordSelectedPayload;
    invalidWordSelected: void;
    correctAnswerGiven: CorrectAnswerGivenPayload;
    audioHintRequested: void;
    audioHintCannotPlay: void;
    audioHintWordSelected: AudioHintWordSelected;
    audioHintPlayWord: AudioHintPlayWordPayload;
    magicWandHintRequested: void;
    magicWandHintCannotFind: void;
    magicWandHintTileFound: void;
    magicWandHintShowTile: void;
    forceGameStepComplete: void;
    emptySpaceTap: void;
    playSound: PlaySoundPayload;
    playWordAudio: PlayWordAudioPayload;
    isShowDevControlsChanged: IsShowDevControlsChangedPayload;
    wordUnderReviewUpdated: WordUnderReviewUpdatedPayload;
    wordSelectionChanged: WordSelectionChangedPayload;
    correctTranslationSelected: CorrectTranslationSelectedPayload;
    wrongTranslationSelected: WrongTranslationSelectedPayload;
};

const emitter = mitt<Events>();
export default emitter;
