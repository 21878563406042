import {
    createContext,
    type FC,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState
} from 'react';

import categoriesData from '../../assets/content/Categories.json';
import landscapesData from '../../assets/content/Prizes.json';
import {
    CategoriesData,
    CategoryInfoLocalized,
    WordInfoLocalized
} from '../types/category';
import { LandscapeInfoLocalized, LandscapesData } from '../types/prize';
import {
    processCategoriesData,
    processLandscapesData
} from '../utils/contentHandlers';

interface ContentContextType {
    landscapes: LandscapeInfoLocalized[];
    getLandscape: (landscapeId: string) => LandscapeInfoLocalized | null;
    categories: CategoryInfoLocalized[];
    getWordsByCategory: (categoryId: string) => WordInfoLocalized[];
    loadContent: (
        uiLanguage: string,
        studyLanguage: string,
        onLoadingFinished: () => void
    ) => void;
}

interface ContentProviderProps {
    children: ReactNode;
}

const ContentContext = createContext<ContentContextType | undefined>(undefined);

export const ContentProvider: FC<ContentProviderProps> = ({ children }) => {
    const [categories, setCategories] = useState<CategoryInfoLocalized[]>([]);
    const [landscapes, setLandscapes] = useState<LandscapeInfoLocalized[]>([]);

    const loadContent = useCallback(
        (
            uiLanguage: string,
            studyLanguage: string,
            onLoadingFinished: () => void
        ) => {
            console.log('Loading content...');
            if (uiLanguage && studyLanguage) {
                setCategories(
                    processCategoriesData(
                        categoriesData as CategoriesData,
                        uiLanguage,
                        studyLanguage
                    )
                );
                setLandscapes(
                    processLandscapesData(
                        landscapesData as LandscapesData,
                        uiLanguage,
                        studyLanguage
                    )
                );
            }

            console.log('Loading content finished');
            onLoadingFinished();
        },
        [setCategories, setLandscapes]
    );

    const getLandscape = useCallback(
        (landscapeId: string): LandscapeInfoLocalized | null => {
            return (
                landscapes.find(landscape => landscape.id === landscapeId) ||
                null
            );
        },
        [landscapes]
    );

    const getWordsByCategory = useCallback(
        (categoryId: string): WordInfoLocalized[] => {
            const category = categories.find(cat => cat.id === categoryId);
            return category ? category.content : [];
        },
        [categories]
    );

    const contextValue = useMemo(
        () => ({
            loadContent,
            landscapes,
            categories,
            getLandscape,
            getWordsByCategory
        }),
        [loadContent, landscapes, categories, getLandscape, getWordsByCategory]
    );

    return (
        <ContentContext.Provider value={contextValue}>
            {children}
        </ContentContext.Provider>
    );
};

export const useContent = (): ContentContextType => {
    const context = useContext(ContentContext);
    if (context === undefined) {
        throw new Error('useContent must be used within a ContentProvider');
    }
    return context;
};
