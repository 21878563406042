import { createRef, type FC, useEffect, useRef, useState } from 'react';
import { animated } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { useSettings } from '@/context';
import useCursorAnimation from '@/hooks/useCursorAnimation';

import cursorIcon from '../../assets/images/icons/cursor.png';
import { WordInfoLocalized } from '../types/category';
import { MAX_TILE_SIZE } from '../utils/constants';
import { getCleanWord } from '../utils/language';

import GridLetterTile, { GridLetterTileHandle } from './GridLetterTile';

interface SingleWordGridHandlerProps {
    word: WordInfoLocalized;
    onWordComplete: (word: WordInfoLocalized) => void;
    vertical?: boolean;
    showCursor?: boolean;
}

const SingleWordGridHandler: FC<SingleWordGridHandlerProps> = ({
    word,
    onWordComplete,
    vertical = false,
    showCursor = false
}) => {
    const containerRef = useRef(null);
    const { moveCursor, stopCursorAnimation, cursorRef, style } =
        useCursorAnimation();
    const { settings } = useSettings();
    const initialIndexRef = useRef<number | undefined>(undefined);
    const currentIndexRef = useRef<number | undefined>(undefined);
    const selectedIndicesRef = useRef<Set<number>>(new Set());

    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [tileSize, setTileSize] = useState(0);
    const [isTileSizeCalculated, setIsTileSizeCalculated] = useState(false);

    const cleanWord = getCleanWord(word.word, settings.studyLanguage);
    const tileRefs = useRef(
        cleanWord.split('').map(() => createRef<GridLetterTileHandle>())
    );

    useEffect(() => {
        const updateSize = vertical ? containerHeight : containerWidth;
        if (updateSize > 0) {
            const potentialSize = updateSize / cleanWord.length;
            const size = Math.min(potentialSize, MAX_TILE_SIZE);
            setTileSize(size);
            setIsTileSizeCalculated(true);
        }
    }, [containerWidth, containerHeight, cleanWord.length, vertical]);

    const calculateIndex = (x: number, y: number) => {
        if (vertical) {
            const totalTilesHeight = tileSize * cleanWord.length;
            if (y >= 0 && y <= totalTilesHeight) {
                const index = Math.floor(y / tileSize);
                if (index >= 0 && index < cleanWord.length) {
                    updateSelectedIndices(index);
                }
            }
        } else {
            const totalTilesWidth = tileSize * cleanWord.length;
            const startOffset = (containerWidth - totalTilesWidth) / 2;
            const relativeX = x - startOffset;
            if (relativeX >= 0 && relativeX <= totalTilesWidth) {
                const index = Math.floor(relativeX / tileSize);
                if (index >= 0 && index < cleanWord.length) {
                    updateSelectedIndices(index);
                }
            }
        }
    };

    const animateUpdatedSelection = (
        prevIndices: Set<number>,
        newIndices: Set<number>
    ) => {
        if (newIndices.size > prevIndices.size) {
            newIndices.forEach(index => {
                if (!prevIndices.has(index)) {
                    tileRefs.current[index].current?.select();
                }
            });
        } else {
            prevIndices.forEach(index => {
                if (!newIndices.has(index)) {
                    tileRefs.current[index].current?.deselect();
                }
            });
        }
    };

    const updateSelectedIndices = (index: number) => {
        if (index < 0 || index >= cleanWord.length) return;

        let initialIndex = initialIndexRef.current;
        if (initialIndex === undefined) {
            initialIndex = index;
            initialIndexRef.current = index;
        }

        const currentIndex = index;
        currentIndexRef.current = index;

        const newIndices = new Set<number>();

        if (initialIndex <= currentIndex) {
            for (let i = initialIndex; i <= currentIndex; i++) {
                newIndices.add(i);
            }
        } else {
            for (let i = initialIndex; i >= currentIndex; i--) {
                newIndices.add(i);
            }
        }

        animateUpdatedSelection(selectedIndicesRef.current, newIndices);
        selectedIndicesRef.current = newIndices;
    };

    const onGestureEnd = () => {
        const indicesArray = Array.from(selectedIndicesRef.current);
        let isInAscendingOrder = true;

        for (let i = 0; i < indicesArray.length - 1; i++) {
            if (indicesArray[i] > indicesArray[i + 1]) {
                isInAscendingOrder = false;
                break;
            }
        }

        if (
            selectedIndicesRef.current.size === cleanWord.length &&
            isInAscendingOrder
        ) {
            tileRefs.current.forEach(ref => {
                ref.current?.remove();
            });
            if (showCursor) {
                stopCursorAnimation();
            }
            onWordComplete(word);
        } else {
            selectedIndicesRef.current.forEach(index => {
                tileRefs.current[index].current?.shake();
            });
            animateUpdatedSelection(selectedIndicesRef.current, new Set());
            selectedIndicesRef.current.clear();
        }

        initialIndexRef.current = undefined;
        currentIndexRef.current = undefined;
        selectedIndicesRef.current.clear();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bind: any = useDrag(({ xy: [x, y], last }) => {
        if (!containerRef.current) return;

        // Get container's bounding rect
        const containerRect = containerRef.current.getBoundingClientRect();

        // Calculate the coordinates relative to the container
        const relativeX = x - containerRect.left;
        const relativeY = y - containerRect.top;

        // Use these relative coordinates to calculate the tile index
        calculateIndex(relativeX, relativeY);

        if (last) onGestureEnd();
    });

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const resizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
                setContainerWidth(entry.contentRect.width);
                setContainerHeight(entry.contentRect.height);
            }
        });

        resizeObserver.observe(container);

        return () => {
            resizeObserver.unobserve(container);
        };
    }, []);

    useEffect(() => {
        if (!showCursor) return;

        let intervalId: NodeJS.Timeout | null = null;

        const showCursorHint = () => {
            const startIndex = 0;
            const endIndex = cleanWord.length - 1;

            // Ensure tile references are valid
            if (
                tileRefs.current[startIndex] &&
                tileRefs.current[startIndex].current &&
                tileRefs.current[endIndex] &&
                tileRefs.current[endIndex].current
            ) {
                moveCursor(
                    tileRefs.current[startIndex],
                    tileRefs.current[endIndex]
                );
            } else {
                console.error('Invalid tile references', startIndex, endIndex);
            }
        };

        const handleCursorAnimation = () => {
            showCursorHint();
            intervalId = setInterval(showCursorHint, 5000);
        };

        // Delay the start of the interval
        const timeoutId = setTimeout(handleCursorAnimation, 2500);

        return () => {
            // Clear both timeout and interval on cleanup to prevent memory leaks and invalid accesses
            if (intervalId) clearInterval(intervalId);
            clearTimeout(timeoutId);
        };
    }, [showCursor, cleanWord.length, moveCursor, tileRefs]);

    return (
        <motion.div
            {...bind()}
            initial={{ opacity: 0 }}
            animate={isTileSizeCalculated ? { opacity: 1 } : {}}
            transition={{ duration: 0.5 }}
            className={classNames(
                `relative flex ${vertical ? 'flex-col' : 'flex-row'} w-full touch-none justify-center`,
                { 'flex-1': !isTileSizeCalculated }
            )}
            ref={containerRef}
        >
            {isTileSizeCalculated && (
                <>
                    <div
                        className={`flex ${vertical ? 'flex-col items-center' : 'justify-center'}`}
                    >
                        {Array.from(cleanWord).map((letter, index) => (
                            <GridLetterTile
                                ref={tileRefs.current[index]}
                                key={index}
                                letter={letter}
                                size={tileSize}
                                selectedBackgroundColor={word.categoryColor}
                            />
                        ))}
                    </div>
                    {showCursor && (
                        <animated.div
                            ref={cursorRef} // Attach the ref from useCursorAnimation
                            style={style} // Apply the animated styles
                            className="absolute left-0 top-0 h-16 w-16"
                        >
                            <img
                                src={cursorIcon}
                                alt="Cursor"
                                className="h-full w-full object-contain"
                            />
                        </animated.div>
                    )}
                </>
            )}
        </motion.div>
    );
};

export default SingleWordGridHandler;
