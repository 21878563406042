import { type FC, ReactNode } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { useVibration } from '@/hooks/useVibration';

interface AnimatedButtonProps {
    isSelected?: boolean;
    isDisabled?: boolean;
    onPress: () => void;
    children: ReactNode;
    className?: string;
    overlayBackgroundColor?: string;
    showOverlay?: boolean;
}

const AnimatedButton: FC<AnimatedButtonProps> = ({
    isSelected,
    isDisabled,
    onPress,
    children,
    className,
    showOverlay = true
}) => {
    const { vibrateLight } = useVibration();

    const handlePress = () => {
        if (!isDisabled) {
            vibrateLight();
            onPress();
        }
    };

    const getBgColor = (isOverlay?: boolean) => {
        if (isDisabled) {
            return isOverlay ? 'bg-black/30' : 'bg-grey400';
        }
        if (isSelected) {
            return 'bg-orange';
        }
        return 'bg-white500';
    };

    return (
        <motion.div
            className="relative w-full"
            whileTap={{ scale: 0.96 }}
            style={{ transformOrigin: 'center' }}
        >
            {showOverlay && (
                <div
                    className={classNames(
                        'absolute top-1 h-full w-full rounded-3xl opacity-60',
                        getBgColor(true),
                        className
                    )}
                />
            )}
            <button
                className={classNames(
                    'relative flex w-full items-center justify-center rounded-3xl px-2 py-1.5',
                    getBgColor(),
                    className
                )}
                onClick={handlePress}
                disabled={isDisabled}
            >
                {children}
            </button>
        </motion.div>
    );
};

export default AnimatedButton;
