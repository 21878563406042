import { type FC, useState } from 'react';

import LanguageButton from '@/components/buttons/LanguageButton';
import TextButton from '@/components/buttons/TextButton';
import CloseIcon from '@/components/icons/CloseIcon';
import PanelWithText from '@/components/PanelWithText';
import { useLabels, useNavigation, useSettings } from '@/context';
import { commonStyles } from '@/styles/commonStyles';
import { getUniqueAvailableUILanguages } from '@/utils/languages';

const ChooseUILanguageScreen: FC = () => {
    const { navigate } = useNavigation();
    const { settings, setUILanguage } = useSettings();

    const [initialUILanguageCode] = useState(settings.uiLanguage);
    const [selectedLanguageCode, setSelectedLanguageCode] = useState<
        string | null
    >(null);

    const uiLanguages = getUniqueAvailableUILanguages();
    const { getLabel } = useLabels();

    const toggleLanguageSelection = async (languageCode: string) => {
        if (selectedLanguageCode === languageCode) {
            setSelectedLanguageCode(null); // Unselect if the same language is selected
        } else {
            await setUILanguage(languageCode);
            setSelectedLanguageCode(languageCode); // Select the new language
        }
    };

    const onContinuePress = async () => {
        if (!selectedLanguageCode) return;

        await setUILanguage(selectedLanguageCode);
        navigate('ChooseStudyLanguage');
    };

    const onClosePress = async () => {
        await setUILanguage(initialUILanguageCode);
        navigate('ChooseStudyLanguage');
    };

    return (
        <div className={commonStyles.languageSelectionScreen}>
            <button
                onClick={onClosePress}
                className="z-10 self-end p-4 pr-1 text-white"
            >
                <CloseIcon height={16} width={16} color="white" />
            </button>
            <div className="mb-2.5 mt-1 flex flex-1 flex-col">
                <div>
                    <PanelWithText
                        text={getLabel('onboarding.courses-available-in')}
                        className="mb-8"
                    />
                    <div className="overflow-y-auto">
                        {uiLanguages.map(uiLanguage => (
                            <div key={uiLanguage.languageCode} className="mb-4">
                                <LanguageButton
                                    language={uiLanguage}
                                    isSelected={
                                        selectedLanguageCode ===
                                        uiLanguage.languageCode
                                    }
                                    onPress={() =>
                                        toggleLanguageSelection(
                                            uiLanguage.languageCode
                                        )
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <TextButton
                onClick={onContinuePress}
                text={getLabel('button.continue')}
                isDisabled={!selectedLanguageCode}
            />
        </div>
    );
};

export default ChooseUILanguageScreen;
