import { Random } from './Random';

export function shuffle<T>(list: T[]): void {
    const random = new Random();

    let n = list.length;
    while (n > 1) {
        n--;
        const k = random.next(n + 1);
        [list[k], list[n]] = [list[n], list[k]]; // Swap elements
    }
}
