import { BoardData } from './BoardData';

export class LevelSaveData {
    wordsInLevel: string[] = [];
    foundLevelWords: Set<string> = new Set<string>();
    tileHints: Record<string, number> = {};
    boardData: BoardData | null = null;

    constructor(wordsInLevel: string[]) {
        this.wordsInLevel = wordsInLevel;
    }
}
