import { retrieveLaunchParams } from '@telegram-apps/sdk-react';
import axios from 'axios';

import { CategoryProgressItem, LandscapeProgressItem } from '@/types/progress';
import { LanguageSettings, UserSettings } from '@/types/settings';
import { API_URL } from '@/utils/constants';

import {
    GetGameConfigResponse,
    GetLandscapesProgressResponse,
    GetProgressResponse,
    GetSettingsResponse,
    GetUserResponse,
    GetWordsProgressResponse,
    PostCoinsResponse,
    PostGameLevelResponse,
    PostLandscapeProgressResponse,
    PostLandscapesProgressResponse,
    PostLanguageSettingsResponse,
    PostSelectedLandscapeIdResponse,
    PostWordsProgressResponse,
    PostXpResponse,
    PutSettingsResponse
} from './types';

const apiClient = axios.create({
    baseURL: API_URL
});

apiClient.interceptors.request.use(
    config => {
        const { initDataRaw } = retrieveLaunchParams();

        if (initDataRaw) {
            config.headers.Authorization = `tma ${initDataRaw}`;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const getUserApi = async (): Promise<GetUserResponse> => {
    try {
        const response = await apiClient.get<GetUserResponse>('/user');
        return response.data;
    } catch (error) {
        console.error('Error fetching user:', error);
        throw new Error('Failed to fetch user');
    }
};

export const postUserApi = async (): Promise<GetUserResponse> => {
    try {
        const response = await apiClient.post<GetUserResponse>('/user');
        return response.data;
    } catch (error) {
        console.error('Error posting user:', error);
        throw new Error('Failed to post user');
    }
};

const getGameConfigApi = async (): Promise<GetGameConfigResponse> => {
    try {
        const response =
            await apiClient.get<GetGameConfigResponse>('/game-config');
        return response.data;
    } catch (error) {
        console.error('Error fetching game config:', error);
        throw new Error('Failed to fetch game config');
    }
};

const getUserSettingsApi = async (): Promise<GetSettingsResponse> => {
    try {
        const response =
            await apiClient.get<GetSettingsResponse>(`/user/settings`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user settings:', error);
        throw new Error('Failed to fetch user settings');
    }
};

const updateUserSettingsApi = async (
    updatedSettings: Partial<UserSettings>
): Promise<PutSettingsResponse> => {
    try {
        const response = await apiClient.put<UserSettings>(
            `/user/settings`,
            updatedSettings
        );
        return response.data;
    } catch (error) {
        console.error('Error updating user settings:', error);
        throw new Error('Failed to update user settings');
    }
};

const updateLanguageSettingsApi = async (
    lang: string,
    languageLevel: string
): Promise<PostLanguageSettingsResponse> => {
    try {
        const response = await apiClient.post<LanguageSettings>(
            `/user/${lang}/settings`,
            { languageLevel }
        );
        return response.data;
    } catch (error) {
        console.error('Error updating language settings:', error);
        throw new Error('Failed to update language settings');
    }
};

const getProgressApi = async (lang: string): Promise<GetProgressResponse> => {
    try {
        const response = await apiClient.get<GetProgressResponse>(
            `/user/${lang}/progress`
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching progress:', error);
        throw new Error('Failed to fetch progress');
    }
};

const getWordsProgressApi = async (
    lang: string
): Promise<GetWordsProgressResponse> => {
    try {
        const response = await apiClient.get(`/user/${lang}/progress/words`);
        return response.data;
    } catch (error) {
        console.error('Error fetching words progress:', error);
        throw new Error('Failed to fetch words progress');
    }
};

const updateWordsProgressApi = async (
    lang: string,
    categoryProgressItems: CategoryProgressItem[]
): Promise<PostWordsProgressResponse> => {
    try {
        const response = await apiClient.post(`/user/${lang}/progress/words`, {
            categoryProgressItems
        });
        return response.data;
    } catch (error) {
        console.error('Error posting words progress:', error);
        throw new Error('Failed to post words progress');
    }
};

const getLandscapesProgressApi = async (
    lang: string
): Promise<GetLandscapesProgressResponse> => {
    try {
        const response = await apiClient.get(
            `/user/${lang}/progress/landscapes`
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching landscapes progress:', error);
        throw new Error('Failed to fetch landscapes progress');
    }
};

const updateLandscapesProgressApi = async (
    lang: string,
    landscapeProgressItems: LandscapeProgressItem[]
): Promise<PostLandscapesProgressResponse> => {
    try {
        const response = await apiClient.post(
            `/user/${lang}/progress/landscapes`,
            {
                landscapeProgressItems
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error posting landscapes progress:', error);
        throw new Error('Failed to post landscapes progress');
    }
};

const updateGameLevelApi = async (
    lang: string,
    level: number
): Promise<PostGameLevelResponse> => {
    try {
        const response = await apiClient.post(
            `/user/${lang}/progress/game-level`,
            { level }
        );
        return response.data;
    } catch (error) {
        console.error('Error posting game level:', error);
        throw new Error('Failed to post game level');
    }
};

const updateCoinsApi = async (coins: number): Promise<PostCoinsResponse> => {
    try {
        const response = await apiClient.post(`/user/progress/coins`, {
            coins
        });
        return response.data;
    } catch (error) {
        console.error('Error posting coins:', error);
        throw new Error('Failed to post coins');
    }
};

const updateXpApi = async (
    lang: string,
    xp: number
): Promise<PostXpResponse> => {
    try {
        const response = await apiClient.post(`/user/${lang}/progress/xp`, {
            xp
        });
        return response.data;
    } catch (error) {
        console.error('Error updating XP:', error);
        throw new Error('Failed to update XP');
    }
};

const updateSelectedLandscapeIdApi = async (
    lang: string,
    landscapeId: string
): Promise<PostSelectedLandscapeIdResponse> => {
    try {
        const response = await apiClient.post(
            `/user/${lang}/progress/selected-landscape-id`,
            {
                landscapeId
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error posting selected landscape ID:', error);
        throw new Error('Failed to post selected landscape ID');
    }
};

const updateLandscapeProgressApi = async (
    lang: string,
    landscapeProgress: number
): Promise<PostLandscapeProgressResponse> => {
    try {
        const response = await apiClient.post(
            `/user/${lang}/progress/landscape-progress`,
            {
                landscapeProgress
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error posting landscape progress:', error);
        throw new Error('Failed to post landscape progress');
    }
};

const generateInvoiceApi = async (code: string): Promise<string> => {
    try {
        const response = await axios.post(
            'https://us-central1-mylingocity.cloudfunctions.net/generateInvoice',
            {
                code
            }
        );

        return response.data.invoiceLink;
    } catch (error) {
        console.error('Error generating invoice:', error);
        throw new Error('Failed to generate invoice');
    }
};

export {
    generateInvoiceApi,
    getGameConfigApi,
    getLandscapesProgressApi,
    getProgressApi,
    getUserSettingsApi,
    getWordsProgressApi,
    updateCoinsApi,
    updateGameLevelApi,
    updateLandscapeProgressApi,
    updateLandscapesProgressApi,
    updateLanguageSettingsApi,
    updateSelectedLandscapeIdApi,
    updateUserSettingsApi,
    updateWordsProgressApi,
    updateXpApi
};
