import {
    CategoriesData,
    CategoryInfo,
    CategoryInfoLocalized,
    WordInfo,
    WordInfoLocalized
} from '../types/category';
import {
    LandscapeInfo,
    LandscapeInfoLocalized,
    LandscapesData
} from '../types/prize';

export const processCategoriesData = (
    categoriesData: CategoriesData,
    uiLanguage: string,
    studyLanguage: string
): CategoryInfoLocalized[] => {
    console.log(
        'Processing categories data with UI language: ' +
            uiLanguage +
            ' and study language: ' +
            studyLanguage
    );
    const localizedCategories: CategoryInfoLocalized[] = [];

    categoriesData.categories.forEach(category => {
        if (
            category.nameTranslations.en.startsWith('A -') ||
            category.nameTranslations.en.startsWith('S ')
        ) {
            return;
        }

        if (
            !category.targetStudyLanguages ||
            category.targetStudyLanguages.includes(studyLanguage)
        ) {
            try {
                const localizedCategory = localizeCategory(
                    category,
                    studyLanguage,
                    uiLanguage
                );
                localizedCategories.push(localizedCategory);
            } catch (error) {
                console.log(
                    `Skipping category "${category.nameTranslations.en}" due to errors.`
                );
            }
        }
    });

    console.log(
        'Amount of localized categories: ' + localizedCategories.length
    );
    return localizedCategories;
};

export const processLandscapesData = (
    landscapesData: LandscapesData,
    uiLanguage: string,
    studyLanguage: string
): LandscapeInfoLocalized[] => {
    console.log(
        'Processing landscapes data with UI language: ' +
            uiLanguage +
            ' and study language: ' +
            studyLanguage
    );
    const localizedLandscapes: LandscapeInfoLocalized[] = [];

    landscapesData.allPrizes.forEach(landscape => {
        if (landscape.language === studyLanguage) {
            try {
                const localizedLandscape = localizeLandscape(
                    landscape,
                    uiLanguage
                );
                localizedLandscapes.push(localizedLandscape);
            } catch (error) {
                console.log(
                    `Skipping landscape "${landscape.nameTranslations.en}" due to errors.`
                );
            }
        }
    });

    console.log(
        'Amount of localized landscapes: ' + localizedLandscapes.length
    );
    return localizedLandscapes;
};

const localizeCategory = (
    categoryInfo: CategoryInfo,
    studyLanguage: string,
    UILanguage: string
): CategoryInfoLocalized => {
    let name = '';
    let description = '';

    // Handle name localization
    if (categoryInfo.nameTranslations[UILanguage]) {
        name = categoryInfo.nameTranslations[UILanguage];
    } else {
        console.log(
            `KeyNotFoundException in LocalizeCategory for category "${categoryInfo.nameTranslations['en']}" - Missing key: '${UILanguage}' in nameTranslations`
        );
        throw new Error('KeyNotFoundException');
    }

    // Handle description localization
    if (categoryInfo.descriptionTranslations[UILanguage]) {
        description = categoryInfo.descriptionTranslations[UILanguage];
    } else {
        console.log(
            `KeyNotFoundException in LocalizeCategory for category "${categoryInfo.nameTranslations['en']}" - Missing key: '${UILanguage}' in descriptionTranslations`
        );
        throw new Error('KeyNotFoundException');
    }

    const localizedCategory: CategoryInfoLocalized = {
        id: categoryInfo.id,
        name,
        description,
        languageLevel: categoryInfo.languageLevel,
        startFriendly: categoryInfo.startFriendly,
        imageUrl: categoryInfo.imageUrl,
        thumbnailUrl: categoryInfo.thumbnailUrl,
        color: `#${categoryInfo.color}`,
        content: categoryInfo.content
            .sort((a, b) => a.position - b.position)
            .map(content =>
                localizeContent(
                    content,
                    categoryInfo.id,
                    categoryInfo.color,
                    studyLanguage,
                    UILanguage
                )
            ) // Assuming localizeContent function exists
    };

    return localizedCategory;
};

const localizeContent = (
    wordInfo: WordInfo,
    categoryId: string,
    categoryColor: string | undefined,
    studyLanguage: string,
    UILanguage: string
): WordInfoLocalized => {
    try {
        const word = wordInfo.translations[studyLanguage];
        const translation = wordInfo.translations[UILanguage];
        const audioUrl = wordInfo.audioUrls[studyLanguage];

        if (!word || !translation || !audioUrl) {
            throw new Error('Missing translation or audio URL');
        }

        const localizedContent: WordInfoLocalized = {
            id: wordInfo.id,
            partOfSpeech: wordInfo.partOfSpeech,
            position: wordInfo.position,
            type: wordInfo.type,
            word,
            translation,
            audioUrl,
            categoryId,
            categoryColor: `#${categoryColor}`
        };

        return localizedContent;
    } catch (error) {
        console.log(
            `KeyNotFoundException in LocalizeContent for the content with ID: ${wordInfo.id}.`
        );
        throw error;
    }
};

const localizeLandscape = (
    landscapeInfo: LandscapeInfo,
    uiLanguage: string
): LandscapeInfoLocalized => {
    const defaultNamePlaceholder = 'Lorem Ipsum';

    // Check and assign name translation
    const nameTranslation =
        landscapeInfo.nameTranslations[uiLanguage] || defaultNamePlaceholder;
    if (nameTranslation === defaultNamePlaceholder) {
        console.log(
            `Warning in LocalizeLandscape: Missing name translation for landscape '${landscapeInfo.id}' in language '${uiLanguage}'. Using placeholder.`
        );
    }

    return {
        id: landscapeInfo.id,
        language: landscapeInfo.language,
        position: landscapeInfo.position,
        imageUrl: landscapeInfo.imageUrl,
        thumbnailUrl: landscapeInfo.thumbnailUrl,
        name: nameTranslation
    };
};
