import { BoardData } from './types/BoardData';
import { GridCell } from './types/GridCell';

function printBoardDataCells(boardData: BoardData, desc: string): void {
    let prnt = desc;

    const yCells = boardData.board.length;
    const xCells = boardData.board[0].length;

    // Iterate from the top of the board to the bottom
    for (let y = yCells - 1; y >= 0; y--) {
        prnt += '\n' + y + ' ';

        // Iterate through each cell in the row
        for (let x = 0; x < xCells; x++) {
            const cell = boardData.board[y][x]; // Accessing row first, then column
            prnt += cell === '\0' ? '_' : cell;
        }
    }

    // Append column numbers below the grid for clarity
    prnt += '\n  '; // Adjust spacing for alignment
    for (let x = 0; x < xCells; x++) {
        prnt += x % 10; // Print column index, using modulo to handle two-digit numbers
    }

    console.log(prnt);
}

function printGridCellsWithCoordinates(grid: GridCell[][], desc: string): void {
    let prnt = desc;
    const yCells = grid.length; // Total number of rows
    const xCells = grid[0]?.length || 0; // Total number of columns in the first row

    const cellWidth = 15; // Fixed width for each cell (adjusted for shorter coordinate strings)

    // Header row for column indices
    prnt += '\n    '; // Padding for row numbers
    for (let x = 0; x < xCells; x++) {
        prnt += `${x}`.padEnd(cellWidth, ' ');
    }
    prnt += '\n    ' + '-'.repeat(cellWidth * xCells) + '\n'; // Underline

    // Iterate from the top of the board to the bottom
    for (let y = yCells - 1; y >= 0; y--) {
        prnt += `${y} |`; // Row number

        for (let x = 0; x < xCells; x++) {
            const cell = grid[y][x]; // Accessing row first, then column
            let cellContent = `${cell.letter}`; // Start with the letter

            // Check if the letterTile ref is assigned and not null
            if (cell.letterTile && cell.letterTile.current) {
                cell.letterTile.current.measure(rect => {
                    const left = Math.round(rect.left);
                    const top = Math.round(rect.top);
                    cellContent += `(${left},${top})`;
                });
            } else {
                cellContent += '(_)'; // Handle case where ref is null
            }

            // Append the cell content to the row with padding for alignment
            prnt += cellContent.padEnd(cellWidth, ' ') + '|';
        }
        prnt += '\n' + '    ' + '-'.repeat(cellWidth * xCells) + '\n'; // Underline between rows
    }

    console.log(prnt);
}

function printIsBlankCells(grid: GridCell[][], desc: string): void {
    let prnt = desc;
    const yCells = grid.length; // Total number of rows
    const xCells = grid[0]?.length || 0; // Total number of columns in the first row

    // Iterate from the top of the board to the bottom
    for (let y = yCells - 1; y >= 0; y--) {
        prnt += '\n' + y + ' ';

        // Iterate through each cell in the row
        for (let x = 0; x < xCells; x++) {
            const cell = grid[y][x]; // Accessing row first, then column

            // Append 'B' for blank cells, 'N' for non-blank cells
            prnt += cell.isBlank ? 'B' : 'N';
        }
    }

    // Append column numbers below the grid for clarity
    prnt += '\n  '; // Adjust spacing for alignment
    for (let x = 0; x < xCells; x++) {
        prnt += x % 10; // Print column index, using modulo to handle two-digit numbers
    }

    console.log(prnt);
}

function printGridCells(grid: GridCell[][], desc: string): void {
    let prnt = desc;
    const yCells = grid.length; // Total number of rows
    const xCells = grid[0]?.length || 0; // Total number of columns in the first row

    // Iterate from the top of the board to the bottom
    for (let y = yCells - 1; y >= 0; y--) {
        prnt += '\n' + y + ' ';

        // Iterate through each cell in the row
        for (let x = 0; x < xCells; x++) {
            const cell = grid[y][x]; // Accessing row first, then column

            prnt += cell.letter;
        }
    }

    // Append column numbers below the grid for clarity
    prnt += '\n  '; // Adjust spacing for alignment
    for (let x = 0; x < xCells; x++) {
        prnt += x % 10; // Print column index, using modulo to handle two-digit numbers
    }

    console.log(prnt);
}

const printCell = (text: string, cell?: GridCell) => {
    if (!cell) {
        return console.warn(`${text}: Cell is undefined or null`);
    }

    console.log(
        `${text}: Row: ${cell.row}, Col: ${cell.col}, Letter: ${cell.letter}`
    );
};

export {
    printBoardDataCells,
    printCell,
    printGridCells,
    printGridCellsWithCoordinates,
    printIsBlankCells
};
