import {
    createContext,
    type FC,
    ReactNode,
    useCallback,
    useContext,
    useState
} from 'react';

import {
    getLandscapesProgressApi,
    getProgressApi,
    getWordsProgressApi,
    updateCoinsApi,
    updateGameLevelApi,
    updateLandscapeProgressApi,
    updateLandscapesProgressApi,
    updateSelectedLandscapeIdApi,
    updateWordsProgressApi,
    updateXpApi
} from '@/api/client';
import { useContent } from '@/context';

import { CategoryInfoWithProgress, WordInfoLocalized } from '../types/category';
import { LandscapeInfoLocalized } from '../types/prize';
import {
    CategoryProgressItem,
    GameProgressValues,
    LandscapeProgressItem,
    LandscapesUnlocked,
    WordProgressItem
} from '../types/progress';
import {
    getCategoriesToChooseFrom,
    getUniqueCleanWords,
    getWordsToStudyForCurrentCategory
} from '../utils/categories';
import { Random } from '../utils/Random';
import {
    getAllWordsInProgress,
    getAllWordsToReview,
    getAllWordsToReviewCount
} from '../utils/review';

interface GameProgressContextType {
    categoriesToChooseFrom?: CategoryInfoWithProgress[];
    chooseCategory: (category: CategoryInfoWithProgress) => void;
    resetCategories: () => void;
    currentCategory?: CategoryInfoWithProgress;
    gameProgress: GameProgress;
    getWordsByLevel: (level: number) => WordInfoLocalized[];
    getWordsLearnedCount: () => number;
    getWordsToReview: (studyLanguage: string) => WordInfoLocalized[];
    getWordsToReviewCount: () => number;
    goToNextGameLevel: (studyLanguage: string) => void;
    isLockedLandscapeAvailable: () => boolean;
    isNoWordsToReviewWithSpacedRep: boolean;
    loadCategoriesToChooseFrom: (languageLevel: string) => void;
    loadGameProgress: (
        studyLanguage: string,
        onLoadingFinished: () => void
    ) => Promise<void>;
    nextLandscape?: LandscapeInfoLocalized;
    prepareNextLandscape: () => void;
    resetNextLandscape: () => void;
    unlockCategory: (
        studyLanguage: string,
        languageLevel: string,
        categoryId: string
    ) => Promise<void>;
    unlockLandscape: (
        studyLanguage: string,
        landscape: LandscapeInfoLocalized
    ) => void;
    updateCategoriesProgress: (
        studyLanguage: string,
        learnedWords: WordInfoLocalized[],
        currentCategory?: CategoryInfoWithProgress
    ) => Promise<void>;
    updateSelectedLandscapeId: (
        studyLanguage: string,
        landscapeId: string
    ) => void;
    addXp: (studyLanguage: string, xp: number) => void;
    addCoins: (coins: number) => void;
    updateIsNoWordsToReviewWithSpacedRep: (
        noWordsToReviewWithSpacedRep: boolean
    ) => void;
    wordsToStudy?: WordInfoLocalized[];
}

export interface GameProgress extends GameProgressValues {
    categoriesProgress: CategoryInfoWithProgress[];
    landscapesProgress: LandscapeProgressItem[];
    landscapesUnlocked: LandscapesUnlocked;
}

interface GameProgressProviderProps {
    children: ReactNode;
}

const GameProgressContext = createContext<GameProgressContextType | undefined>(
    undefined
);

export const GameProgressProvider: FC<GameProgressProviderProps> = ({
    children
}) => {
    const { categories, landscapes } = useContent();

    const [gameProgress, setGameProgress] = useState<GameProgress>();
    const [isNoWordsToReviewWithSpacedRep, setIsNoWordsToReviewWithSpacedRep] =
        useState(false);

    const [categoriesToChooseFrom, setCategoriesToChooseFrom] =
        useState<CategoryInfoWithProgress[]>();
    const [currentCategory, setCurrentCategory] =
        useState<CategoryInfoWithProgress>();
    const [wordsToStudy, setWordsToStudy] = useState<WordInfoLocalized[]>();

    const [nextLandscape, setNextLandscape] =
        useState<LandscapeInfoLocalized>();

    const loadCategoriesProgress = async (
        studyLanguage: string
    ): Promise<CategoryInfoWithProgress[]> => {
        console.log(`loadCategoriesProgress for language: ${studyLanguage}`);

        // Fetch all words progress at once using the new API
        let allCategoryProgress = [];
        try {
            const { categoryProgressItems } =
                await getWordsProgressApi(studyLanguage);
            allCategoryProgress = categoryProgressItems || [];
        } catch (error) {
            console.error('Error loading words progress:', error);
        }

        const categoriesWithProgress: CategoryInfoWithProgress[] = [];

        console.log('Total amount of categories: ' + categories.length);

        for (const category of categories) {
            // Find the progress for the current category from the fetched data
            let categoryProgressItem = allCategoryProgress.find(
                progressItem => progressItem.categoryId === category.id
            );

            // If no progress is found for the category, initialize it
            if (!categoryProgressItem) {
                categoryProgressItem = {
                    categoryId: category.id,
                    wordProgressItems: [] as WordProgressItem[],
                    unlocked: false
                };
            }

            // Build CategoryInfoWithProgress structure
            const categoryInfoWithProgress: CategoryInfoWithProgress = {
                categoryInfo: category,
                categoryProgressItem
            };

            // Filter word progress to only include words that still exist in the category's content
            categoryInfoWithProgress.categoryProgressItem.wordProgressItems =
                categoryInfoWithProgress.categoryProgressItem.wordProgressItems.filter(
                    wordProgressItem =>
                        category.content.some(
                            wordInfo => wordInfo.id === wordProgressItem.wordId
                        )
                );

            categoriesWithProgress.push(categoryInfoWithProgress);
        }

        console.log(
            'loadCategoriesProgress finished. Amount of categoriesWithProgress: ' +
                categoriesWithProgress.length
        );

        return categoriesWithProgress;
    };

    const loadGameProgress = useCallback(
        async (studyLanguage: string, onLoadingFinished: () => void) => {
            console.log('Loading game progress...');

            resetCurrentValues();

            try {
                const [
                    progressData,
                    categoriesProgress,
                    landscapesProgressData
                ] = await Promise.all([
                    getProgressApi(studyLanguage),
                    loadCategoriesProgress(studyLanguage),
                    getLandscapesProgressApi(studyLanguage)
                ]);

                setGameProgress({
                    gameLevel: progressData.gameLevel,
                    coins: progressData.coins,
                    xp: progressData.xp,
                    landscapeProgress: progressData.landscapeProgress,
                    selectedLandscapeId: progressData.selectedLandscapeId,
                    categoriesProgress: categoriesProgress,
                    landscapesProgress: landscapesProgressData.landscapes,
                    landscapesUnlocked: {
                        unlocked: landscapesProgressData.landscapes.length,
                        total: landscapes.length
                    }
                });

                console.log('Game progress loaded successfully');
                onLoadingFinished();
            } catch (error) {
                console.error('Error loading game progress:', error);
                throw new Error('Failed to load game progress');
            }
        },
        [categories, landscapes]
    );

    const loadCategoriesToChooseFrom = (languageLevel: string) => {
        console.log('loadCategoriesToChooseFrom');
        console.log(
            'categoriesProgress items: ' +
                gameProgress.categoriesProgress.length
        );

        const categories = getCategoriesToChooseFrom(
            gameProgress.categoriesProgress,
            languageLevel
        );
        setCategoriesToChooseFrom(categories);
    };

    const unlockCategory = async (
        studyLanguage: string,
        languageLevel: string,
        categoryId: string
    ) => {
        const categoriesProgress = gameProgress.categoriesProgress;
        const categoryProgress = categoriesProgress.find(
            cp => cp.categoryInfo.id === categoryId
        );

        if (categoryProgress) {
            categoryProgress.categoryProgressItem.unlocked = true;
            categoryProgress.categoryProgressItem.unlockedAt = new Date();

            await updateWordsProgressApi(studyLanguage, [
                categoryProgress.categoryProgressItem
            ]);

            setGameProgress(prev => ({ ...prev, categoriesProgress }));
        } else {
            console.error('Category progress is for some reason empty');
        }

        // update categories to choose from to reflect the unlocked category
        loadCategoriesToChooseFrom(languageLevel);
    };

    const chooseCategory = (category: CategoryInfoWithProgress) => {
        setCurrentCategory(category);
        setWordsToStudy(getWordsToStudyForCurrentCategory(category));
    };

    const updateSelectedLandscapeId = async (
        studyLanguage: string,
        landscapeId: string
    ) => {
        await updateSelectedLandscapeIdApi(studyLanguage, landscapeId);
        setGameProgress(prevGameProgress => ({
            ...prevGameProgress,
            selectedLandscapeId: landscapeId
        }));
    };

    const addXp = async (studyLanguage: string, xp: number) => {
        const updatedXp = gameProgress.xp + xp;
        await updateXpApi(studyLanguage, updatedXp);
        setGameProgress(prev => ({ ...prev, xp: updatedXp }));
    };

    const getWordsLearnedCount = () => {
        return gameProgress.categoriesProgress.reduce(
            (totalWordsLearned, categoryProgress) => {
                return (
                    totalWordsLearned +
                    categoryProgress.categoryProgressItem.wordProgressItems
                        .length
                );
            },
            0
        );
    };

    const addCoins = async (coins: number) => {
        const updatedCoins = gameProgress.coins + coins;
        await updateCoinsApi(updatedCoins);
        setGameProgress(prev => ({ ...prev, coins: updatedCoins }));
    };

    const resetCategories = () => {
        setCategoriesToChooseFrom(undefined);
        setCurrentCategory(undefined);
    };

    const resetCurrentValues = () => {
        resetCategories();
        setWordsToStudy(undefined);
        setNextLandscape(undefined);
    };

    const updateCategoriesProgress = async (
        studyLanguage: string,
        learnedWords: WordInfoLocalized[],
        currentCategory?: CategoryInfoWithProgress
    ) => {
        const uniqueLearnedWords = Array.from(
            new Set(learnedWords.map(word => word.id))
        ).map(id => learnedWords.find(word => word.id === id));

        const categoriesProgress = gameProgress.categoriesProgress;
        const currentTime = new Date();
        const updatedCategoryProgressItems: CategoryProgressItem[] = [];

        for (const word of uniqueLearnedWords) {
            const categoryId = word.categoryId;
            let categoryProgress = categoriesProgress.find(
                cp => cp.categoryInfo.id === categoryId
            );

            // If the category progress does not exist, create a new one
            if (!categoryProgress) {
                categoryProgress = {
                    categoryInfo: categories.find(c => c.id === categoryId),
                    categoryProgressItem: {
                        categoryId: categoryId,
                        lastTimePlayed: currentTime,
                        wordProgressItems: [],
                        unlocked: true,
                        unlockedAt: currentTime
                    }
                };

                categoriesProgress.push(categoryProgress);
            }

            // Update last time played only for the current category (which must be a category with new words),
            // to avoid mixing categories in the ChooseCategoryScreen
            if (
                currentCategory &&
                currentCategory.categoryInfo.id === categoryId
            ) {
                categoryProgress.categoryProgressItem.lastTimePlayed =
                    currentTime;
            }

            // Check if the word progress already exists
            let wordProgress =
                categoryProgress.categoryProgressItem.wordProgressItems.find(
                    wpi => wpi.wordId === word.id
                );

            if (!wordProgress) {
                // Add new word progress item
                wordProgress = {
                    wordId: word.id,
                    level: gameProgress.gameLevel,
                    firstTimePlayed: currentTime,
                    lastTimePlayed: currentTime,
                    timesPlayed: 1,
                    box: 0,
                    movedToBoxAt: currentTime
                };
                categoryProgress.categoryProgressItem.wordProgressItems.push(
                    wordProgress
                );
            } else {
                // Update existing word progress item
                wordProgress.lastTimePlayed = currentTime;
                wordProgress.timesPlayed++;
            }

            // Collect the updated category progress to be sent in bulk
            updatedCategoryProgressItems.push(
                categoryProgress.categoryProgressItem
            );
        }

        // Save the updated category progress for all categories at once
        if (updatedCategoryProgressItems.length > 0) {
            await updateWordsProgressApi(
                studyLanguage,
                updatedCategoryProgressItems
            );
        }

        console.log('Finish updateCategoriesProgress');

        setGameProgress(prev => ({ ...prev, categoriesProgress }));
    };

    const isLockedLandscapeAvailable = (): boolean => {
        return landscapes.length > gameProgress.landscapesProgress.length;
    };

    const prepareNextLandscape = () => {
        const random = new Random();
        const shuffledLandscapes = [...landscapes].sort(
            () => random.nextDouble() - 0.5
        );

        const landscapeToReturn = shuffledLandscapes.find(
            landscape =>
                !gameProgress.landscapesProgress.some(
                    lp => lp.landscapeId === landscape.id
                )
        );

        if (landscapeToReturn) {
            // TODO: download landscape image
            setNextLandscape(landscapeToReturn);
            console.log('Next landscape: ' + landscapeToReturn.id);
        }
    };

    const unlockLandscape = async (
        studyLanguage: string,
        landscape: LandscapeInfoLocalized
    ) => {
        updateSelectedLandscapeId(studyLanguage, landscape.id);

        const newLandscapesProgress = [
            ...gameProgress.landscapesProgress,
            { landscapeId: landscape.id, addedAt: new Date() }
        ];

        setGameProgress(prev => ({
            ...prev,
            landscapeProgress: 0,
            landscapesProgress: newLandscapesProgress,
            landscapesUnlocked: {
                unlocked: newLandscapesProgress.length,
                total: landscapes.length
            }
        }));

        await updateLandscapeProgressApi(studyLanguage, 0);
        await updateLandscapesProgressApi(studyLanguage, newLandscapesProgress);
    };

    const resetNextLandscape = () => {
        setNextLandscape(undefined);
    };

    const getWordsByLevel = (level: number): WordInfoLocalized[] => {
        if (level < 1 || level > gameProgress.gameLevel) return [];

        const wordsByLevel: WordInfoLocalized[] = [];

        gameProgress.categoriesProgress.forEach(categoryProgress => {
            categoryProgress.categoryProgressItem.wordProgressItems.forEach(
                wordProgressItem => {
                    if (wordProgressItem.level === level) {
                        const word = categoryProgress.categoryInfo.content.find(
                            wordInfo => wordInfo.id === wordProgressItem.wordId
                        );
                        if (word) {
                            wordsByLevel.push(word);
                        }
                    }
                }
            );
        });

        return wordsByLevel;
    };

    const updateIsNoWordsToReviewWithSpacedRep = (
        noWordsToReviewWithSpacedRep: boolean
    ) => {
        setIsNoWordsToReviewWithSpacedRep(noWordsToReviewWithSpacedRep);
    };

    const getWordsToReview = (studyLanguage: string) => {
        let reviewableWords: WordInfoLocalized[] = [];

        if (isNoWordsToReviewWithSpacedRep) {
            reviewableWords = getAllWordsInProgress(
                gameProgress.categoriesProgress
            );
        } else {
            reviewableWords = getAllWordsToReview(
                gameProgress.categoriesProgress
            );
        }

        // limit up to 20 words
        return getUniqueCleanWords(reviewableWords.slice(0, 20), studyLanguage);
    };

    const getWordsToReviewCount = () => {
        return getAllWordsToReviewCount(gameProgress.categoriesProgress);
    };

    const goToNextGameLevel = async (studyLanguage: string) => {
        let updatedLandscapeProgress = 0;

        if (gameProgress.gameLevel >= 2 && isLockedLandscapeAvailable()) {
            updatedLandscapeProgress = gameProgress.landscapeProgress + 1;

            await updateLandscapeProgressApi(
                studyLanguage,
                updatedLandscapeProgress
            );
        }

        const newGameLevel = gameProgress.gameLevel + 1;
        await updateGameLevelApi(studyLanguage, newGameLevel);

        setGameProgress(prev => ({
            ...prev,
            gameLevel: newGameLevel,
            landscapeProgress: updatedLandscapeProgress
        }));
    };

    return (
        <GameProgressContext.Provider
            value={{
                categoriesToChooseFrom,
                chooseCategory,
                currentCategory,
                resetCategories,
                gameProgress,
                getWordsByLevel,
                getWordsLearnedCount,
                getWordsToReview,
                getWordsToReviewCount,
                goToNextGameLevel,
                isLockedLandscapeAvailable,
                isNoWordsToReviewWithSpacedRep,
                loadCategoriesToChooseFrom,
                loadGameProgress,
                nextLandscape,
                prepareNextLandscape,
                resetNextLandscape,
                unlockCategory,
                unlockLandscape,
                updateCategoriesProgress,
                updateSelectedLandscapeId,
                addXp,
                addCoins,
                updateIsNoWordsToReviewWithSpacedRep,
                wordsToStudy
            }}
        >
            {children}
        </GameProgressContext.Provider>
    );
};

export const useGameProgress = (): GameProgressContextType => {
    const context = useContext(GameProgressContext);
    if (context === undefined) {
        throw new Error(
            'useGameProgress must be used within a GameProgressProvider'
        );
    }
    return context;
};
