import { type FC } from 'react';

import Panel from './Panel';

type Props = {
    text: string;
    className?: string;
};

const PanelWithText: FC<Props> = ({ text, className }) => {
    return (
        <Panel className={className}>
            <h2>{text}</h2>
        </Panel>
    );
};

export default PanelWithText;
