import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import { CrossFadeImage, TextButton } from '@/components';
import { useGameProgress, useSettings } from '@/context';
import { useLabels } from '@/context/LabelsContext';
import { useNavigation } from '@/context/NavigationContext';
import emitter from '@/events/emitter';

const OpenLandscapeScreen = () => {
    const { navigate } = useNavigation();
    const { settings } = useSettings();
    const { getLabel } = useLabels();
    const {
        nextLandscape,
        prepareNextLandscape,
        resetNextLandscape,
        unlockLandscape
    } = useGameProgress();

    const [isVisible, setIsVisible] = useState(true); // Controls visibility after animation
    const [shouldAnimate, setShouldAnimate] = useState(false); // Controls animation

    useEffect(() => {
        if (!nextLandscape) {
            prepareNextLandscape();
        }
    }, [nextLandscape, prepareNextLandscape]);

    const onContinuePress = () => {
        unlockLandscape(settings.studyLanguage, nextLandscape);
        emitter.emit('playSound', { sound: SoundName.Reveal });

        // Trigger the animation
        setShouldAnimate(true);

        // Navigate after the animation
        setTimeout(() => {
            resetNextLandscape();
            setIsVisible(false); // Hide the element after fade-out
            navigate('ChooseCategory');
        }, 800); // Match the duration of the fade-out animation
    };

    if (!nextLandscape || !isVisible) return null;

    return (
        <motion.div
            className="flex h-full flex-col justify-center"
            initial={{ opacity: 1 }}
            animate={shouldAnimate ? { opacity: 0 } : { opacity: 1 }}
            transition={{ duration: 0.8 }}
        >
            <div className="mb-6 flex w-full flex-col items-center rounded-2xl bg-grey700 bg-opacity-85 px-5 py-4">
                <h3>{nextLandscape.name}</h3>
                <CrossFadeImage
                    src={nextLandscape.imageUrl}
                    alt={nextLandscape.name}
                    containerClass="my-6 h-64 w-64"
                    className="rounded-full"
                />
            </div>
            <TextButton
                onClick={onContinuePress}
                text={getLabel('new-landscape.discover')}
            />
        </motion.div>
    );
};

export default OpenLandscapeScreen;
