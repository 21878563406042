import { useState } from 'react';

import {
    Dialogue,
    HeaderWithCloseAndBackButton,
    TextButton
} from '@/components';
import { useLabels, useNavigation, useSettings } from '@/context';
import { getAvailableUILanguagesForStudyLanguage } from '@/utils/languages';

import ProfileLanguageButton from './components/ProfileLanguageButton';

const LanguageSettingsScreen = () => {
    const { closeProfile, navigateToProfile, navigate } = useNavigation();
    const { settings, setStudyLanguage, setUILanguage } = useSettings();
    const { getLabel } = useLabels();
    const [
        isChangeLanguageDialogueVisible,
        setIsChangeLanguageDialogueVisible
    ] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState<string>();

    const showLanguagesYouStudy = () => {
        return settings.studyLanguages.map((languageCode, index) => {
            const isSelectedLanguage = languageCode === settings.studyLanguage;
            return (
                <ProfileLanguageButton
                    key={index}
                    onButtonPress={() => {
                        setSelectedLanguage(languageCode);
                        setIsChangeLanguageDialogueVisible(true);
                    }}
                    disabled={isSelectedLanguage}
                    languageCode={languageCode}
                    label={getLabel(`language.${languageCode}`)}
                />
            );
        });
    };

    const showLanguageYouSpeak = () => {
        return (
            <ProfileLanguageButton
                disabled
                onButtonPress={() => {}}
                languageCode={settings.uiLanguage}
                label={getLabel(`language.${settings.uiLanguage}`)}
            />
        );
    };

    const onChangeStudyLanguage = async () => {
        setIsChangeLanguageDialogueVisible(false);
        if (selectedLanguage === settings.uiLanguage) {
            const availableLanguagesForStudyLanguage =
                getAvailableUILanguagesForStudyLanguage(selectedLanguage);
            const anotherLanguage = availableLanguagesForStudyLanguage.filter(
                language => language.languageCode !== selectedLanguage
            )[0];
            await setUILanguage(anotherLanguage.languageCode);
        }
        await setStudyLanguage(selectedLanguage);
        navigate('Splash');
    };

    const onDialogueClose = () => {
        setIsChangeLanguageDialogueVisible(false);
    };

    return (
        <div className="w-full">
            <HeaderWithCloseAndBackButton onClose={() => closeProfile()} />
            <div className="h-[calc(100vh-60px)] overflow-y-scroll pb-12">
                <div className="mb-8">
                    <h2>{getLabel('change-language.languages-you-study')}</h2>
                    <div className="my-6">{showLanguagesYouStudy()}</div>
                    <TextButton
                        onClick={() => {
                            navigateToProfile('AddStudyLanguage');
                        }}
                        text={getLabel('change-language.add-language')}
                        className="w-full"
                    />
                </div>
                <div>
                    <h2>{getLabel('change-language.language-you-speak')}</h2>
                    <div className="my-6">{showLanguageYouSpeak()}</div>
                    <TextButton
                        onClick={() => {
                            navigateToProfile('ChangeUILanguage');
                        }}
                        text={getLabel('change-language.change-language')}
                        className="w-full"
                    />
                </div>
            </div>
            <Dialogue
                isVisible={isChangeLanguageDialogueVisible}
                onButton1Press={onChangeStudyLanguage}
                onButton2Press={onDialogueClose}
                onClose={onDialogueClose}
                title={getLabel('change-language.change-language')}
                text={getLabel('change-language.change-study-language')}
                button1Text={getLabel('button.yes')}
                button2Text={getLabel('button.no')}
                outline
            />
        </div>
    );
};

export default LanguageSettingsScreen;
