import { useEffect, useState } from 'react';

import { PanelWithText } from '@/components';
import ProgressBar from '@/components/ProgressBar';
import {
    useContent,
    useGameProgress,
    useLabels,
    useNavigation,
    useSettings
} from '@/context';
import { commonStyles } from '@/styles/commonStyles';

const MIN_LOADING_TIME_MS = 2500;

const ContentLoadingScreen = () => {
    const { navigate } = useNavigation();
    const { settings, addNewStudyLanguage } = useSettings();
    const { loadContent } = useContent();
    const { loadGameProgress } = useGameProgress();
    const { getLabel } = useLabels();
    const [progressBarValue, setProgressBarValue] = useState(0);

    const [isContentLoadingFinished, setIsContentLoadingFinished] =
        useState(false);
    const [isGameProgressLoadingFinished, setIsGameProgressLoadingFinished] =
        useState(false);
    const [hasMinLoadingTimePassed, setHasMinLoadingTimePassed] =
        useState(false);

    useEffect(() => {
        if (
            !isContentLoadingFinished ||
            !settings?.studyLanguage ||
            isGameProgressLoadingFinished
        ) {
            return;
        }

        loadGameProgress(settings.studyLanguage, () => {
            setIsGameProgressLoadingFinished(true);
        });
    }, [settings?.studyLanguage, isContentLoadingFinished, loadGameProgress]);

    useEffect(() => {
        if (isContentLoadingFinished) return;

        console.log(
            `ContentLoadingScreen. UI language: ${settings.uiLanguage}. Study language: ${settings.studyLanguage}. Language level: ${settings.languageLevel}`
        );
        if (!settings.studyLanguage || !settings.uiLanguage) return;
        loadContent(settings.uiLanguage, settings.studyLanguage, () => {
            setIsContentLoadingFinished(true);
        });

        if (!settings.studyLanguages.includes(settings.studyLanguage)) {
            addNewStudyLanguage(settings.studyLanguage);
        }
    }, [settings, loadContent, isContentLoadingFinished]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setHasMinLoadingTimePassed(true);
        }, MIN_LOADING_TIME_MS);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const flags = [
            isContentLoadingFinished,
            isGameProgressLoadingFinished,
            hasMinLoadingTimePassed
        ];
        const allFlagsAreTrue = flags.every(flag => flag);
        if (allFlagsAreTrue) {
            setProgressBarValue(1);
        } else {
            const progress = flags.filter(flag => flag).length / flags.length;
            setProgressBarValue(progress);
        }

        if (allFlagsAreTrue) {
            setTimeout(() => {
                navigate('Hello');
            }, 500);
        }
    }, [
        isContentLoadingFinished,
        isGameProgressLoadingFinished,
        hasMinLoadingTimePassed,
        navigate
    ]);

    return (
        <div className={commonStyles.centeredContent}>
            <PanelWithText
                className={commonStyles.panelWrapper}
                text={getLabel('onboarding.loading-your-course')}
            />
            <div className="absolute bottom-20 w-52">
                <ProgressBar progressBarValue={progressBarValue} />
            </div>
        </div>
    );
};

export default ContentLoadingScreen;
