import { type FC } from 'react';

interface Props {
    width: number;
    height: number;
    color: string;
}

const ExitIcon: FC<Props> = ({ width, height, color }: Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 68 74" fill={color}>
            <g clipPath="url(#clip0_2979_34)">
                <path d="M67.0601 36.9111C67.0601 36.8611 67.0801 36.8111 67.0801 36.7511C67.0801 36.6811 67.0601 36.6211 67.0601 36.5511C67.0601 36.4111 67.0401 36.2711 67.0201 36.1311C67.0001 36.0011 66.9701 35.8811 66.9401 35.7611C66.9101 35.6311 66.8701 35.5111 66.8201 35.3811C66.7701 35.2511 66.7201 35.1311 66.6601 35.0111C66.6101 34.9011 66.5501 34.7911 66.4801 34.6811C66.4101 34.5611 66.3301 34.4411 66.2401 34.3311C66.2001 34.2811 66.1701 34.2111 66.1201 34.1611L57.6601 24.1611C56.2301 22.4711 53.7101 22.2611 52.0201 23.6911C50.3301 25.1211 50.1201 27.6411 51.5501 29.3311L54.4401 32.7511H28.3301C26.1201 32.7511 24.3301 34.5411 24.3301 36.7511C24.3301 38.9611 26.1201 40.7511 28.3301 40.7511H54.8701L51.5601 44.6711C50.1301 46.3611 50.3501 48.8811 52.0301 50.3111C52.7801 50.9411 53.7001 51.2611 54.6101 51.2611C55.7501 51.2611 56.8801 50.7811 57.6701 49.8411L66.1301 39.8411C66.8001 39.0511 67.1001 38.0711 67.0601 37.1011C67.0601 37.0711 67.0601 37.0411 67.0601 37.0011C67.0601 36.9711 67.0601 36.9411 67.0601 36.9111Z" />
                <path d="M44.73 64H10V10H44.73C47.49 10 49.73 7.76 49.73 5C49.73 2.24 47.49 0 44.73 0H5C2.24 0 0 2.24 0 5V69C0 71.76 2.24 74 5 74H44.73C47.49 74 49.73 71.76 49.73 69C49.73 66.24 47.49 64 44.73 64Z" />
            </g>
            <defs>
                <clipPath id="clip0_2979_34">
                    <rect width="67.07" height="74" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ExitIcon;
