import { type FC } from 'react';

interface Props {
    width: number;
    height: number;
    color: string;
}

const BackIcon: FC<Props> = ({ width, height, color }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 33 50"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g transform="rotate(180, 16.5, 25)">
                <path d="M14.8608 24.8565C10.3162 20.4069 6.46158 16.7522 2.74692 12.9525C-0.787766 9.33286 -0.882759 5.26822 2.28696 2.22349C5.53667 -0.896229 9.45131 -0.75124 13.026 2.77344C18.5055 8.17296 23.95 13.6075 29.3445 19.092C33.4492 23.2666 33.3842 26.8163 29.2145 31.0209C23.995 36.2854 18.7555 41.53 13.486 46.7445C9.53131 50.6591 5.68665 51.0341 2.44694 47.9194C-1.00275 44.6047 -0.762768 40.5551 3.16188 36.5804C6.95654 32.7458 10.7812 28.9411 14.8608 24.8565Z" />
            </g>
        </svg>
    );
};

export default BackIcon;
