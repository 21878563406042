import { Howl, Howler } from 'howler';

import card from '../../assets/audio/card.m4a';
import click from '../../assets/audio/click.m4a';
import click2 from '../../assets/audio/click2.m4a';
import coin from '../../assets/audio/coin.m4a';
import done from '../../assets/audio/done.m4a';
import notice from '../../assets/audio/notice.m4a';
import reveal from '../../assets/audio/reveal.m4a';
import success from '../../assets/audio/success.m4a';
import wrong from '../../assets/audio/wrong.m4a';
import emitter from '../events/emitter';

export enum SoundName {
    Card = 'card',
    Click = 'click',
    Click2 = 'click2',
    Coin = 'coin',
    Done = 'done',
    Notice = 'notice',
    Reveal = 'reveal',
    Success = 'success',
    Wrong = 'wrong'
}

const soundFileMap = {
    [SoundName.Card]: card,
    [SoundName.Click]: click,
    [SoundName.Click2]: click2,
    [SoundName.Coin]: coin,
    [SoundName.Done]: done,
    [SoundName.Notice]: notice,
    [SoundName.Reveal]: reveal,
    [SoundName.Success]: success,
    [SoundName.Wrong]: wrong
};

class AudioManager {
    private wordAudio: Howl | null;
    private soundAudioMap: Map<SoundName, Howl>;
    private volume: number = 1; // Default volume at 100%
    private isInitialized: boolean = false;

    constructor() {
        this.wordAudio = null; // Store current word audio Howl instance
        this.soundAudioMap = new Map(); // Store preloaded Howl objects for sound effects
    }

    public init() {
        if (!this.isInitialized) {
            this.preloadSounds();
            this.subscribeToEvents();
            this.isInitialized = true;
        }
    }

    private preloadSounds() {
        for (const [soundName, soundUrl] of Object.entries(soundFileMap)) {
            const sound = new Howl({
                src: [soundUrl],
                preload: true, // Preload the sound for faster playback
                volume: this.volume
            });
            this.soundAudioMap.set(soundName as SoundName, sound);
        }
    }

    private subscribeToEvents() {
        emitter.on('playSound', this.handlePlaySound);
        emitter.on('playWordAudio', this.handlePlayWordAudio);
    }

    public setVolume(volume: number) {
        this.volume = volume / 100;
        Howler.volume(this.volume); // Set global volume for all Howl instances

        // Update volume for all preloaded sounds
        this.soundAudioMap.forEach(sound => {
            sound.volume(this.volume);
        });
    }

    private handlePlaySound = ({ sound }: { sound: SoundName }) => {
        this.playSound(sound);
    };

    private handlePlayWordAudio = ({
        audioUrl,
        onPlaybackFinished
    }: {
        audioUrl: string;
        onPlaybackFinished?: () => void;
    }) => {
        this.playWordAudio(audioUrl, onPlaybackFinished);
    };

    playSound(soundName: SoundName) {
        const sound = this.soundAudioMap.get(soundName);
        if (sound) {
            sound.stop(); // Ensure the sound restarts from the beginning
            sound.play();
        } else {
            console.error('Sound not found:', soundName);
        }
    }

    async playWordAudio(audioUrl: string, onPlaybackFinished?: () => void) {
        if (this.wordAudio) {
            this.wordAudio.stop(); // Stop any previously playing word audio
        }

        this.wordAudio = new Howl({
            src: [audioUrl],
            volume: this.volume,
            onend: () => {
                if (onPlaybackFinished) {
                    onPlaybackFinished();
                }
            }
        });

        this.wordAudio.play();
    }
}

export const audioManager = new AudioManager();
