import { type FC, useCallback, useEffect, useState } from 'react';
import { FaCheck, FaCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Checkbox, Field, Label } from '@headlessui/react';
import Slider from 'rc-slider';

import { SoundName } from '@/audio/AudioManager';
import TextButton from '@/components/buttons/TextButton';
import {
    useGameProgress,
    useLabels,
    useNavigation,
    useSettings
} from '@/context';
import emitter from '@/events/emitter';
import useClearAsyncStorage from '@/hooks/useClearLocalStorage';
import { useVibration } from '@/hooks/useVibration';
import { commonStyles } from '@/styles/commonStyles';
import { SUPPORT_EMAIL } from '@/utils/constants';

import Modal from '../../components/Modal';

import 'rc-slider/assets/index.css';

interface SettingsProps {
    isVisible: boolean;
    onClose: () => void;
}

const Settings: FC<SettingsProps> = ({ isVisible, onClose }) => {
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();
    const { navigate } = useNavigation();
    const { addCoins } = useGameProgress();
    const {
        settings,
        setSoundEffectsVolume,
        setIsVibrationOn,
        setIsSkipWordsIntro,
        updateDevControlsClickCounter
    } = useSettings();
    const clearStorage = useClearAsyncStorage();

    const [soundEffectsVolumeTmp, setSoundEffectsVolumeTmp] = useState(
        settings.soundEffectsVolume
    );

    useEffect(() => {
        const handleIsShowDevControlsChanged = ({ isShowDevControls }) => {
            emitter.emit('playSound', { sound: SoundName.Done });
            toast(
                isShowDevControls
                    ? 'Dev controls enabled'
                    : 'Dev controls disabled',
                {
                    autoClose: 2000
                }
            );
        };

        emitter.on('isShowDevControlsChanged', handleIsShowDevControlsChanged);

        return () => {
            emitter.off(
                'isShowDevControlsChanged',
                handleIsShowDevControlsChanged
            );
        };
    }, []);

    const onCleanAsyncStorage = async () => {
        try {
            await clearStorage();
            navigate('Splash');
        } catch (error) {
            console.error('Error cleaning async storage:', error);
        }
    };

    const onAddCoins = () => {
        addCoins(500);
        toast('500 coins added!', {
            autoClose: 2000
        });
    };

    const onTriggerError = () => {
        throw new Error('Sentry test error');
    };

    const handleSoundsEffectsChangeComplete = volume => {
        setSoundEffectsVolumeTmp(volume);
        emitter.emit('playSound', { sound: SoundName.Click2 });
        setSoundEffectsVolume(volume);
    };

    const handleSoundsEffectChange = volume => {
        setSoundEffectsVolumeTmp(volume);
    };

    const handleSetIsVibrationOn = useCallback(
        async (isOn: boolean) => {
            if (isOn) {
                vibrateLight(true);
                // Vibration logic here, if any
            }
            await setIsVibrationOn(isOn);
            emitter.emit('playSound', { sound: SoundName.Click2 });
        },
        [setIsVibrationOn]
    );

    const handleSetIsSkipWordsIntro = useCallback(
        async (isSkip: boolean) => {
            await setIsSkipWordsIntro(isSkip);
            emitter.emit('playSound', { sound: SoundName.Click2 });
        },
        [setIsSkipWordsIntro]
    );

    const onSettingsTextClick = () => {
        updateDevControlsClickCounter();
    };

    const onCopyToEmailClipboard = async () => {
        navigator.clipboard.writeText(SUPPORT_EMAIL);
        toast(getLabel('settings.email-copied'), {
            autoClose: 2000
        });
    };

    return (
        <Modal isVisible={isVisible} onClose={onClose}>
            <div className={commonStyles.modalPanel}>
                <div onClick={onSettingsTextClick}>
                    <h1>{getLabel('settings.settings')}</h1>
                </div>
                <div className="my-5 flex flex-col items-center justify-center">
                    {/* Sound Effects Volume Slider */}
                    <div className="mb-6 flex w-full flex-col">
                        <label className="mb-3 text-lg text-white200">
                            {getLabel('settings.sound-effects')}
                        </label>
                        <Slider
                            value={soundEffectsVolumeTmp}
                            onChange={handleSoundsEffectChange}
                            onChangeComplete={handleSoundsEffectsChangeComplete}
                            min={0}
                            max={100}
                            step={1}
                        />
                    </div>

                    {/* Vibration Toggle */}
                    <Field className="flex w-full gap-2">
                        <Checkbox
                            checked={settings.isVibrationOn}
                            onChange={handleSetIsVibrationOn}
                            className="group size-6 rounded-md bg-white/10 p-1 ring-1 ring-inset ring-white/15 data-[checked]:bg-white"
                        >
                            <FaCheck className="hidden size-4 fill-black group-data-[checked]:block" />
                        </Checkbox>
                        <Label className="text-grey300">
                            {getLabel('settings.vibration')}
                        </Label>
                    </Field>

                    {/* Email us label */}
                    <div className="mt-8 w-full">
                        <p className="font-semibold text-grey300">
                            {getLabel('settings.email-us')}
                        </p>
                        <div
                            className="flex w-full items-center py-1"
                            onClick={onCopyToEmailClipboard}
                        >
                            <span className="mr-4 font-semibold text-grey300">
                                {SUPPORT_EMAIL}
                            </span>
                            <FaCopy size={24} className="text-grey300" />
                        </div>
                    </div>

                    {settings.isShowDevControls && (
                        <div className="mt-6 w-full">
                            <TextButton
                                onClick={onCleanAsyncStorage}
                                text="Clear storage"
                                className="mb-2.5"
                                textStyle="text-sm"
                            />
                            <TextButton
                                onClick={onAddCoins}
                                text="Add 500 coins"
                                className="mb-2.5"
                                textStyle="text-sm"
                            />
                            <TextButton
                                onClick={onTriggerError}
                                text="Trigger error (Sentry test)"
                                textStyle="text-sm"
                            />
                            {/* Skip words intro Toggle */}
                            <Field className="mt-5 flex w-full gap-2">
                                <Checkbox
                                    checked={settings.isSkipWordsIntro}
                                    onChange={handleSetIsSkipWordsIntro}
                                    className="group size-6 rounded-md bg-white/10 p-1 ring-1 ring-inset ring-white/15 data-[checked]:bg-white"
                                >
                                    <FaCheck className="hidden size-4 fill-black group-data-[checked]:block" />
                                </Checkbox>
                                <Label className="text-grey300">
                                    Skip words intro
                                </Label>
                            </Field>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default Settings;
