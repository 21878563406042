import { type FC, useState } from 'react';

import { useSettings } from '@/context';
import { commonStyles } from '@/styles/commonStyles';
import { getStudyLanguagesByUILanguage } from '@/utils/languages';

import {
    ChangeUILanguageButton,
    LanguageButton,
    PanelWithText,
    TextButton
} from '../../components';
import { useLabels } from '../../context/LabelsContext';
import { useNavigation } from '../../context/NavigationContext';

const ChooseStudyLanguageScreen: FC = () => {
    const { navigate } = useNavigation();
    const { settings, setStudyLanguage } = useSettings();
    const { getLabel } = useLabels();
    const [selectedLanguageCode, setSelectedLanguageCode] = useState<
        string | null
    >(null);

    const studyLanguages = getStudyLanguagesByUILanguage(settings.uiLanguage);

    const toggleLanguageSelection = (languageCode: string) => {
        setSelectedLanguageCode(prevCode =>
            prevCode === languageCode ? null : languageCode
        );
    };

    const onContinuePress = async () => {
        if (!selectedLanguageCode) return;

        await setStudyLanguage(selectedLanguageCode);
        navigate('ChooseLanguageLevel');
    };

    const onChangeUILanguagePress = () => {
        navigate('ChooseUILanguage');
    };

    return (
        <div className={commonStyles.languageSelectionScreen}>
            <div className="mb-2.5 flex flex-1 flex-col justify-center">
                <PanelWithText
                    className="mb-8"
                    text={getLabel(
                        'onboarding.what-language-do-you-want-to-learn'
                    )}
                />
                <ChangeUILanguageButton onPress={onChangeUILanguagePress} />
                <div className="overflow-y-auto">
                    {studyLanguages.map(studyLanguage => (
                        <div
                            key={studyLanguage.language.languageCode}
                            className={commonStyles.languageSelectionButtonView}
                        >
                            <LanguageButton
                                language={studyLanguage.language}
                                isSelected={
                                    selectedLanguageCode ===
                                    studyLanguage.language.languageCode
                                }
                                onPress={() =>
                                    toggleLanguageSelection(
                                        studyLanguage.language.languageCode
                                    )
                                }
                            />
                        </div>
                    ))}
                </div>
            </div>
            <TextButton
                onClick={onContinuePress}
                text={getLabel('button.continue')}
                isDisabled={!selectedLanguageCode}
            />
        </div>
    );
};

export default ChooseStudyLanguageScreen;
