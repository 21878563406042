import { useEffect, useState } from 'react';
import { CountUp } from 'use-count-up';

import { SoundName } from '@/audio/AudioManager';
import { useSettings } from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';

import accountIcon from '../../../assets/images/icons/account.png';
import coinIcon from '../../../assets/images/icons/coin.png';
import landscapeIcon from '../../../assets/images/icons/landscape.png';
import xpIcon from '../../../assets/images/icons/xp.png';
import { ModalInfo } from '../../components';
import { useGameProgress } from '../../context/GameProgressContext';
import { useLabels } from '../../context/LabelsContext';
import { useNavigation } from '../../context/NavigationContext';
import { flagImages } from '../../utils/images';

const ProgressPanel = () => {
    const { gameProgress } = useGameProgress();
    const { settings } = useSettings();
    const { getLabel } = useLabels();
    const { navigateToProfile } = useNavigation();
    const { vibrateLight } = useVibration();

    const [isShowXpInfo, setIsShowXpInfo] = useState(false);
    const [isShowCoinsInfo, setIsShowCoinsInfo] = useState(false);
    const [isShowLandscapeInfo, setIsShowLandscapeInfo] = useState(false);

    const [previousCoins, setPreviousCoins] = useState(gameProgress.coins);
    const [coins, setCoins] = useState(gameProgress.coins);

    const [previousXp, setPreviousXp] = useState(gameProgress.xp);
    const [xp, setXp] = useState(gameProgress.xp);

    useEffect(() => {
        setCoins(gameProgress.coins);
    }, [gameProgress.coins]);

    useEffect(() => {
        setXp(gameProgress.xp);
    }, [gameProgress.xp]);

    const onCoinsCounterComplete = () => {
        setPreviousCoins(gameProgress.coins);
        setCoins(gameProgress.coins);
    };

    const onXpCounterComplete = () => {
        setPreviousXp(gameProgress.xp);
        setXp(gameProgress.xp);
    };

    const onProfilePress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        navigateToProfile('Account');
    };

    const onLanguagePress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        navigateToProfile('LanguageSettings');
    };

    const onXpPress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        console.log('onXpPress');
        vibrateLight();
        setIsShowXpInfo(true);
    };

    const onLandscapePress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        setIsShowLandscapeInfo(true);
    };

    const onCoinsPress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        console.log('onCoinsPress');
        vibrateLight();
        setIsShowCoinsInfo(true);
    };

    return (
        <>
            <div className="mt-2 flex items-center justify-between gap-3 p-1.5 px-2">
                <button
                    onClick={onLanguagePress}
                    className="flex items-center justify-center rounded-full bg-black bg-opacity-30 p-1"
                >
                    <img
                        src={flagImages[settings.studyLanguage]}
                        alt="Language"
                        className="h-6 w-6"
                    />
                </button>
                <button
                    onClick={onXpPress}
                    className="flex flex-auto items-center justify-start rounded-full bg-black bg-opacity-30 px-1 py-0.5"
                >
                    <div className="flex-shrink-0">
                        <img src={xpIcon} alt="XP" className="h-7 w-7" />
                    </div>
                    <div className="flex flex-1 items-center justify-center whitespace-nowrap text-center">
                        <span className="p-1.5 py-0 pr-3 text-sm font-semibold text-white">
                            XP{' '}
                            <CountUp
                                key={xp}
                                isCounting
                                start={previousXp}
                                end={xp}
                                duration={1.5}
                                onComplete={onXpCounterComplete}
                            />
                        </span>
                    </div>
                </button>
                {gameProgress.gameLevel > 1 &&
                    gameProgress.landscapesUnlocked.unlocked <
                        gameProgress.landscapesUnlocked.total && (
                        <button
                            onClick={onLandscapePress}
                            className="flex flex-auto items-center justify-start rounded-full bg-black bg-opacity-30 px-1 py-0.5"
                        >
                            <div className="flex-shrink-0">
                                <img
                                    src={landscapeIcon}
                                    alt="Landscape"
                                    className="h-7 w-7"
                                />
                            </div>

                            <div className="flex-1 text-center">
                                <span className="p-1.5 py-0 pr-3 text-sm font-semibold text-white">
                                    {gameProgress.landscapeProgress}/10
                                </span>
                            </div>
                        </button>
                    )}
                <button
                    onClick={onCoinsPress}
                    className="flex flex-auto items-center justify-start rounded-full bg-black bg-opacity-30 px-1 py-0.5"
                >
                    <div className="flex-shrink-0">
                        <img src={coinIcon} alt="Coins" className="h-7 w-7" />
                    </div>
                    <div className="flex-1 text-center">
                        <span className="p-1.5 py-0 pr-3 text-sm font-semibold text-white">
                            <CountUp
                                key={coins}
                                isCounting
                                start={previousCoins}
                                end={coins}
                                duration={1.5}
                                onComplete={onCoinsCounterComplete}
                            />
                        </span>
                    </div>
                </button>
                <button
                    onClick={onProfilePress}
                    className="flex items-center justify-center rounded-full bg-black bg-opacity-30 p-1"
                >
                    <img src={accountIcon} alt="Profile" className="h-6 w-6" />
                </button>
            </div>
            <ModalInfo
                isVisible={isShowXpInfo}
                onClose={() => setIsShowXpInfo(false)}
                title="Leaderboard"
                description="Coming soon..."
            />
            <ModalInfo
                isVisible={isShowLandscapeInfo}
                onClose={() => setIsShowLandscapeInfo(false)}
                title={getLabel('landscapes')}
                description={getLabel('progress-panel.landscapes-info')}
            />
            <ModalInfo
                isVisible={isShowCoinsInfo}
                onClose={() => setIsShowCoinsInfo(false)}
                title={getLabel('progress-panel.coins')}
                description={getLabel('progress-panel.coins-info')}
            />
        </>
    );
};

export default ProgressPanel;
