import { type FC, useEffect, useState } from 'react';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import { Dialogue, Panel, TextButton, TooltipContent } from '@/components';
import {
    useGameProgress,
    useLabels,
    useNavigation,
    useNewWordsGameSession,
    useReviewGameSession,
    useSettings
} from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import { commonStyles } from '@/styles/commonStyles';
import { CategoryInfoWithProgress } from '@/types/category';

import shopIcon from '../../../assets/images/icons/shop.png';

import { CategoryCard } from './components/CategoryCard';
import Shop from './Shop';

import 'tippy.js/animations/scale-subtle.css';

const ChooseCategoryScreen: FC = () => {
    const { navigate } = useNavigation();
    const [isShopVisible, setIsShopVisible] = useState(false);
    const [isReviewDialogueVisible, setIsReviewDialogueVisible] =
        useState(false);
    const [levelText, setLevelText] = useState('');
    const [hintText, setHintText] = useState('');
    const {
        loadCategoriesToChooseFrom,
        chooseCategory,
        categoriesToChooseFrom,
        gameProgress,
        getWordsToReviewCount,
        updateIsNoWordsToReviewWithSpacedRep
    } = useGameProgress();
    const {
        settings,
        setIsReviewWithoutSpacedRepViewed,
        updateIsReviewModeTooltipNeeded
    } = useSettings();
    const { resetGameSession } = useNewWordsGameSession();
    const { resetReviewGameSession } = useReviewGameSession();
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();

    const [wordsToReviewCount, setWordsToReviewCount] = useState(() =>
        getWordsToReviewCount()
    );

    const [isReviewTooltipVisible, setIsReviewTooltipVisible] = useState(
        gameProgress.gameLevel === 3 && settings.isReviewModeTooltipNeeded
    );

    useEffect(() => {
        const handleAppStateChange = (nextAppState: string) => {
            if (nextAppState === 'active') {
                updateWordsToReviewCount();
            }
        };

        window.addEventListener('focus', () => handleAppStateChange('active'));

        return () => {
            window.removeEventListener('focus', () =>
                handleAppStateChange('active')
            );
        };
    }, []);

    const updateWordsToReviewCount = () => {
        const count = getWordsToReviewCount();
        setWordsToReviewCount(count);
    };

    useEffect(() => {
        if (wordsToReviewCount === 0) return;

        // Animation logic handled by Framer Motion in JSX
    }, [wordsToReviewCount]);

    useEffect(() => {
        resetGameSession();
        resetReviewGameSession();

        if (!categoriesToChooseFrom) {
            loadCategoriesToChooseFrom(settings.languageLevel);
        }
    }, []);

    useEffect(() => {
        if (!categoriesToChooseFrom) return;

        const levelLabel = getLabel('choose-category.level');
        const chooseTopicLabel = getLabel(
            'choose-category.what-topic-would-you-like-to-learn'
        );
        const impressiveLabel = getLabel('choose-category.impressive');
        const noTopicsLabel = getLabel('choose-category.no-topics');
        const oneTopicLabel = getLabel('choose-category.one-topic');

        if (categoriesToChooseFrom.length === 0) {
            setLevelText(impressiveLabel);
            setHintText(noTopicsLabel);
        } else if (categoriesToChooseFrom.length === 1) {
            setLevelText(`${levelLabel} ${gameProgress.gameLevel} ✨`);
            setHintText(oneTopicLabel);
        } else {
            setLevelText(`${levelLabel} ${gameProgress.gameLevel} ✨`);
            setHintText(chooseTopicLabel);
        }
    }, [categoriesToChooseFrom]);

    const onPickTopicPress = () => {
        navigate('ChooseAnotherCategory');
    };

    const onReviewPress = () => {
        vibrateLight();
        emitter.emit('playSound', { sound: SoundName.Click2 });
        if (wordsToReviewCount === 0) {
            updateIsNoWordsToReviewWithSpacedRep(true);
            if (settings.isReviewWithoutSpacedRepViewed) {
                navigate('ReviewPuzzle');
            } else {
                setIsReviewDialogueVisible(true);
                return;
            }
        } else {
            updateIsNoWordsToReviewWithSpacedRep(false);
            navigate('ReviewPuzzle');
        }
    };

    const onContinueReviewPress = () => {
        setIsReviewWithoutSpacedRepViewed();
        setIsReviewDialogueVisible(false);
        navigate('ReviewPuzzle');
    };

    const onReviewDialogueClose = () => {
        setIsReviewDialogueVisible(false);
    };

    const onChooseCategory = (category: CategoryInfoWithProgress) => {
        chooseCategory(category);
        if (settings.isSkipWordsIntro) {
            navigate('Puzzle');
        } else {
            navigate('WordsIntro');
        }
    };

    const onShopPress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        setIsShopVisible(true);
    };

    if (!categoriesToChooseFrom) return null;

    return (
        <>
            {isReviewTooltipVisible && (
                <div className="fixed inset-0 z-20 bg-black opacity-70"></div>
            )}
            <div className="relative flex h-full flex-col justify-center pt-12">
                <motion.div
                    className="absolute right-0 top-2 flex items-center"
                    whileTap={{ scale: 0.95 }}
                    initial={{ scale: 1 }}
                    animate={{ scale: 1 }}
                >
                    <button
                        onClick={onShopPress}
                        className="flex flex-col items-center rounded-2xl bg-grey700/80 px-4 py-1 shadow-extraDark"
                    >
                        <img src={shopIcon} alt="Shop" className="h-10 w-10" />
                        <span className="text-xs font-bold uppercase text-white200">
                            Shop
                        </span>
                    </button>
                </motion.div>

                <Panel className={commonStyles.panelWrapper}>
                    <h1 className="mb-4">{levelText}</h1>
                    <p className="mb-6 text-lg text-white">{hintText}</p>

                    {categoriesToChooseFrom.length > 0 && (
                        <div className="mb-8 flex flex-wrap items-start justify-around">
                            {categoriesToChooseFrom.map(category => (
                                <CategoryCard
                                    key={category.categoryInfo.id}
                                    category={category}
                                    onChooseCategory={onChooseCategory}
                                />
                            ))}
                        </div>
                    )}
                    {categoriesToChooseFrom.length > 1 && (
                        <TextButton
                            className="w-full py-2"
                            bgColor="bg-grey700"
                            textStyle="text-white"
                            text={getLabel('choose-category.see-other-topics')}
                            onClick={onPickTopicPress}
                        />
                    )}
                </Panel>
                {gameProgress.gameLevel > 2 && (
                    <Tippy
                        content={
                            <TooltipContent
                                title={getLabel(
                                    'choose-category.review-learned-words'
                                )}
                                text={getLabel(
                                    'choose-category.review-tooltip-text'
                                )}
                                className={`w-[calc(100vw-16px)]`}
                            />
                        }
                        visible={
                            isReviewTooltipVisible &&
                            settings.isReviewModeTooltipNeeded
                        }
                        placement="top-start"
                        onClickOutside={() => {
                            setIsReviewTooltipVisible(false);
                            void updateIsReviewModeTooltipNeeded(false);
                        }}
                        duration={300}
                        animation="scale-subtle"
                    >
                        <div
                            className={classNames(
                                'relative flex w-full',
                                isReviewTooltipVisible && 'z-30'
                            )}
                        >
                            <motion.button
                                whileTap={{ scale: 0.95 }}
                                initial={{ scale: 1 }}
                                animate={{ scale: 1 }}
                                className="flex w-full items-center justify-between rounded-2xl bg-grey700 bg-opacity-80 p-3 px-4 shadow-extraDark"
                                onClick={onReviewPress}
                            >
                                <span className="text-xl font-bold text-white">
                                    {getLabel('choose-category.review')}
                                </span>
                                <span className="text-orange">
                                    {wordsToReviewCount}{' '}
                                    {getLabel(
                                        'choose-category.review-available-words'
                                    )}
                                </span>
                            </motion.button>
                        </div>
                    </Tippy>
                )}
                <Dialogue
                    text={getLabel('choose-category.review-without-bonus')}
                    onButton1Press={onContinueReviewPress}
                    onButton2Press={onReviewDialogueClose}
                    onClose={onReviewDialogueClose}
                    isVisible={isReviewDialogueVisible}
                    button1Text={getLabel('button.yes')}
                    button2Text={getLabel('button.no')}
                />
                <Shop
                    isVisible={isShopVisible}
                    onClose={() => setIsShopVisible(false)}
                />
            </div>
        </>
    );
};

export default ChooseCategoryScreen;
