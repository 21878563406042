import TextButton from '@/components/buttons/TextButton';
import HeaderWithCloseAndBackButton from '@/components/HeaderWithCloseAndBackButton';
import {
    useBackgroundImage,
    useLabels,
    useNavigation,
    useProfile
} from '@/context';

const LandscapeInfoScreen = () => {
    const { updateCurrentLandscape } = useBackgroundImage();
    const { getLabel } = useLabels();
    const { navigateToProfile, closeProfile } = useNavigation();
    const { currentLandscape } = useProfile();

    const onUseLandscapePress = () => {
        updateCurrentLandscape(currentLandscape);
        closeProfile();
    };

    if (!currentLandscape) return null;

    return (
        <div className="flex h-full flex-col">
            <HeaderWithCloseAndBackButton
                onClose={() => closeProfile()}
                onBack={() => navigateToProfile('Landscapes')}
                backTitle={getLabel('common.back')}
            />
            <div className="mt-4 flex flex-grow flex-col items-center overflow-y-scroll">
                <h2 className="mb-4 text-center text-xl font-bold">
                    {currentLandscape.name}
                </h2>
                <img
                    src={currentLandscape.imageUrl}
                    alt={currentLandscape.name}
                    className="mb-6 h-[60vh] object-contain"
                />
                <TextButton
                    text={getLabel('gift-info.use-this-landscape')}
                    onClick={onUseLandscapePress}
                />
            </div>
        </div>
    );
};

export default LandscapeInfoScreen;
