import { type FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { motion, useAnimation } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import { WordInfoLocalized } from '@/types/category';
import { getCleanWord, toUpperCaseWord } from '@/utils/language';

import cursorTapIcon from '../../../../assets/images/icons/cursor-tap.png';

import {
    BACKGROUND_COLOR,
    BACKGROUND_COLOR_FOUND,
    questionStyles,
    TEXT_COLOR,
    TEXT_COLOR_FOUND
} from './questionStyles';

interface QuestionTranslationProps {
    word: WordInfoLocalized;
    correctWord: WordInfoLocalized;
    studyLanguage: string;
    showCursorHint: boolean;
}

const QuestionTranslation: FC<QuestionTranslationProps> = ({
    word,
    correctWord,
    studyLanguage,
    showCursorHint
}) => {
    const { vibrateLight } = useVibration();

    const cursorControls = useAnimation();
    const cursorWrapperControls = useAnimation();
    const shakeControls = useAnimation();

    const [isCorrectClicked, setIsCorrectClicked] = useState(false);

    const isCorrect = word.id === correctWord.id;

    useEffect(() => {
        if (showCursorHint) {
            cursorWrapperControls.start({
                opacity: 1,
                transition: {
                    delay: 2,
                    duration: 1
                }
            });

            cursorControls.start({
                scale: [1, 1.2, 1],
                transition: {
                    delay: 2,
                    duration: 2,
                    repeat: Infinity
                }
            });
        }
    }, [showCursorHint, cursorControls, cursorWrapperControls]);

    const onPress = async () => {
        if (isCorrectClicked) return;

        if (isCorrect) {
            setIsCorrectClicked(true);
            vibrateLight();

            emitter.emit('playSound', { sound: SoundName.Success });
            emitter.emit('playWordAudio', { audioUrl: word.audioUrl });

            emitter.emit('correctTranslationSelected', {
                word,
                cleanWord: toUpperCaseWord(
                    getCleanWord(word.word, studyLanguage)
                )
            });

            setTimeout(() => {
                emitter.emit('correctAnswerGiven', { word });
            }, 750);
        } else {
            emitter.emit('playSound', { sound: SoundName.Wrong });
            emitter.emit('wrongTranslationSelected', { word: correctWord });
            await shakeControls.start({
                x: [0, -3, 3, -3, 3, -3, 0],
                transition: { duration: 0.4 }
            });
        }
    };

    return (
        <button onClick={onPress} className="relative w-full">
            <motion.div
                className="relative m-1.5 flex items-center justify-center rounded-2xl px-3 py-1.5"
                animate={shakeControls}
                initial={{ x: 0 }}
                style={{
                    backgroundColor: isCorrectClicked
                        ? BACKGROUND_COLOR_FOUND
                        : BACKGROUND_COLOR,
                    color: isCorrectClicked ? TEXT_COLOR_FOUND : TEXT_COLOR
                }}
                transition={{ duration: 0.5 }}
            >
                <motion.p
                    className={classNames(
                        questionStyles.fontBold,
                        'text-center'
                    )}
                    style={{
                        color: isCorrectClicked
                            ? word.categoryColor
                            : TEXT_COLOR
                    }}
                >
                    {word.translation}
                </motion.p>
                {showCursorHint && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={cursorWrapperControls}
                        className="absolute"
                        style={{
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, 0)',
                            width: '52px',
                            height: '75px'
                        }}
                    >
                        <motion.img
                            src={cursorTapIcon}
                            animate={cursorControls}
                            className="h-full w-full"
                        />
                    </motion.div>
                )}
            </motion.div>
        </button>
    );
};

export default QuestionTranslation;
