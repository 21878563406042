import { Panel, TextButton } from '@/components';
import { useLabels, useNavigation } from '@/context';
import { commonStyles } from '@/styles/commonStyles';

const TimeToPlayScreen = () => {
    const { getLabel } = useLabels();
    const { navigate } = useNavigation();

    const onContinuePress = () => {
        navigate('Puzzle');
    };

    const title = getLabel('tutorial.time-to-play');
    const text = getLabel('tutorial.time-to-play-text');
    const startLearning = getLabel('tutorial.lets-play', true);

    return (
        <div className="flex h-full flex-col justify-center">
            <Panel className={commonStyles.panelWrapper}>
                <h1>{title}</h1>
                <p className={commonStyles.fontRegular}>{text}</p>
            </Panel>
            <TextButton onClick={onContinuePress} text={startLearning} />
        </div>
    );
};

export default TimeToPlayScreen;
