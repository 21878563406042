import { PuzzleData, PuzzleType } from '../types';

import ChooseTranslationQuestionsPanel from './ChooseTranslationQuestionsPanel';
import FindWordQuestionsPanel from './FindWordQuestionsPanel';

interface QuestionsPanelProps {
    puzzleData: PuzzleData;
    studyLanguage: string;
    wordsFound: number;
}

const QuestionsPanel: React.FC<QuestionsPanelProps> = ({
    puzzleData: {
        puzzleType,
        wordsToPlay: words,
        selectedTileColor: selectionColor
    },
    studyLanguage,
    wordsFound
}) => {
    const renderQuestions = () => {
        switch (puzzleType) {
            case PuzzleType.BasicWithTranslation:
            case PuzzleType.BasicWithoutTranslation:
                return (
                    <FindWordQuestionsPanel
                        words={words}
                        puzzleType={puzzleType}
                        studyLanguage={studyLanguage}
                    />
                );
            case PuzzleType.ChooseTranslation:
                return (
                    <ChooseTranslationQuestionsPanel
                        words={words}
                        selectionColor={selectionColor}
                        studyLanguage={studyLanguage}
                        wordsFound={wordsFound}
                    />
                );
            default:
                return null;
        }
    };

    return renderQuestions();
};

export default QuestionsPanel;
