import { WordInfoLocalized } from '@/types/category';
import { getCleanWord, toUpperCaseLetter } from '@/utils/language';

class WordInfo {
    public readonly id: string;
    public readonly cleanWord: string;

    constructor(id: string, cleanWord: string) {
        this.id = id;
        this.cleanWord = cleanWord;
    }
}

export class LevelData {
    words: string[] = [];

    private numLettersInLevel: number = -1;
    private maxWordLength: number = -1;
    private minWordLength: number = -1;
    private wordIdMap: Record<string, string> = {};

    constructor(words: WordInfoLocalized[], studyLanguage: string) {
        words.forEach(contentInfo => {
            const cleanWord = getCleanWord(contentInfo.word, studyLanguage)
                .split('')
                .map(letter => toUpperCaseLetter(letter))
                .join('');
            const wordInfo = new WordInfo(contentInfo.id, cleanWord);

            this.words.push(cleanWord);
            this.wordIdMap[wordInfo.cleanWord] = wordInfo.id;
        });
    }

    get Words(): string[] {
        return this.words;
    }

    get NumLettersInLevel(): number {
        if (this.numLettersInLevel === -1) {
            this.setLettersInLevel();
        }
        return this.numLettersInLevel;
    }

    get MaxWordLength(): number {
        if (this.maxWordLength === -1) {
            this.setMaxWordLength();
        }
        return this.maxWordLength;
    }

    get MinWordLength(): number {
        if (this.minWordLength === -1) {
            this.setMinWordLength();
        }
        return this.minWordLength;
    }

    isWordInLevel(word: string): boolean {
        return this.Words.includes(word);
    }

    getWordId(word: string): string | null {
        const id = this.wordIdMap[word.toUpperCase()];
        return id || null;
    }

    private setLettersInLevel(): void {
        this.numLettersInLevel = this.Words.reduce(
            (acc, word) => acc + word.length,
            0
        );
    }

    private setMaxWordLength(): void {
        this.maxWordLength = this.Words.reduce(
            (max, word) => Math.max(max, word.length),
            Number.MIN_VALUE
        );
    }

    private setMinWordLength(): void {
        this.minWordLength = this.Words.reduce(
            (min, word) => Math.min(min, word.length),
            Number.MAX_VALUE
        );
    }
}
