import { useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import {
    useGameProgress,
    useNavigation,
    useReviewGameSession,
    useSettings
} from '@/context';
import emitter, { WrongTranslationSelectedPayload } from '@/events/emitter';
import useDisableVerticalSwipe from '@/hooks/useDisableVertialSwipe';
import { WordInfoLocalized } from '@/types/category';
import { COLORS } from '@/utils/colors';
import { WORDS_PER_REVIEW_STEP } from '@/utils/constants';

import GameStep from './GameStep';
import HintsPanel from './HintsPanel';
import { PuzzleData, PuzzleType } from './types';

const ReviewPuzzleScreen = () => {
    useDisableVerticalSwipe();

    const { navigate } = useNavigation();
    const { getWordsToReview, isNoWordsToReviewWithSpacedRep } =
        useGameProgress();
    const {
        reviewGameSession,
        completeReviewGameSession,
        updateReviewGameSessionSteps
    } = useReviewGameSession();
    const { settings } = useSettings();

    const [wordsReviewed, setWordsReviewed] = useState<WordInfoLocalized[]>([]);
    const [wrongWordIds, setWrongWordIds] = useState<string[]>([]);
    const [wordsReviewedCorrectly, setWordsReviewedCorrectly] = useState<
        WordInfoLocalized[]
    >([]);
    const [updateInitiated, setUpdateInitiated] = useState(false);

    const wordsToReview = useMemo(
        () => getWordsToReview(settings?.studyLanguage),
        [settings?.studyLanguage]
    );
    const totalSteps = Math.ceil(wordsToReview.length / WORDS_PER_REVIEW_STEP);

    useEffect(() => {
        updateReviewGameSessionSteps(1, totalSteps);
    }, [totalSteps]);

    const puzzleData = useMemo<PuzzleData>(() => {
        const puzzleType = PuzzleType.ChooseTranslation;
        const currentStep = reviewGameSession.currentStep;

        const startIndex = (currentStep - 1) * WORDS_PER_REVIEW_STEP;
        const endIndex = currentStep * WORDS_PER_REVIEW_STEP;

        const wordsToPlay = wordsToReview.slice(startIndex, endIndex);

        let selectedTileColor = COLORS.blue;
        const firstWord = wordsToPlay[0];

        if (firstWord) {
            const categoryId = firstWord.categoryId;
            const allWordsAreFromTheSameCategory = wordsToPlay.every(
                word => word.categoryId === categoryId
            );

            if (allWordsAreFromTheSameCategory) {
                selectedTileColor = firstWord.categoryColor;
            }
        }

        return {
            puzzleType,
            wordsToPlay,
            selectedTileColor
        };
    }, [reviewGameSession.currentStep, wordsToReview]);

    useEffect(() => {
        const handleCorrectAnswerGiven = (payload: {
            word: WordInfoLocalized;
        }) => {
            setWordsReviewed(prevWords => [...prevWords, payload.word]);
        };

        const handleCorrectTranslationSelected = (payload: {
            word: WordInfoLocalized;
        }) => {
            // Skip correct words in "no words to review with spaced repetition" mode
            if (isNoWordsToReviewWithSpacedRep) return;

            if (!wrongWordIds.includes(payload.word.id)) {
                setWordsReviewedCorrectly(prevWords => [
                    ...prevWords,
                    payload.word
                ]);
            }
        };

        const handleInvalidWordSelected = () => {
            emitter.emit('playSound', { sound: SoundName.Wrong });
        };

        const handleWrongTranslationSelected = ({
            word
        }: WrongTranslationSelectedPayload) => {
            setWrongWordIds(prevWordIds => [...prevWordIds, word.id]);
        };

        emitter.on('correctAnswerGiven', handleCorrectAnswerGiven);
        emitter.on('invalidWordSelected', handleInvalidWordSelected);
        emitter.on(
            'correctTranslationSelected',
            handleCorrectTranslationSelected
        );
        emitter.on('wrongTranslationSelected', handleWrongTranslationSelected);

        return () => {
            emitter.off('correctAnswerGiven', handleCorrectAnswerGiven);
            emitter.off('invalidWordSelected', handleInvalidWordSelected);
            emitter.off(
                'correctTranslationSelected',
                handleCorrectTranslationSelected
            );
            emitter.off(
                'wrongTranslationSelected',
                handleWrongTranslationSelected
            );
        };
    }, [
        puzzleData,
        wrongWordIds,
        wordsReviewedCorrectly,
        isNoWordsToReviewWithSpacedRep
    ]);

    const completeAndNavigate = async () => {
        await completeReviewGameSession(wordsReviewedCorrectly, wordsReviewed);
        navigate('ReviewResults');
    };

    useEffect(() => {
        if (reviewGameSession.currentStep > totalSteps && !updateInitiated) {
            setUpdateInitiated(true);
            completeAndNavigate();
        }
    }, [reviewGameSession.currentStep, totalSteps, updateInitiated]);

    const onAllWordsFound = () => {
        const nextStep = reviewGameSession.currentStep + 1;
        updateReviewGameSessionSteps(nextStep, totalSteps);
    };

    const onLeavePuzzle = async () => {
        if (wordsReviewedCorrectly.length === 0 && wordsReviewed.length === 0) {
            navigate('ChooseCategory');
            return;
        }
        await completeReviewGameSession(wordsReviewedCorrectly, wordsReviewed);
        navigate('ReviewResults');
    };

    if (
        !puzzleData.wordsToPlay?.length ||
        reviewGameSession.currentStep > totalSteps
    ) {
        return null;
    }

    return (
        <div className="flex h-full flex-col justify-center">
            <GameStep
                key={reviewGameSession.currentStep}
                puzzleData={puzzleData}
                studyLanguage={settings.studyLanguage}
                onAllWordsFound={onAllWordsFound}
                showCursor={false}
            />
            <motion.div
                className="mb-2 mt-5 px-3"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.5 } }}
            >
                <HintsPanel
                    puzzleType={puzzleData.puzzleType}
                    onLeavePuzzle={onLeavePuzzle}
                    isReview
                />
            </motion.div>
        </div>
    );
};

export default ReviewPuzzleScreen;
