import { createRef, type FC, memo, useEffect, useRef, useState } from 'react';
import { animated } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { motion } from 'framer-motion';

import emitter, { CorrectTranslationSelectedPayload } from '@/events/emitter';
import useCursorAnimation from '@/hooks/useCursorAnimation';
import { PuzzleType } from '@/screens/puzzle/types';
import { MAX_TILE_SIZE } from '@/utils/constants';

import cursorIcon from '../../assets/images/icons/cursor.png';

import { BoardData } from './types/BoardData';
import { GridCell } from './types/GridCell';
import { LevelData } from './types/LevelData';
import { LevelSaveData } from './types/LevelSaveData';
import { SelectableWord } from './types/SelectableWord';
import { createBoardData, createInitialBoardData } from './board-creator';
import GridLetterTile, { GridLetterTileHandle } from './GridLetterTile';
import { printBoardDataCells } from './print-puzzle';
import { findSelectableWords } from './selectableWordsFinder';

interface GridHandlerProps {
    puzzleType: PuzzleType;
    levelData: LevelData;
    levelSaveData: LevelSaveData;
    selectedTileColor: string;
    previousBoardData?: BoardData;
    onBoardDataReady?: (boardData: BoardData) => void;
    showCursor?: boolean;
}

enum SelectState {
    None,
    Selecting
}

const GridHandler: FC<GridHandlerProps> = ({
    puzzleType,
    selectedTileColor,
    levelData,
    levelSaveData,
    previousBoardData,
    onBoardDataReady,
    showCursor = false
}) => {
    const rootContainerRef = useRef(null);
    const tilesContainerRef = useRef(null);

    const { moveCursor, stopCursorAnimation, cursorRef, style } =
        useCursorAnimation();

    const activeLevelSaveDataRef = useRef(levelSaveData);
    const selectableWordsRef = useRef<SelectableWord[]>([]);

    const gridRef = useRef<GridCell[][]>([]);
    const isGridSetupFinishedRef = useRef(false);

    const startGridCellRef = useRef<GridCell>();
    const endGridCellRef = useRef<GridCell>();
    const selectStateRef = useRef<SelectState>(SelectState.None);

    const selectedGridCellsRef = useRef<GridCell[]>([]);
    const selectedWordRef = useRef('');

    const hintWordRef = useRef<SelectableWord | null>(null);
    const hintIndexRef = useRef<number>(0);

    const cursorHintCountdownStartsAtRef = useRef(new Date());
    const showCursorHintFirstTimeRef = useRef(true);

    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [tileSize, setTileSize] = useState(0);
    const [layoutSet, setLayoutSet] = useState(false);

    const numTilesMovingRef = useRef(0);

    useEffect(() => {
        const boardData = createInitialBoardData(levelData, previousBoardData);
        if (onBoardDataReady) onBoardDataReady(boardData);

        activeLevelSaveDataRef.current.boardData = boardData;
        selectableWordsRef.current = findSelectableWords(
            levelData,
            activeLevelSaveDataRef.current
        );

        console.log('Initial words:', JSON.stringify(levelData.Words));
        console.log(
            'selectableWords',
            JSON.stringify(selectableWordsRef.current)
        );
    }, []);

    useEffect(() => {
        const onAudioHintRequested = () => {
            onAudioHintRequestedRef.current();
        };

        const onMagicWandHintRequested = () => {
            onMagicWandHintRequestedRef.current();
        };

        const onMagicWandHintShowTile = () => {
            onMagicWandHintShowTileRef.current();
        };

        emitter.on('audioHintRequested', onAudioHintRequested);
        emitter.on('magicWandHintRequested', onMagicWandHintRequested);
        emitter.on('magicWandHintShowTile', onMagicWandHintShowTile);

        return () => {
            emitter.off('audioHintRequested', onAudioHintRequested);
            emitter.off('magicWandHintRequested', onMagicWandHintRequested);
            emitter.off('magicWandHintShowTile', onMagicWandHintShowTile);
        };
    }, []);

    const onCorrectTranslationSelectedRef = useRef(
        (payload: CorrectTranslationSelectedPayload) => {
            hideValidWord(payload.cleanWord);
        }
    );

    const onAudioHintRequestedRef = useRef(() => {
        handleAudioHintRequested();
    });

    onAudioHintRequestedRef.current = () => {
        // make sure that handleAudioHintRequested has access to latest refs
        handleAudioHintRequested();
    };

    const handleAudioHintRequested = () => {
        console.log('Audio hint requested');
        console.log('Selectable words:', selectableWordsRef.current.length);
        if (selectableWordsRef.current.length === 0) {
            emitter.emit('audioHintCannotPlay');
            return;
        }

        // probably a word is already selected in ChooseTranslation mode, no need to waste player's coins
        if (selectedGridCellsRef.current.length > 0) {
            emitter.emit('audioHintCannotPlay');
            return;
        }

        const wordId = levelData.getWordId(selectableWordsRef.current[0].word);
        emitter.emit('audioHintWordSelected', { wordId });
    };

    onCorrectTranslationSelectedRef.current = payload => {
        // make sure that hideValidWord has access to latest refs
        hideValidWord(payload.cleanWord);
    };

    const onMagicWandHintRequestedRef = useRef(() => {
        handleMagicWandHintRequested();
    });

    onMagicWandHintRequestedRef.current = () => {
        // make sure that handleMagicWandHintRequested has access to latest refs
        handleMagicWandHintRequested();
    };

    const handleMagicWandHintRequested = () => {
        console.log('Magic wand hint requested');
        console.log('Selectable words:', selectableWordsRef.current.length);

        if (selectableWordsRef.current.length === 0) {
            emitter.emit('magicWandHintCannotFind');
            return;
        }

        // probably a word is already selected in ChooseTranslation mode, no need to waste player's coins
        if (selectedGridCellsRef.current.length > 0) {
            emitter.emit('magicWandHintCannotFind');
            return;
        }

        const hintResult = selectHint(activeLevelSaveDataRef.current.tileHints);
        if (!hintResult) {
            emitter.emit('magicWandHintCannotFind');
            return;
        }

        hintWordRef.current = hintResult.hintWord;
        hintIndexRef.current = hintResult.hintIndex;

        emitter.emit('magicWandHintTileFound');
    };

    const onMagicWandHintShowTileRef = useRef(() => {
        handleMagicWandHintShowTile();
    });

    onMagicWandHintShowTileRef.current = () => {
        // make sure that hideValidWord has access to latest refs
        handleMagicWandHintShowTile();
    };

    const handleMagicWandHintShowTile = () => {
        console.log('Magic wand hint show tile');
        if (!hintWordRef.current) return;

        const hintWord = hintWordRef.current;
        const hintIndex = hintIndexRef.current;

        console.log('Hint word:', hintWord.word);
        console.log('Hint index:', hintIndex);

        const rowCol = getHintTileRowCol(hintWord, hintIndex);
        const cell = gridRef.current[rowCol[0]][rowCol[1]];

        cell.isHintHighlighted = true;
        cell.letterTile.current.showHint();
    };

    useEffect(() => {
        const onCorrectTranslationSelected = (
            payload: CorrectTranslationSelectedPayload
        ) => {
            onCorrectTranslationSelectedRef.current(payload);
        };

        emitter.on('correctTranslationSelected', onCorrectTranslationSelected);

        return () => {
            emitter.off(
                'correctTranslationSelected',
                onCorrectTranslationSelected
            );
        };
    }, []);

    const showCursorHintRef = useRef(() => {
        showCursorHint();
    });

    showCursorHintRef.current = () => {
        // make sure that showCursorHint has access to latest refs
        showCursorHint();
    };

    const showCursorHint = () => {
        if (
            !selectableWordsRef?.current.length ||
            numTilesMovingRef.current > 0
        ) {
            return;
        }

        const CURSOR_HINT_INTERVAL = 5000;

        if (
            new Date().getTime() -
                cursorHintCountdownStartsAtRef?.current.getTime() <
            CURSOR_HINT_INTERVAL
        ) {
            return;
        }

        cursorHintCountdownStartsAtRef.current = new Date();
        showCursorHintFirstTimeRef.current = false;

        const selectableWord = selectableWordsRef.current[0];
        let startRow: number, startCol: number, endRow: number, endCol: number;

        startCol = endCol = selectableWord.startCol; // Column remains constant for vertical words

        if (selectableWord.isVertical) {
            if (selectableWord.isReversed) {
                // The provided start is actually the bottom-most point of the word (last letter)
                startRow =
                    selectableWord.startRow + (selectableWord.word.length - 1);
                endRow = selectableWord.startRow; // The word extends upwards, hence endRow is less than startRow
            } else {
                // The provided start is actually the top-most point of the word (first letter)
                startRow = selectableWord.startRow;
                endRow =
                    selectableWord.startRow + (selectableWord.word.length - 1); // The word extends downwards
            }
        } else {
            startRow = endRow = selectableWord.startRow; // Row remains constant for horizontal words

            if (selectableWord.isReversed) {
                // The provided start is the right-most point of the word (last letter)
                startCol =
                    selectableWord.startCol + (selectableWord.word.length - 1);
                endCol = selectableWord.startCol; // The word extends leftwards
            } else {
                // The provided start is the left-most point of the word (first letter)
                startCol = selectableWord.startCol;
                endCol =
                    selectableWord.startCol + (selectableWord.word.length - 1); // The word extends rightwards
            }
        }

        if (
            startRow >= 0 &&
            endRow >= 0 &&
            startRow < gridRef.current.length &&
            endRow < gridRef.current.length &&
            startCol >= 0 &&
            endCol >= 0 &&
            startCol < gridRef.current[startRow].length &&
            endCol < gridRef.current[endRow].length
        ) {
            const startTile = gridRef.current[startRow][startCol].letterTile;
            const endTile = gridRef.current[endRow][endCol].letterTile;

            if (startTile && endTile) {
                moveCursor(startTile, endTile);
            } else {
                console.error(
                    'Show cursor hint error! One or both calculated tiles are undefined.',
                    'Start Tile:',
                    startTile,
                    'End Tile:',
                    endTile
                );
            }
        } else {
            console.error(
                'Show cursor hint error! One or more calculated indices are out of grid bounds.'
            );
        }
    };

    const scheduleCursorHintIfNeeded = () => {
        if (!showCursor) return;

        const intervalId = setInterval(() => {
            showCursorHintRef.current();
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    };

    useEffect(() => {
        scheduleCursorHintIfNeeded();
    }, []);

    // Creates the starting board cells
    const setupGrid = () => {
        const boardData = activeLevelSaveDataRef.current.boardData;

        for (let row = 0; row < boardData.rows; row++) {
            const gridRow: GridCell[] = [];

            for (let col = 0; col < boardData.cols; col++) {
                const letter = boardData.board[row][col];
                const isBlank = letter === '\0';
                const gridCell: GridCell = {
                    row,
                    col,
                    letter,
                    isBlank,
                    isHintHighlighted: false,
                    letterTile: createRef()
                };
                gridRow.push(gridCell);
            }

            gridRef.current.push(gridRow);
        }

        isGridSetupFinishedRef.current = true;
    };

    useEffect(() => {
        if (
            !activeLevelSaveDataRef.current.boardData?.board ||
            isGridSetupFinishedRef.current
        ) {
            return;
        }

        setupGrid();
    }, [activeLevelSaveDataRef.current.boardData]);

    const computeTileSize = (
        cols: number,
        rows: number,
        width: number,
        height: number
    ) => {
        const potentialHorizontalSize = width / cols;
        const potentialVerticalSize = height / rows;
        return Math.min(
            potentialHorizontalSize,
            potentialVerticalSize,
            MAX_TILE_SIZE
        );
    };

    useEffect(() => {
        if (
            !activeLevelSaveDataRef.current.boardData?.board ||
            !containerHeight ||
            !containerWidth
        ) {
            return;
        }

        const newSize = computeTileSize(
            activeLevelSaveDataRef.current.boardData.cols,
            activeLevelSaveDataRef.current.boardData.rows,
            containerWidth,
            containerHeight
        );
        setTileSize(newSize);
    }, [containerWidth, containerHeight, activeLevelSaveDataRef.current]);

    // Gets the position in the grid for the given row/col
    const getCellPosition = (row: number, col: number, rows: number) => ({
        left: col * tileSize,
        top: (rows - 1 - row) * tileSize // Invert the row index to start from the bottom
    });

    // Gets the grid cell which contains the given position
    const getGridCellAt = (
        x: number,
        y: number,
        grid: GridCell[][],
        tileSize: number
    ): GridCell | null => {
        for (let row = 0; row < grid.length; row++) {
            for (let col = 0; col < grid[row].length; col++) {
                const gridCell = grid[row][col];
                const cellPos = getCellPosition(row, col, grid.length);

                // Define the bounds of the cell
                const left = cellPos.left;
                const right = cellPos.left + tileSize;
                const top = cellPos.top;
                const bottom = cellPos.top + tileSize;

                // Check if the coordinates fall within the bounds of the cell
                if (x >= left && x <= right && y >= top && y <= bottom) {
                    return gridCell;
                }
            }
        }

        return null; // Return null if the position is outside the grid
    };

    // Gets the closest grid cell to the given position
    function getClosestGridCell(
        x: number,
        y: number,
        tileSize: number
    ): GridCell {
        let closestGridCell: GridCell | null = null;
        let minDist = Infinity;

        // Iterate through each cell to find the one with the minimum distance to the given x, y
        gridRef.current.forEach((rowCells, row) => {
            rowCells.forEach((gridCell, col) => {
                const cellX = col * tileSize + tileSize / 2; // Center of the cell horizontally
                const cellY =
                    activeLevelSaveDataRef.current.boardData.rows * tileSize -
                    row * tileSize -
                    tileSize / 2; // Center of the cell vertically, adjusted for bottom-left origin

                const dist = Math.sqrt(
                    Math.pow(cellX - x, 2) + Math.pow(cellY - y, 2)
                );

                if (dist < minDist) {
                    minDist = dist;
                    closestGridCell = gridCell;
                }
            });
        });

        return closestGridCell;
    }

    // Sets the tiles from start to end as selected and updates the selectedWord
    const setSelectedTiles = (start: GridCell, end: GridCell) => {
        const rowInc = start.row <= end.row ? 1 : -1;
        const colInc = start.col <= end.col ? 1 : -1;

        const newSelectedGridCells: GridCell[] = [];
        let newSelectedWord = '';

        for (
            let row = start.row;
            rowInc > 0 ? row <= end.row : row >= end.row;
            row += rowInc
        ) {
            for (
                let col = start.col;
                colInc > 0 ? col <= end.col : col >= end.col;
                col += colInc
            ) {
                const gridCell = gridRef.current[row][col];

                // If we encounter a blank cell then stop
                if (gridCell.isBlank) {
                    break;
                }

                // Accumulate the letter and cell
                newSelectedWord += gridCell.letter;
                newSelectedGridCells.push(gridCell);
            }
        }

        animateUpdatedSelection(
            selectedGridCellsRef.current,
            newSelectedGridCells
        );

        selectedGridCellsRef.current = newSelectedGridCells;

        if (newSelectedWord !== selectedWordRef.current) {
            selectedWordRef.current = newSelectedWord;
            emitter.emit('wordSelectionChanged', { word: newSelectedWord });
        }
    };

    const selectHint = (hintIndices: Record<string, number>) => {
        let hintWord: SelectableWord | null = null;
        let hintIndex = Number.MAX_SAFE_INTEGER;

        selectableWordsRef.current.forEach(wordObj => {
            const word = wordObj.word;
            let nextHintIndex = 0;

            if (hintIndices[word] !== undefined) {
                nextHintIndex = hintIndices[word] + 1;
            }

            if (nextHintIndex < word.length && nextHintIndex < hintIndex) {
                hintWord = wordObj;
                hintIndex = nextHintIndex;
            }
        });

        if (hintWord === null) {
            return false; // No hint to show
        }

        if (hintIndex === 0) {
            hintIndices[hintWord.word] = 0;
        } else {
            hintIndices[hintWord.word] = hintIndex;
        }

        return { hintWord, hintIndex };
    };

    const getHintTileRowCol = (
        selectableWord: SelectableWord,
        letterIndex: number
    ): [number, number] => {
        let row = selectableWord.startRow;
        let col = selectableWord.startCol;

        if (selectableWord.isVertical) {
            if (selectableWord.isReversed) {
                row =
                    selectableWord.startRow +
                    selectableWord.word.length -
                    letterIndex -
                    1;
            } else {
                row = selectableWord.startRow + letterIndex;
            }
        } else {
            if (selectableWord.isReversed) {
                col =
                    selectableWord.startCol +
                    selectableWord.word.length -
                    letterIndex -
                    1;
            } else {
                col = selectableWord.startCol + letterIndex;
            }
        }

        return [row, col];
    };

    // Animates the updated selection of grid cells
    const animateUpdatedSelection = (
        prevSelectedGridCells: GridCell[],
        newSelectedGridCells: GridCell[]
    ) => {
        const newlySelectedGridCells = newSelectedGridCells.filter(
            cell => !prevSelectedGridCells.includes(cell)
        );

        const newlyDeselectedGridCells = prevSelectedGridCells.filter(
            cell => !newSelectedGridCells.includes(cell)
        );

        newlySelectedGridCells.forEach(cell => {
            cell.letterTile.current?.select();
        });

        newlyDeselectedGridCells.forEach(cell => {
            cell.letterTile.current?.deselect();
        });
    };

    // Sets the grid cell that the given position is over as the starting grid cell
    const setSelectStart = (x: number, y: number) => {
        const gridCell = getGridCellAt(x, y, gridRef.current, tileSize);

        if (gridCell && !gridCell.isBlank) {
            selectStateRef.current = SelectState.Selecting;
            startGridCellRef.current = gridCell;
            // printCell('Start cell', startGridCellRef.current);
        }
    };

    // Sets the grid cell that the given position is over as the ending grid cell
    const setSelectEnd = (x: number, y: number) => {
        let gridCell = getGridCellAt(x, y, gridRef.current, tileSize);
        if (gridCell === null) {
            gridCell = getClosestGridCell(x, y, tileSize);
        }

        // If the grid cell is on the same row/column as the start cell, it's the end cell
        if (
            gridCell.row === startGridCellRef.current.row ||
            gridCell.col === startGridCellRef.current.col
        ) {
            endGridCellRef.current = gridCell;
        } else {
            // Find the closest cell on the same row or column as the start cell
            const rowDiff = Math.abs(
                gridCell.row - startGridCellRef.current.row
            );
            const colDiff = Math.abs(
                gridCell.col - startGridCellRef.current.col
            );

            if (rowDiff <= colDiff) {
                gridCell =
                    gridRef.current[startGridCellRef.current.row][gridCell.col];
            } else {
                gridCell =
                    gridRef.current[gridCell.row][startGridCellRef.current.col];
            }
        }

        endGridCellRef.current = gridCell;
    };

    // Updates the selectStart and selectEnd tiles
    const updateSelected = (x: number, y: number) => {
        if (selectStateRef.current === SelectState.None) {
            setSelectStart(x, y);
        }

        if (selectStateRef.current === SelectState.Selecting) {
            setSelectEnd(x, y);
        }

        updateSelectedTiles();
    };

    // Updates the selected tiles when the user interacts with the screen
    const updateSelectedTiles = () => {
        if (!startGridCellRef.current) return;

        setSelectedTiles(startGridCellRef.current, endGridCellRef.current);
    };

    // Clears variables used for selecting tiles
    const clearSelection = () => {
        selectStateRef.current = SelectState.None;
        startGridCellRef.current = null;
        endGridCellRef.current = null;
        selectedGridCellsRef.current = [];
        selectedWordRef.current = '';
    };

    const clearSelectionIfNeeded = () => {
        if (
            selectedGridCellsRef.current.length === 0 ||
            puzzleType !== PuzzleType.ChooseTranslation
        ) {
            return;
        }

        selectedGridCellsRef.current.forEach(gridCell => {
            if (gridCell.letterTile.current) {
                gridCell.letterTile.current?.deselect();
            }
        });
        clearSelection();
    };

    // Sets the grid cell as a blank cell
    const setBlank = (gridCell: GridCell) => {
        gridCell.letter = '\0';
        gridCell.letterTile = null;
        gridCell.isBlank = true;
    };

    const handleHideTilesAnimationComplete = (
        minCol: number,
        maxCol: number,
        minRow: number,
        maxRow: number
    ) => {
        selectedGridCellsRef.current.forEach(gridCell => {
            setBlank(gridCell);
            activeLevelSaveDataRef.current.boardData.board[gridCell.row][
                gridCell.col
            ] = '\0';
        });

        // Only reposition tiles after all remove animations have completed
        repositionTiles(minCol, maxCol, minRow, maxRow);
        clearSelection();

        // printBoardDataCells(
        //     activeLevelSaveDataRef.current.boardData,
        //     'After hide'
        // );
        // printGridCellsWithCoordinates(gridRef.current, 'After hide');

        const newSelectableWords = findSelectableWords(
            levelData,
            activeLevelSaveDataRef.current
        );
        console.log('newSelectableWords', JSON.stringify(newSelectableWords));
        selectableWordsRef.current = newSelectableWords;
        if (
            newSelectableWords.length === 0 &&
            levelSaveData.foundLevelWords.size !== levelData.words.length
        ) {
            reshuffle();
        }
    };

    const reshuffle = () => {
        const remainingWords: string[] = [];

        levelData.words.forEach(word => {
            if (!activeLevelSaveDataRef.current.foundLevelWords.has(word)) {
                remainingWords.push(word);
            }
        });

        const reshuffledBoardData = createBoardData(
            remainingWords,
            activeLevelSaveDataRef.current.boardData.rows,
            activeLevelSaveDataRef.current.boardData.cols
        );
        activeLevelSaveDataRef.current.boardData = reshuffledBoardData;
        const newSelectableWords = findSelectableWords(
            levelData,
            activeLevelSaveDataRef.current
        );

        console.log('Remaining words:', remainingWords);
        console.log(
            'New selectable words after reshuffling:',
            JSON.stringify(newSelectableWords)
        );
        selectableWordsRef.current = newSelectableWords;
        resetTileHints();
        printBoardDataCells(reshuffledBoardData, 'Reshuffled board');
        waitForDropThenShuffleTiles();
    };

    const resetTileHints = () => {
        activeLevelSaveDataRef.current.tileHints = {};
        hintWordRef.current = null;
        hintIndexRef.current = 0;

        gridRef.current.forEach(row => {
            row.forEach(gridCell => {
                if (!gridCell.isBlank && gridCell.isHintHighlighted) {
                    gridCell.isHintHighlighted = false;
                    gridCell.letterTile.current?.hideHint();
                }
            });
        });
    };

    function shuffleBoard(): void {
        const allGridLetterTiles: Record<
            string,
            React.RefObject<GridLetterTileHandle>[]
        > = {};

        // Clear all cells and collect letter tiles by letter
        gridRef.current.forEach(row => {
            row.forEach(gridCell => {
                if (gridCell.isBlank) return;

                const letter = gridCell.letter;
                if (!allGridLetterTiles[letter]) {
                    allGridLetterTiles[letter] = [];
                }

                if (gridCell.letterTile) {
                    allGridLetterTiles[letter].push(gridCell.letterTile);
                    setBlank(gridCell);
                }
            });
        });

        // Reassign letter tiles to new positions
        activeLevelSaveDataRef.current.boardData.board.forEach(
            (row, rowIndex) => {
                row.forEach((letter, colIndex) => {
                    if (letter === '\0') return;

                    const gridLetterTiles = allGridLetterTiles[letter];
                    if (!gridLetterTiles || gridLetterTiles.length === 0)
                        return;

                    const letterTile = gridLetterTiles.shift();
                    const gridCell = gridRef.current[rowIndex][colIndex];

                    if (letterTile) {
                        gridCell.letterTile = letterTile;
                        gridCell.letter = letter;
                        gridCell.isBlank = false;

                        const position = getCellPosition(
                            rowIndex,
                            colIndex,
                            gridRef.current.length
                        );
                        numTilesMovingRef.current++;
                        letterTile.current.move(position, () => {
                            numTilesMovingRef.current--;
                        });
                    }
                });
            }
        );
    }

    // Called when a selected word has been found in the level
    const handleValidWordSelected = (word: string) => {
        emitter.emit('validWordSelected', {
            wordId: levelData.getWordId(word)
        });

        // wait until correct translation is selected
        if (puzzleType === PuzzleType.ChooseTranslation) {
            selectedGridCellsRef.current.forEach(gridCell => {
                gridCell.letterTile.current?.pulse();
            });
            return;
        }

        if (showCursor) {
            stopCursorAnimation();
            cursorHintCountdownStartsAtRef.current = new Date();
        }

        hideValidWord(word);
    };

    const hideValidWord = (word: string) => {
        activeLevelSaveDataRef.current.foundLevelWords.add(word);

        let minCol = Number.MAX_SAFE_INTEGER;
        let maxCol = Number.MIN_SAFE_INTEGER;
        let minRow = Number.MAX_SAFE_INTEGER;
        let maxRow = Number.MIN_SAFE_INTEGER;

        let animationCompletedCount = 0;
        const totalAnimations = selectedGridCellsRef.current.length;

        selectedGridCellsRef.current.forEach(gridCell => {
            minCol = Math.min(minCol, gridCell.col);
            maxCol = Math.max(maxCol, gridCell.col);
            minRow = Math.min(minRow, gridCell.row);
            maxRow = Math.max(maxRow, gridCell.row);
        });

        selectedGridCellsRef.current.forEach(gridCell => {
            gridCell.letterTile.current?.remove(() => {
                animationCompletedCount++;
                if (animationCompletedCount === totalAnimations) {
                    handleHideTilesAnimationComplete(
                        minCol,
                        maxCol,
                        minRow,
                        maxRow
                    );
                }
            });
        });
    };

    // Called when a selected word was not a valid word in the level
    const handleInvalidWordSelected = () => {
        if (selectedGridCellsRef.current.length === 0) return;

        emitter.emit('invalidWordSelected');
        selectedGridCellsRef.current.forEach(gridCell => {
            gridCell.letterTile.current?.shake();
        });
    };

    // Drops all tiles above the tiles specified by the given start/end row/col
    const repositionTiles = (
        startCol: number,
        endCol: number,
        startRow: number,
        endRow: number
    ) => {
        // console.log('Repositioning tiles', startCol, endCol, startRow, endRow);
        const dropAmount = endRow - startRow + 1;

        for (let col: number = startCol; col <= endCol; col++) {
            dropTiles(col, endRow + 1, dropAmount);
        }

        if (startRow === 0 || endRow === 0) {
            const shiftInfo = isShiftNeeded();

            if (shiftInfo.isShiftNeeded) {
                console.log('Shift needed');

                // Call shiftTiles with setBoardData set to true so only the levelSaveData.boardData is updated
                // This won't move the tiles on the board
                shiftTiles(
                    shiftInfo.firstNonBlankCol,
                    shiftInfo.lastNonBlankCol,
                    true
                );

                // Wait for the drops to complete then start the shift animations
                waitForDropThenShiftTiles(
                    shiftInfo.firstNonBlankCol,
                    shiftInfo.lastNonBlankCol
                );
            }
        }
    };

    // Waits until all tiles have dropped before starting the shift animations
    const waitForDropThenShiftTiles = (
        firstNonBlankCol: number,
        lastNonBlankCol: number
    ) => {
        if (numTilesMovingRef.current > 0) {
            // Check again after some time if tiles are still moving
            setTimeout(
                () =>
                    waitForDropThenShiftTiles(
                        firstNonBlankCol,
                        lastNonBlankCol
                    ),
                50
            );
        } else {
            // All tiles have dropped, now proceed with shifting
            shiftTiles(firstNonBlankCol, lastNonBlankCol, false);
        }
    };

    // Waits until all tiles have dropped before starting the shuffle animations
    const waitForDropThenShuffleTiles = () => {
        if (numTilesMovingRef.current > 0) {
            // Check again after some time if tiles are still moving
            setTimeout(() => waitForDropThenShuffleTiles(), 50);
        } else {
            // All tiles have dropped, now proceed with shuffling
            shuffleBoard();
        }
    };

    // Shifts the tiles so there are no blanks between first and last non blank cell
    const shiftTiles = (
        firstNonBlankCol: number,
        lastNonBlankCol: number,
        setBoardData: boolean
    ) => {
        console.log('Shifting tiles', firstNonBlankCol, lastNonBlankCol);

        // Now shift the board left/right if there are blank cells at the bottom
        if (
            activeLevelSaveDataRef.current.boardData.cols - lastNonBlankCol >
            firstNonBlankCol + 1
        ) {
            // Shift everything left of the last blank column right
            doShiftTiles(lastNonBlankCol, firstNonBlankCol, -1, setBoardData);
        } else {
            // Shift everything right of the first blank column left
            doShiftTiles(firstNonBlankCol, lastNonBlankCol, 1, setBoardData);
        }
    };

    // Shifts the tiles
    const doShiftTiles = (
        startCol: number,
        endCol: number,
        colInc: number,
        setBoardData: boolean
    ) => {
        console.log('Do shifting tiles', startCol, endCol, colInc);

        let shiftAmount = 0;

        for (
            let col = startCol;
            colInc > 0 ? col <= endCol : col >= endCol;
            col += colInc
        ) {
            const gridCell: GridCell = gridRef.current[0][col];

            // If the cell is blank increase the amount of cells to shift and continue to the next cell
            if (gridCell.isBlank) {
                shiftAmount++;
                continue;
            }

            // If the cell is not blank and shift amount if greater than 0 then we need to shift this whole column by the shift amount
            if (shiftAmount > 0) {
                shiftColumn(
                    col,
                    colInc > 0 ? -shiftAmount : shiftAmount,
                    setBoardData
                );
            }
        }
    };

    // Shifts all tiles in a column
    const shiftColumn = (
        col: number,
        shiftAmount: number,
        setBoardData: boolean
    ) => {
        for (
            let row = 0;
            row < activeLevelSaveDataRef.current.boardData.rows;
            row++
        ) {
            const gridCell: GridCell = gridRef.current[row][col];

            // If the cell is blank we reached the top and we can stop
            if (gridCell.isBlank) {
                return;
            }

            if (setBoardData) {
                // Update the board data in the LevelSaveData
                activeLevelSaveDataRef.current.boardData.board[row][
                    col + shiftAmount
                ] = activeLevelSaveDataRef.current.boardData.board[row][col];
                activeLevelSaveDataRef.current.boardData.board[row][col] = '\0';
            } else {
                const moveToCell: GridCell =
                    gridRef.current[row][col + shiftAmount];

                numTilesMovingRef.current++;

                moveTile(gridCell, moveToCell, () => {
                    numTilesMovingRef.current--;
                });
            }
        }
    };

    // Drops all tiles in the given column starting at the given row
    const dropTiles = (col: number, startRow: number, dropAmount: number) => {
        // console.log('Dropping tiles', col, startRow, dropAmount);

        for (
            let row = startRow;
            row < activeLevelSaveDataRef.current.boardData.rows;
            row++
        ) {
            const gridCell: GridCell = gridRef.current[row][col];

            if (gridCell.isBlank) {
                break;
            }

            // Update the board data in the LevelSaveData
            activeLevelSaveDataRef.current.boardData.board[row - dropAmount][
                col
            ] = activeLevelSaveDataRef.current.boardData.board[row][col];
            activeLevelSaveDataRef.current.boardData.board[row][col] = '\0';

            const dropToCell: GridCell = gridRef.current[row - dropAmount][col];

            numTilesMovingRef.current++;

            moveTile(gridCell, dropToCell, () => {
                numTilesMovingRef.current--;
            });
        }
    };

    // Moves the letter tile
    const moveTile = (
        fromCell: GridCell,
        toCell: GridCell,
        animFinished: () => void
    ): void => {
        // printCell('Moving tile from', fromCell);
        // printCell('to', toCell);

        // Copy the values to the new cell
        toCell.letterTile = fromCell.letterTile;
        toCell.letter = fromCell.letter;
        toCell.isBlank = false;

        // Animate the tile to its new placement cell
        toCell.letterTile.current.move(
            getCellPosition(toCell.row, toCell.col, gridRef.current.length),
            animFinished
        );

        // Set the "from cell" to blank
        setBlank(fromCell);
    };

    // Determine if a shift is needed by checking for non-blank and blank columns
    const isShiftNeeded = (): {
        isShiftNeeded: boolean;
        firstNonBlankCol: number;
        lastNonBlankCol: number;
    } => {
        let firstNonBlankCol = -1;
        let lastNonBlankCol = -1;
        let firstBlankCol = -1;

        for (
            let col = 0;
            col < activeLevelSaveDataRef.current.boardData.cols;
            col++
        ) {
            const gridCell: GridCell = gridRef.current[0][col];

            if (gridCell.isBlank) {
                if (firstNonBlankCol !== -1 && firstBlankCol === -1) {
                    firstBlankCol = col;
                }
            } else {
                if (firstNonBlankCol === -1) {
                    firstNonBlankCol = col;
                } else {
                    lastNonBlankCol = col;
                }
            }
        }

        return {
            isShiftNeeded:
                firstBlankCol !== -1 && firstBlankCol < lastNonBlankCol,
            firstNonBlankCol,
            lastNonBlankCol
        };
    };

    useEffect(() => {
        const container = rootContainerRef.current;
        if (!container) return;

        const resizeObserver = new ResizeObserver(entries => {
            if (!layoutSet) {
                for (const entry of entries) {
                    setContainerWidth(entry.contentRect.width);
                    setContainerHeight(entry.contentRect.height);
                }
                setLayoutSet(true);
            } else {
                resizeObserver.unobserve(container);
            }
        });

        resizeObserver.observe(container);

        return () => {
            resizeObserver.unobserve(container);
        };
    }, [layoutSet]);

    useEffect(() => {
        if (
            !activeLevelSaveDataRef.current.boardData?.board ||
            !containerHeight ||
            !containerWidth
        ) {
            return;
        }

        const newSize = computeTileSize(
            activeLevelSaveDataRef.current.boardData.cols,
            activeLevelSaveDataRef.current.boardData.rows,
            containerWidth,
            containerHeight
        );
        setTileSize(newSize);
    }, [containerWidth, containerHeight, activeLevelSaveDataRef.current]);

    const onGestureMove = (x: number, y: number) => {
        if (numTilesMovingRef.current > 0) return;
        updateSelected(x, y);
    };

    const onGestureEnd = () => {
        // printGridCellsWithCoordinates(gridRef.current, 'Current grid');

        if (numTilesMovingRef.current > 0) return;

        const isValidWord =
            levelData.isWordInLevel(selectedWordRef.current) &&
            !activeLevelSaveDataRef.current.foundLevelWords.has(
                selectedWordRef.current
            );

        console.log('Selected Word:', selectedWordRef.current);
        if (!selectedWordRef.current?.length) {
            emitter.emit('emptySpaceTap');
            // TODO: return from here?
        }

        if (isValidWord) {
            handleValidWordSelected(selectedWordRef.current);
        } else {
            handleInvalidWordSelected();
            animateUpdatedSelection(selectedGridCellsRef.current, []);
            clearSelection();
        }

        emitter.emit('wordSelectionChanged', { word: '' });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bind: any = useDrag(({ xy: [x, y], first, last }) => {
        if (!tilesContainerRef.current) return;

        if (first) clearSelectionIfNeeded();

        // Get container's bounding rect
        const containerRect = tilesContainerRef.current.getBoundingClientRect();

        // Calculate the coordinates relative to the container
        const relativeX = x - containerRect.left;
        const relativeY = y - containerRect.top;

        // Use these relative coordinates to calculate the tile index
        onGestureMove(relativeX, relativeY);

        if (last) onGestureEnd();
    });

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={tileSize ? { opacity: 1 } : {}}
            transition={{ duration: 0.5 }}
            className="flex h-full w-full flex-1 flex-col items-center justify-end"
            ref={rootContainerRef}
        >
            <div
                style={
                    tileSize
                        ? {
                              width:
                                  activeLevelSaveDataRef.current.boardData
                                      .cols * tileSize,
                              height:
                                  activeLevelSaveDataRef.current.boardData
                                      .rows * tileSize
                          }
                        : undefined
                }
            >
                <div
                    {...bind()}
                    className="relative h-full w-full touch-none"
                    ref={tilesContainerRef}
                >
                    {gridRef.current?.map((gridRow, rowIndex) =>
                        gridRow.map((gridCell, colIndex) => {
                            if (gridCell.isBlank) return null;

                            return (
                                <GridLetterTile
                                    key={`${rowIndex}-${colIndex}`}
                                    ref={
                                        gridRef.current[rowIndex][colIndex]
                                            .letterTile
                                    }
                                    letter={gridCell.letter}
                                    size={tileSize}
                                    selectedBackgroundColor={selectedTileColor}
                                    positionStyle={{
                                        position: 'absolute',
                                        ...getCellPosition(
                                            rowIndex,
                                            colIndex,
                                            gridRef.current.length
                                        )
                                    }}
                                />
                            );
                        })
                    )}
                    {showCursor && (
                        <animated.div
                            ref={cursorRef} // Attach the ref from useCursorAnimation
                            style={style} // Apply the animated styles
                            className="absolute left-0 top-0 h-16 w-16"
                        >
                            <img
                                src={cursorIcon}
                                alt="Cursor"
                                className="h-full w-full object-contain"
                            />
                        </animated.div>
                    )}
                </div>
            </div>
        </motion.div>
    );
};

const areEqual = (prevProps: GridHandlerProps, nextProps: GridHandlerProps) => {
    return (
        prevProps.selectedTileColor === nextProps.selectedTileColor &&
        prevProps.levelData === nextProps.levelData &&
        prevProps.levelSaveData === nextProps.levelSaveData &&
        prevProps.puzzleType === nextProps.puzzleType
    );
};

export default memo(GridHandler, areEqual);
