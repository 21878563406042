import { type FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { COLORS } from '@/utils/colors';

interface ProgressBarProps {
    progressBarValue: number;
    initialValue?: number;
    transitionDuration?: number;
    height?: string;
    bgColor?: string;
    borderColor?: string;
    unfilledColor?: string;
}

const ProgressBar: FC<ProgressBarProps> = ({
    progressBarValue,
    initialValue = 0, // Added initialValue prop with default value of 0
    transitionDuration,
    height,
    bgColor = COLORS.orange,
    borderColor,
    unfilledColor
}) => {
    const [initialWidth, setInitialWidth] = useState<string>(
        `${initialValue * 100}%`
    );

    useEffect(() => {
        // This effect will only update initialWidth once, on component mount
        setInitialWidth(`${initialValue * 100}%`);
    }, [initialValue]);

    return (
        <div
            className={classNames(
                'w-full overflow-hidden rounded-full border',
                height ? height : 'h-2',
                unfilledColor ? unfilledColor : 'bg-white/50',
                borderColor ? borderColor : 'border-white200'
            )}
        >
            <motion.div
                className="h-full rounded-full"
                style={{
                    width: initialWidth,
                    backgroundColor: bgColor
                }}
                initial={false} // Prevents animation on initial render
                animate={{ width: `${progressBarValue * 100}%` }}
                transition={{ duration: transitionDuration ?? 0.5 }}
            />
        </div>
    );
};

export default ProgressBar;
