import { FC, useState } from 'react';
import { useReward } from 'react-rewards';
import { toast } from 'react-toastify';
import { initInvoice } from '@telegram-apps/sdk';

import { generateInvoiceApi } from '@/api/client';
import { SoundName } from '@/audio/AudioManager';
import TextButton from '@/components/buttons/TextButton';
import Modal from '@/components/Modal';
import { useGameProgress, useLabels, useSettings } from '@/context';
import emitter from '@/events/emitter';
import { commonStyles } from '@/styles/commonStyles';

import coinIcon from '../../../assets/images/icons/coin.png';

interface ShopProps {
    isVisible: boolean;
    onClose: () => void;
}

interface ShopItemProps {
    coins: number;
    stars: string;
    code: string;
    reward: () => void;
}

const ShopItem: FC<ShopItemProps> = ({ coins, stars, code, reward }) => {
    const { addCoins } = useGameProgress();
    const [isLoading, setIsLoading] = useState(false);

    const handlePurchase = async () => {
        try {
            setIsLoading(true);

            const invoice = initInvoice();

            const invoiceLink = await generateInvoiceApi(code);
            console.log('Invoice link:', invoiceLink);
            const status = await invoice.open(invoiceLink, 'url');
            if (status === 'paid') {
                console.log('Payment successful!');
                emitter.emit('playSound', { sound: SoundName.Coin });
                reward();
                await addCoins(coins);
            } else if (status === 'failed') {
                console.log('Payment failed:', status);
                toast.error('Payment failed');
            }
        } catch (error) {
            console.error('Processing invoice error:', error);
            toast.error('Processing invoice error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-between rounded-2xl bg-[#E1A814]/10 px-4 py-3">
            <div className="flex items-center">
                <img src={coinIcon} alt="LingoCoins" className="mr-2 h-8 w-8" />
                <span className="text-xl font-extrabold text-[#FFC328]">
                    +{coins}
                </span>
            </div>
            <TextButton
                className="min-w-24"
                bgColor="bg-[#E1A814]"
                textStyle="text-white200"
                text={isLoading ? 'Loading...' : stars}
                onClick={handlePurchase}
            />
        </div>
    );
};

const Shop: FC<ShopProps> = ({ isVisible, onClose }) => {
    const { getLabel } = useLabels();
    const { gameConfig } = useSettings();

    const { reward } = useReward('rewardId', 'confetti', {
        angle: 270,
        lifetime: 200,
        elementSize: 6,
        elementCount: 170
    });

    const shopItems = gameConfig?.shopItems || [];

    return (
        <>
            <div
                className="absolute -top-2/3 right-1/2 z-20"
                id="rewardId"
            ></div>

            <Modal isVisible={isVisible} onClose={onClose}>
                <div className={commonStyles.modalPanel}>
                    <h1>{getLabel('shop')}</h1>
                    <p className="mb-8 text-lg text-white">
                        {getLabel('lingocoins')}
                    </p>

                    <div className="flex w-full flex-col gap-4">
                        {shopItems.map(item => (
                            <ShopItem
                                key={item.code}
                                coins={item.coins}
                                stars={`${item.priceInStars.amount} ⭐`}
                                code={item.code}
                                reward={reward}
                            />
                        ))}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Shop;
