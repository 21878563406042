export class Random {
    // Returns a random integer between 0 (inclusive) and the specified value (exclusive)
    next(maxValue: number): number {
        return Math.floor(Math.random() * maxValue);
    }

    // Returns a random integer between the specified minimum value (inclusive) and maximum value (exclusive)
    nextRange(minValue: number, maxValue: number): number {
        return Math.floor(Math.random() * (maxValue - minValue)) + minValue;
    }

    // Returns a random floating-point number between 0 (inclusive) and 1 (exclusive)
    nextDouble(): number {
        return Math.random();
    }
}
