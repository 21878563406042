import { type FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import emitter, {
    AudioHintPlayWordPayload,
    ValidWordSelectedPayload
} from '@/events/emitter';
import { WordInfoLocalized } from '@/types/category';
import { isPunctuation, removeArticle } from '@/utils/language';

import {
    BACKGROUND_COLOR,
    BACKGROUND_COLOR_FOUND,
    questionStyles,
    TEXT_COLOR,
    TEXT_COLOR_FOUND
} from './questionStyles';

interface QuestionWithoutTranslationProps {
    word: WordInfoLocalized;
    studyLanguage: string;
    onQuestionPress: () => void;
}

const QuestionWithoutTranslation: FC<QuestionWithoutTranslationProps> = ({
    word,
    studyLanguage,
    onQuestionPress
}) => {
    const [found, setFound] = useState(false);
    const [initialDimensions, setInitialDimensions] = useState<{
        width: number | null;
    }>({ width: null });

    const initialSet = useRef(false);
    const questionRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (questionRef.current && !initialSet.current) {
            const rect = questionRef.current.getBoundingClientRect();
            setInitialDimensions({ width: rect.width });
            initialSet.current = true;
        }
    }, [questionRef]);

    useEffect(() => {
        const handleValidWord = (payload: ValidWordSelectedPayload) => {
            if (payload.wordId !== word.id) return;

            emitter.emit('playWordAudio', { audioUrl: word.audioUrl });
            setFound(true);

            emitter.off('validWordSelected', handleValidWord);
            emitter.emit('correctAnswerGiven', { word });
        };

        const handleAudioHintPlayWord = (payload: AudioHintPlayWordPayload) => {
            if (payload.wordId !== word.id) return;

            emitter.emit('playWordAudio', { audioUrl: word.audioUrl });
        };

        emitter.on('validWordSelected', handleValidWord);
        emitter.on('audioHintPlayWord', handleAudioHintPlayWord);

        return () => {
            emitter.off('validWordSelected', handleValidWord);
            emitter.off('audioHintPlayWord', handleAudioHintPlayWord);
        };
    }, [word.id]);

    const getHiddenTranslation = () => {
        const wordWithoutArticle = removeArticle(word.word, studyLanguage);
        const startsAt = word.word.indexOf(wordWithoutArticle);
        const endsAt = startsAt + wordWithoutArticle.length;

        const hiddenTranslation = word.word.split('').map((char, index) => {
            if (
                index >= startsAt &&
                index < endsAt &&
                !isPunctuation(char) &&
                char !== ' '
            ) {
                return (
                    <div
                        key={index}
                        className="mx-[1px] h-3.5 w-3.5 rounded bg-white bg-opacity-50"
                    >
                        {found && (
                            <span className="font-mono font-bold">{char}</span>
                        )}
                    </div>
                );
            }

            // Handle empty spaces
            if (char === ' ') {
                return (
                    <span key={index} className="w-2">
                        {' '}
                    </span>
                );
            }

            // Render punctuation or other non-hidden characters
            return (
                <span key={index} className="font-mono font-bold text-white200">
                    {char}
                </span>
            );
        });

        return (
            <div className="my-1 flex items-center">{hiddenTranslation}</div>
        );
    };

    return (
        <motion.button
            ref={questionRef}
            className={classNames(
                questionStyles.container,
                'relative mx-3 my-1 flex flex-col items-center',
                { [BACKGROUND_COLOR]: !found, [BACKGROUND_COLOR_FOUND]: found }
            )}
            onClick={onQuestionPress}
            animate={{
                backgroundColor: found
                    ? BACKGROUND_COLOR_FOUND
                    : BACKGROUND_COLOR
            }}
            transition={{ duration: 0.5 }}
            style={{
                width: initialDimensions.width || 'auto'
            }}
        >
            <motion.div
                className="text-center font-bold"
                animate={{ color: found ? TEXT_COLOR_FOUND : TEXT_COLOR }}
                transition={{ duration: 0.5 }}
            >
                {word.translation}
            </motion.div>
            <div className="min-h-6">
                {found ? (
                    <motion.span
                        className="font-roboto text-base font-bold leading-none tracking-wider text-gray-200"
                        style={{
                            color: word.categoryColor
                        }}
                    >
                        {word.word}
                    </motion.span>
                ) : (
                    getHiddenTranslation()
                )}
            </div>
        </motion.button>
    );
};

export default QuestionWithoutTranslation;
