import {
    createContext,
    type FC,
    ReactElement,
    ReactNode,
    useContext,
    useState
} from 'react';
import ReactGA from 'react-ga4';

export type ScreenName =
    | 'Splash'
    | 'ChooseUILanguage'
    | 'ChooseStudyLanguage'
    | 'ChooseLanguageLevel'
    | 'ContentLoading'
    | 'Hello'
    | 'ChooseCategory'
    | 'ChooseAnotherCategory'
    | 'WordsIntro'
    | 'TimeToPlay'
    | 'Puzzle'
    | 'OpenLandscape'
    | 'Results'
    | 'ReviewPuzzle'
    | 'ReviewResults';

export type ProfileScreenName =
    | 'Account'
    | 'Landscapes'
    | 'LandscapeInfo'
    | 'Categories'
    | 'CategoryInfo'
    | 'LanguageSettings'
    | 'ChangeUILanguage'
    | 'AddStudyLanguage'
    | 'ChangeStudyLanguage'
    | 'ChooseNewLanguageLevel'
    | 'WordsLearned';

interface NavigationContextType {
    navigate: (screen: ScreenName) => void;
    navigateToProfile: (profileScreen: ProfileScreenName) => void;
    closeProfile: () => void;
    currentScreen: ScreenName;
    currentProfileScreen: ProfileScreenName | null;
}

interface NavigationProviderProps {
    children: ReactNode;
}

const NavigationContext = createContext<NavigationContextType | undefined>(
    undefined
);

export const NavigationProvider: FC<NavigationProviderProps> = ({
    children
}): ReactElement => {
    const [currentScreen, setCurrentScreen] = useState<ScreenName>('Splash');
    const [currentProfileScreen, setCurrentProfileScreen] =
        useState<ProfileScreenName | null>(null);

    const navigate = (screen: ScreenName) => {
        ReactGA.send({ hitType: 'pageview', page: screen });
        if (currentProfileScreen) {
            closeProfile();
        }
        setCurrentScreen(screen);
    };

    const navigateToProfile = (profileScreen: ProfileScreenName) => {
        ReactGA.send({ hitType: 'pageview', page: screen });
        setCurrentProfileScreen(profileScreen);
    };

    const closeProfile = () => {
        setCurrentProfileScreen(null);
    };

    return (
        <NavigationContext.Provider
            value={{
                navigate,
                navigateToProfile,
                closeProfile,
                currentScreen,
                currentProfileScreen
            }}
        >
            {children}
        </NavigationContext.Provider>
    );
};

export const useNavigation = (): NavigationContextType => {
    const context = useContext(NavigationContext);
    if (!context) {
        throw new Error(
            'useNavigation must be used within a NavigationProvider'
        );
    }
    return context;
};
