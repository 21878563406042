import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

import { useVibration } from '@/hooks/useVibration';
import { COLORS } from '@/utils/colors';
import { toUpperCaseLetter } from '@/utils/language';

type GridLetterTileProps = {
    letter: string;
    size: number;
    positionStyle?: React.CSSProperties;
    selectedBackgroundColor: string;
};

export interface GridLetterTileHandle {
    shake: () => void;
    remove: (animFinished?: () => void) => void;
    select: () => void;
    deselect: () => void;
    showHint: () => void;
    hideHint: () => void;
    move: (
        { left, top }: { left: number; top: number },
        animFinished: () => void
    ) => void;
    pulse: () => void;
    measure: (callback: (rect: DOMRect) => void) => void;
}

const GridLetterTile = forwardRef<GridLetterTileHandle, GridLetterTileProps>(
    ({ letter, size, positionStyle, selectedBackgroundColor }, ref) => {
        const internalRef = useRef<HTMLDivElement>(null);
        const [isHintDisplayed, setIsHintDisplayed] = useState(false);

        const margin = size * 0.04;
        const contentSize = size - 2 * margin;
        const fontSize = contentSize * 0.6;
        const borderRadius = contentSize * 0.15;

        const controls = useAnimation();
        const [letterColor, setLetterColor] = useState(COLORS.grey500);
        const [backgroundColor, setBackgroundColor] = useState(COLORS.white500);

        const { vibrateSelectionChanged } = useVibration();

        useImperativeHandle(ref, () => ({
            shake() {
                controls.start({
                    x: [0, -2.5, 2.5, -2.5, 2.5, 0],
                    transition: { duration: 0.28, ease: 'easeInOut' }
                });
            },
            remove(animFinished) {
                controls
                    .start({
                        opacity: 0,
                        transition: { duration: 0.25 }
                    })
                    .then(() => {
                        if (animFinished) animFinished();
                    });
            },
            select() {
                // Uncomment if you want to see the relative position of the tile
                // if (internalRef.current) {
                //     const offsetLeft = internalRef.current.offsetLeft;
                //     const offsetTop = internalRef.current.offsetTop;

                //     console.log(
                //         `Relative position of tile: (${Math.round(offsetLeft)}, ${Math.round(offsetTop)}) relative to the nearest positioned ancestor`
                //     );
                // }

                vibrateSelectionChanged();
                setBackgroundColor(selectedBackgroundColor);
                setLetterColor(COLORS.white500);
                controls.start({
                    scale: [1, 1.1, 1],
                    transition: { duration: 0.2 }
                });
            },
            deselect() {
                setBackgroundColor(COLORS.white500);
                setLetterColor(COLORS.grey500);
                controls.start({ scale: 1 });
                if (isHintDisplayed) {
                    // showHint();
                }
            },
            showHint() {
                setIsHintDisplayed(true);
                setBackgroundColor(COLORS.orange);
                setLetterColor(COLORS.white500);
                controls.start({
                    scale: [1, 1.1, 1],
                    transition: { duration: 0.2 }
                });
            },
            hideHint() {
                setIsHintDisplayed(false);
                setBackgroundColor(COLORS.white500);
                setLetterColor(COLORS.grey500);
            },
            move({ left, top }, animFinished) {
                controls
                    .start({
                        left,
                        top,
                        transition: { duration: 0.25 }
                    })
                    .then(() => {
                        if (animFinished) animFinished();
                    });
            },
            pulse() {
                controls.start({
                    scale: [1, 1.04, 0.96],
                    transition: {
                        duration: 2,
                        repeat: Infinity,
                        repeatType: 'reverse'
                    }
                });
            },
            measure(callback) {
                if (internalRef.current) {
                    const rect = internalRef.current.getBoundingClientRect();
                    callback(rect);
                }
            }
        }));

        return (
            <motion.div
                ref={internalRef}
                style={{
                    width: contentSize,
                    height: contentSize,
                    margin,
                    borderRadius,
                    backgroundColor,
                    ...positionStyle
                }}
                animate={controls}
                className="flex items-center justify-center"
            >
                <motion.span
                    className="select-none font-extraBold"
                    style={{
                        color: letterColor,
                        fontSize
                    }}
                >
                    {toUpperCaseLetter(letter)}
                </motion.span>
            </motion.div>
        );
    }
);

GridLetterTile.displayName = 'GridLetterTile';

export default GridLetterTile;
