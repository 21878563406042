import { http, HttpResponse } from 'msw';

import { CategoryProgressItem, LandscapeProgressItem } from '@/types/progress';
import { LanguageSettings, UserSettings } from '@/types/settings';
import { API_URL } from '@/utils/constants';

import {
    addUser,
    checkIfUserExists,
    loadLandscapesProgress,
    loadProgress,
    loadSettings,
    loadWordsProgress,
    saveCoins,
    saveGameLevel,
    saveLandscapeId,
    saveLandscapeProgress,
    saveLandscapesProgress,
    saveLanguageLevel,
    saveUserSettings,
    saveWordsProgress,
    saveXp
} from './mockDb';
import {
    GetGameConfigResponse,
    GetLandscapesProgressResponse,
    GetProgressResponse,
    GetSettingsResponse,
    GetUserResponse,
    GetWordsProgressResponse,
    PostCoinsResponse,
    PostGameLevelResponse,
    PostLandscapeProgressResponse,
    PostLandscapesProgressResponse,
    PostLanguageSettingsResponse,
    PostSelectedLandscapeIdResponse,
    PostWordsProgressResponse,
    PostXpResponse,
    PutSettingsResponse
} from './types';

const apiUrl = path => {
    return API_URL + path;
};

type GetUserParams = null;
type GetUserBody = null;

const getUser = http.get<GetUserParams, GetUserBody, GetUserResponse>(
    apiUrl('/user'),
    ({ request }) => {
        const token = request.headers.get('Authorization');
        console.log('token:', token.substring(0, 25), '...');

        if (!token || !checkIfUserExists(token)) {
            return HttpResponse.json(undefined, { status: 204 });
        }

        const userSettings = loadSettings();
        return HttpResponse.json(userSettings);
    }
);

type PostUserParams = null;
type PostUserBody = null;

const postUser = http.post<PostUserParams, PostUserBody, GetUserResponse>(
    apiUrl('/user'),
    ({ request }) => {
        const token = request.headers.get('Authorization');

        if (token) {
            addUser(token);
        }

        const userSettings = loadSettings();
        return HttpResponse.json(userSettings);
    }
);

const PRICE_PER_WORD_IN_CATEGORY = 50;
const XP_PER_COIN_REWARD = 3;
const XP_PER_COIN_REWARD_FOR_REVIEW_WITHOUT_SPACED_REP = 1;
const AUDIO_HINT_PRICE = 25;
const MAGIC_WAND_HINT_PRICE = 50;

type GetConfigParams = null;
type GetConfigBody = null;

const getGameConfig = http.get<
    GetConfigParams,
    GetConfigBody,
    GetGameConfigResponse
>(apiUrl('/game-config'), () => {
    return HttpResponse.json({
        pricePerWordInCategory: PRICE_PER_WORD_IN_CATEGORY,
        audioHintPrice: AUDIO_HINT_PRICE,
        magicWandHintPrice: MAGIC_WAND_HINT_PRICE,
        xpPerCoinReward: XP_PER_COIN_REWARD,
        xpPerCoinRewardForReviewWithoutSpacedRep:
            XP_PER_COIN_REWARD_FOR_REVIEW_WITHOUT_SPACED_REP,
        shopItems: [
            {
                code: '100-lingocoins',
                coins: 100,
                priceInStars: { amount: 1, label: '100 LingoCoins' }
            },
            {
                code: '200-lingocoins',
                coins: 200,
                priceInStars: { amount: 2, label: '200 LingoCoins' }
            },
            {
                code: '300-lingocoins',
                coins: 300,
                priceInStars: { amount: 3, label: '300 LingoCoins' }
            }
        ]
    });
});

type GetSettingsParams = null;
type GetSettingsBody = null;

const getSettings = http.get<
    GetSettingsParams,
    GetSettingsBody,
    GetSettingsResponse
>(apiUrl('/user/settings'), () => {
    const userSettings = loadSettings();
    return HttpResponse.json(userSettings);
});

type PutSettingsParams = null;
type PutSettingsBody = Partial<UserSettings>;

const putSettings = http.put<
    PutSettingsParams,
    PutSettingsBody,
    PutSettingsResponse
>(apiUrl('/user/settings'), async ({ request }) => {
    const updatedSettings = await request.json();

    const currentSettings = loadSettings();
    const newSettings = { ...currentSettings, ...updatedSettings };
    saveUserSettings(newSettings);

    return HttpResponse.json(newSettings);
});

type PostLanguageSettingsParams = {
    lang: string;
};
type PostLanguageSettingsBody = LanguageSettings;

const postLanguageSettings = http.post<
    PostLanguageSettingsParams,
    PostLanguageSettingsBody,
    PostLanguageSettingsResponse
>(apiUrl('/user/:lang/settings'), async ({ params, request }) => {
    const { lang } = params;
    const { languageLevel } = await request.json();
    saveLanguageLevel(lang, languageLevel);

    const updatedSettings = loadSettings();
    return HttpResponse.json(updatedSettings);
});

type GetProgressParams = {
    lang: string;
};
type GetProgressBody = null;

const getProgress = http.get<
    GetProgressParams,
    GetProgressBody,
    GetProgressResponse
>(apiUrl('/user/:lang/progress'), ({ params }) => {
    const { lang } = params;

    const progress = loadProgress(lang);
    return HttpResponse.json(progress);
});

type GetWordsProgressParams = {
    lang: string;
};
type GetWordsProgressBody = null;

const getWordsProgress = http.get<
    GetWordsProgressParams,
    GetWordsProgressBody,
    GetWordsProgressResponse
>(apiUrl('/user/:lang/progress/words'), ({ params }) => {
    const { lang } = params;
    const categoryProgressItems = loadWordsProgress(lang);

    return HttpResponse.json({
        categoryProgressItems
    });
});

type PostWordsProgressParams = {
    lang: string;
};
type PostWordsProgressBody = {
    categoryProgressItems: CategoryProgressItem[];
};

const postWordsProgress = http.post<
    PostWordsProgressParams,
    PostWordsProgressBody,
    PostWordsProgressResponse
>(apiUrl('/user/:lang/progress/words'), async ({ params, request }) => {
    const { lang } = params;
    const { categoryProgressItems } = await request.json();

    saveWordsProgress(lang, categoryProgressItems);

    return HttpResponse.json({
        categoryProgressItems
    });
});

type GetLandscapesProgressParams = {
    lang: string;
};
type GetLandscapesProgressBody = null;

const getLandscapesProgress = http.get<
    GetLandscapesProgressParams,
    GetLandscapesProgressBody,
    GetLandscapesProgressResponse
>(apiUrl('/user/:lang/progress/landscapes'), ({ params }) => {
    const { lang } = params;
    const landscapes = loadLandscapesProgress(lang);
    return HttpResponse.json({ landscapes });
});

type PostLandscapesProgressParams = {
    lang: string;
};
type PostLandscapesProgressBody = {
    landscapeProgressItems: LandscapeProgressItem[];
};

const postLandscapesProgress = http.post<
    PostLandscapesProgressParams,
    PostLandscapesProgressBody,
    PostLandscapesProgressResponse
>(apiUrl('/user/:lang/progress/landscapes'), async ({ params, request }) => {
    const { lang } = params;
    const { landscapeProgressItems } = await request.json();
    saveLandscapesProgress(lang, landscapeProgressItems);
    return HttpResponse.json({ landscapeProgressItems });
});

type PostGameLevelParams = {
    lang: string;
};
type PostGameLevelBody = {
    level: number;
};

const postGameLevel = http.post<
    PostGameLevelParams,
    PostGameLevelBody,
    PostGameLevelResponse
>(apiUrl('/user/:lang/progress/game-level'), async ({ params, request }) => {
    const { lang } = params;
    const { level } = await request.json();
    saveGameLevel(lang, level);
    return HttpResponse.json({ level });
});

type PostCoinsParams = null;
type PostCoinsBody = {
    coins: number;
};

const postCoins = http.post<PostCoinsParams, PostCoinsBody, PostCoinsResponse>(
    apiUrl('/user/progress/coins'),
    async ({ request }) => {
        const { coins } = await request.json();
        saveCoins(coins);
        return HttpResponse.json({ coins });
    }
);

type PostXpParams = {
    lang: string;
};
type PostXpBody = {
    xp: number;
};

const postXp = http.post<PostXpParams, PostXpBody, PostXpResponse>(
    apiUrl('/user/:lang/progress/xp'),
    async ({ params, request }) => {
        const { lang } = params;
        const { xp } = await request.json();

        saveXp(lang, xp);
        return HttpResponse.json({ xp });
    }
);

type PostSelectedLandscapeIdParams = {
    lang: string;
};
type PostSelectedLandscapeIdBody = {
    landscapeId: string;
};

const postSelectedLandscapeId = http.post<
    PostSelectedLandscapeIdParams,
    PostSelectedLandscapeIdBody,
    PostSelectedLandscapeIdResponse
>(
    apiUrl('/user/:lang/progress/selected-landscape-id'),
    async ({ params, request }) => {
        const { lang } = params;
        const { landscapeId } = await request.json();
        saveLandscapeId(lang, landscapeId);
        return HttpResponse.json({ landscapeId });
    }
);

type PostLandscapeProgressParams = {
    lang: string;
};
type PostLandscapeProgressBody = {
    landscapeProgress: number;
};

const postLandscapeProgress = http.post<
    PostLandscapeProgressParams,
    PostLandscapeProgressBody,
    PostLandscapeProgressResponse
>(
    apiUrl('/user/:lang/progress/landscape-progress'),
    async ({ params, request }) => {
        const { lang } = params;
        const { landscapeProgress } = await request.json();
        saveLandscapeProgress(lang, landscapeProgress);
        return HttpResponse.json({ landscapeProgress });
    }
);

export const handlers = [
    getLandscapesProgress,
    getProgress,
    getGameConfig,
    getSettings,
    getUser,
    getWordsProgress,
    postCoins,
    postXp,
    postGameLevel,
    postSelectedLandscapeId,
    postLandscapeProgress,
    postLandscapesProgress,
    postLanguageSettings,
    postUser,
    postWordsProgress,
    putSettings
];
