import { type FC } from 'react';

interface Props {
    width: number;
    height: number;
    color: string;
    opacity?: string;
}

const VolumeIcon: FC<Props> = ({
    width,
    height,
    color,
    opacity = '100%'
}: Props) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 68 71"
            fill={color}
            opacity={opacity}
        >
            <path d="M30.6541 0.679688C29.4381 0.679688 28.3053 1.29724 27.6465 2.31932L18.9544 15.8039C18.0338 17.232 16.4509 18.0949 14.7518 18.0949H5.72266C2.96123 18.0949 0.722656 20.3335 0.722656 23.0949V47.9253C0.722656 50.6867 2.96123 52.9253 5.72266 52.9253H14.7518C16.4509 52.9253 18.0338 53.7882 18.9544 55.2164L27.6465 68.7009C28.3053 69.723 29.4381 70.3405 30.6541 70.3405C32.6304 70.3405 34.2325 68.7384 34.2325 66.7622V4.25805C34.2325 2.28178 32.6304 0.679688 30.6541 0.679688ZM46.944 9.74447C44.5739 9.33936 42.6099 11.3366 42.6099 13.7411V13.8624C42.6099 16.2 44.5363 18.0295 46.7987 18.6174C54.0033 20.533 59.3649 27.325 59.3649 35.5101C59.3649 43.6953 54.0871 50.4872 46.7987 52.4029C44.5363 52.9907 42.6099 54.8202 42.6099 57.1578V57.2791C42.6099 59.6837 44.5705 61.6291 46.9354 61.1946C47.4647 61.0973 47.9948 60.9885 48.5306 60.8732C48.6044 60.8573 48.6798 60.8492 48.7553 60.8492C48.8468 60.8492 48.9381 60.8375 49.0264 60.8136C59.8486 57.8917 67.7423 47.6509 67.7423 35.5101C67.7423 23.3195 59.7837 13.0445 48.893 10.171C48.267 10.0012 47.6145 9.85906 46.944 9.74447ZM44.7043 27.0637C43.5733 26.7698 42.6099 27.7499 42.6099 28.9185V42.1003C42.6099 43.2697 43.5833 44.2894 44.7043 43.9565C48.3066 42.9986 50.9874 39.5156 50.9874 35.5101C50.9874 31.5046 48.3904 28.0216 44.7043 27.0637Z" />
        </svg>
    );
};

export default VolumeIcon;
