import { type FC } from 'react';
import classNames from 'classnames';

import BackIcon from '@/components/icons/BackIcon';
import CloseIcon from '@/components/icons/CloseIcon';
import { useVibration } from '@/hooks/useVibration';

interface HeaderWithCloseAndBackButtonProps {
    backTitle?: string;
    onBack?: () => void;
    onClose: () => void;
}

const HeaderWithCloseAndBackButton: FC<HeaderWithCloseAndBackButtonProps> = ({
    backTitle,
    onBack,
    onClose
}) => {
    const { vibrateLight } = useVibration();

    const onBackPress = () => {
        if (onBack) {
            vibrateLight();
            onBack();
        }
    };

    const handleClose = () => {
        vibrateLight();
        onClose();
    };

    return (
        <div>
            <div
                className={classNames('flex items-center pb-5', {
                    'justify-between': onBack,
                    'justify-end': !onBack
                })}
            >
                {onBack && (
                    <button
                        className="flex items-center gap-3"
                        onClick={onBackPress}
                    >
                        <BackIcon width={15} height={15} color="white" />
                        <span className="pt-0.5 text-xl font-bold text-white">
                            {backTitle}
                        </span>
                    </button>
                )}
                <button onClick={handleClose} aria-label="Close">
                    <CloseIcon width={16} height={16} color="white" />
                </button>
            </div>
            <div
                className="border-b"
                style={{
                    borderBottomColor: 'rgba(255, 255, 255, 0.3)',
                    borderBottomWidth: '1.5px',
                    marginBottom: '12px'
                }}
            />
        </div>
    );
};

export default HeaderWithCloseAndBackButton;
