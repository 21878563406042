import { type FC } from 'react';

import Modal from '@/components/Modal';
import { commonStyles } from '@/styles/commonStyles';

interface ModalInfoProps {
    title?: string;
    description: string;
    isVisible: boolean;
    onClose: () => void;
}

const ModalInfo: FC<ModalInfoProps> = ({
    title,
    description,
    onClose,
    isVisible
}) => {
    return (
        <Modal isVisible={isVisible} onClose={onClose}>
            {title && <h2>{title}</h2>}
            <p className={commonStyles.fontRegular}>{description}</p>
        </Modal>
    );
};

export default ModalInfo;
