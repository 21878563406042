import { type FC, useEffect, useState } from 'react';

import emitter from '@/events/emitter';
import { BoardData } from '@/puzzle/types/BoardData';

import QuestionsPanel from './questionsPanels/QuestionsPanel';
import PuzzleBoard from './PuzzleBoard';
import { PuzzleData, PuzzleType } from './types';

type GameStepProps = {
    puzzleData: PuzzleData;
    studyLanguage: string;
    onAllWordsFound: () => void;
    previousBoardData?: BoardData;
    onBoardDataReady?: (boardData: BoardData) => void;
    showCursor: boolean;
};

const GameStep: FC<GameStepProps> = ({
    puzzleData,
    studyLanguage,
    onAllWordsFound,
    previousBoardData,
    onBoardDataReady,
    showCursor
}) => {
    const [wordsFound, setWordsFound] = useState(0);
    const [forceWordsFound, setForceWordsFound] = useState(false);

    useEffect(() => {
        const correctAnswerGiven = () => {
            setWordsFound(prev => prev + 1);
        };

        const forceGameStepComplete = () => {
            setForceWordsFound(true);
        };

        emitter.on('correctAnswerGiven', correctAnswerGiven);
        emitter.on('forceGameStepComplete', forceGameStepComplete);

        return () => {
            emitter.off('correctAnswerGiven', correctAnswerGiven);
            emitter.off('forceGameStepComplete', forceGameStepComplete);
        };
    }, []);

    useEffect(() => {
        if (wordsFound === puzzleData.wordsToPlay.length || forceWordsFound) {
            setTimeout(
                () => {
                    onAllWordsFound();
                },
                puzzleData.puzzleType === PuzzleType.ChooseTranslation ? 0 : 750
            );
        }
    }, [wordsFound, forceWordsFound, puzzleData.puzzleType, onAllWordsFound]);

    return (
        <>
            <QuestionsPanel
                puzzleData={puzzleData}
                studyLanguage={studyLanguage}
                wordsFound={wordsFound}
            />
            <PuzzleBoard
                puzzleData={puzzleData}
                studyLanguage={studyLanguage}
                previousBoardData={previousBoardData}
                onBoardDataReady={onBoardDataReady}
                showCursor={showCursor}
            />
        </>
    );
};

export default GameStep;
