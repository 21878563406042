import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { CountUp } from 'use-count-up';

import { SoundName } from '@/audio/AudioManager';
import { Panel, ProgressBar, TextButton } from '@/components';
import { useNewWordsGameSession, useSettings } from '@/context';
import { useGameProgress } from '@/context/GameProgressContext';
import { useLabels } from '@/context/LabelsContext';
import { useNavigation } from '@/context/NavigationContext';
import emitter from '@/events/emitter';
import { resultScreenStyles } from '@/styles/resultScreenStyles';
import { COLORS } from '@/utils/colors';

import coinIcon from '../../../assets/images/icons/coin.png';
import landscapeIcon from '../../../assets/images/icons/landscape.png';
import xpIcon from '../../../assets/images/icons/xp.png';

const PROGRESS_BAR_MAX_VALUE = 10;

const ResultsScreen: React.FC = () => {
    const { getLabel } = useLabels();
    const { navigate } = useNavigation();
    const { settings, gameConfig } = useSettings();
    const {
        gameProgress,
        addCoins,
        addXp,
        getWordsLearnedCount,
        isLockedLandscapeAvailable
    } = useGameProgress();
    const { gameSession } = useNewWordsGameSession();

    const [coins, setCoins] = useState(0);
    const [xp, setXp] = useState(0);
    const [animate, setAnimate] = useState(false);

    const wordsLearnedBefore =
        getWordsLearnedCount() - gameSession.learnedWords.length;
    const [wordsLearnedCounter, setWordsLearnedCounter] =
        useState(wordsLearnedBefore);
    const [landscapeProgress, setLandscapeProgress] = useState(
        Math.max(0, gameProgress.landscapeProgress - 1)
    );

    useEffect(() => {
        const timeoutCoins = setTimeout(() => {
            emitter.emit('playSound', { sound: SoundName.Coin });
            const earnedCoins = gameSession.earnedCoins;
            setCoins(earnedCoins);
            setXp(earnedCoins * gameConfig.xpPerCoinReward);
            setAnimate(true);
        }, 300);

        const timeoutLandscapeProgress = setTimeout(() => {
            setLandscapeProgress(gameProgress.landscapeProgress);
        }, 1500);

        const timeoutLearnedWords = setTimeout(() => {
            setWordsLearnedCounter(getWordsLearnedCount());
        }, 1500);

        return () => {
            clearTimeout(timeoutCoins);
            clearTimeout(timeoutLandscapeProgress);
            clearTimeout(timeoutLearnedWords);
        };
    }, []);

    useEffect(() => {
        if (animate) {
            // Reset animation after coins and XP animations
            const resetAnimation = setTimeout(() => {
                setAnimate(false);
            }, 600);

            return () => clearTimeout(resetAnimation);
        }
    }, [animate]);

    const handleButtonPress = (route: 'ChooseCategory' | 'OpenLandscape') => {
        const earnedCoins = gameSession.earnedCoins;
        addCoins(earnedCoins);
        addXp(settings.studyLanguage, earnedCoins * gameConfig.xpPerCoinReward);
        navigate(route);
    };

    const onContinuePress = () => {
        handleButtonPress('ChooseCategory');
    };

    const onUnlockPress = () => {
        handleButtonPress('OpenLandscape');
    };

    const isUnlockAvailable =
        isLockedLandscapeAvailable() &&
        (gameProgress.landscapeProgress >= PROGRESS_BAR_MAX_VALUE ||
            gameProgress.gameLevel < 3);
    const isShowLandscapeProgress =
        isLockedLandscapeAvailable() && gameProgress.gameLevel >= 3;

    const landscapesProgress = landscapeProgress / 10;

    return (
        <div className="flex h-full flex-col justify-center">
            <Panel
                className={`${resultScreenStyles.panel} flex flex-col items-center`}
            >
                <h1>{getLabel('common.great-job')}</h1>
                <div className="my-4 flex w-full justify-around px-4">
                    <div className={`${resultScreenStyles.coinsWrapper}`}>
                        <motion.img
                            src={xpIcon}
                            className={`${resultScreenStyles.icon} h-16 w-16`}
                            animate={animate ? { scale: [1, 1.05, 1] } : {}}
                            transition={animate ? { duration: 0.6 } : {}}
                            alt="XP Icon"
                        />
                        <span
                            className={`${resultScreenStyles.counter} text-white200`}
                        >
                            +
                            <CountUp
                                key={xp}
                                isCounting
                                end={xp}
                                duration={1}
                            />
                        </span>
                    </div>
                    <div className={`${resultScreenStyles.coinsWrapper}`}>
                        <motion.img
                            src={coinIcon}
                            className={`${resultScreenStyles.icon} h-16 w-16`}
                            animate={animate ? { scale: [1, 1.05, 1] } : {}}
                            transition={animate ? { duration: 0.6 } : {}}
                            alt="Coin Icon"
                        />
                        <span
                            className={`${resultScreenStyles.counter} text-white200`}
                        >
                            +
                            <CountUp
                                key={coins}
                                isCounting
                                end={coins}
                                duration={1}
                            />
                        </span>
                    </div>
                </div>
                {isShowLandscapeProgress && (
                    <div className="relative mt-5 flex w-full items-center justify-center">
                        <span className="absolute top-[-5px] text-center font-bold text-white">
                            {Math.min(
                                landscapeProgress,
                                PROGRESS_BAR_MAX_VALUE
                            )}
                            /{PROGRESS_BAR_MAX_VALUE}
                        </span>
                        <ProgressBar
                            initialValue={landscapeProgress}
                            progressBarValue={landscapesProgress}
                            height="h-3"
                            bgColor={COLORS.orange}
                            unfilledColor="bg-white/25"
                            borderColor="border-none"
                        />
                        <img
                            src={landscapeIcon}
                            className="ml-[-16px] h-12 w-12"
                            alt="Landscape Icon"
                        />
                    </div>
                )}
                <div
                    className={`${resultScreenStyles.wordsLearnedView} mt-6 text-center`}
                >
                    <h3>
                        {getLabel('results-screen.learned-words')}
                        {': '}
                        <CountUp
                            key={wordsLearnedCounter}
                            isCounting
                            start={wordsLearnedBefore}
                            end={wordsLearnedCounter}
                            duration={0.5}
                        />
                    </h3>
                </div>
            </Panel>
            {!isUnlockAvailable ? (
                <TextButton
                    onClick={onContinuePress}
                    text={getLabel('button.continue')}
                />
            ) : (
                <TextButton
                    onClick={onUnlockPress}
                    text={getLabel('gifts-progress.unlock-landscape')}
                />
            )}
        </div>
    );
};

export default ResultsScreen;
