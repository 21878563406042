import { type FC, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { commonStyles } from '@/styles/commonStyles';

import { useBackgroundImage } from '../context/BackgroundImageContext';

const BackgroundScreen: FC = () => {
    const { image } = useBackgroundImage();
    const [currentImage, setCurrentImage] = useState<string | null>(null);

    useEffect(() => {
        if (image && image !== currentImage) {
            console.log('Set background image...', image);
            setCurrentImage(image);
        }
    }, [image, currentImage]);

    return (
        <div className={commonStyles.fullScreen}>
            <AnimatePresence>
                {currentImage && (
                    <motion.img
                        key={currentImage}
                        src={currentImage}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            opacity: { duration: 0.5 },
                            ease: 'easeInOut'
                        }}
                        className={`${commonStyles.fullScreen} object-cover`}
                    />
                )}
            </AnimatePresence>
            <div className="absolute inset-0 bg-black bg-opacity-25" />
        </div>
    );
};

export default BackgroundScreen;
