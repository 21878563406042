import { HeaderWithCloseAndBackButton } from '@/components';
import { useLabels, useNavigation, useProfile } from '@/context';

import WordItem from './components/WordItem';

const CategoryInfoScreen = () => {
    const { getLabel } = useLabels();
    const { navigateToProfile, closeProfile } = useNavigation();
    const { currentCategory } = useProfile();

    if (!currentCategory) return null;

    const width = window.innerWidth;

    return (
        <div className="flex h-[100vh] flex-col">
            <HeaderWithCloseAndBackButton
                onClose={() => closeProfile()}
                onBack={() => navigateToProfile('Categories')}
                backTitle={getLabel('common.back')}
            />
            <div
                className="-ml-6 -mt-4 mb-5 flex justify-center py-5"
                style={{
                    backgroundColor: currentCategory.categoryInfo.color,
                    width: width
                }}
            >
                <img
                    src={currentCategory.categoryInfo.imageUrl}
                    alt="Category"
                    className="h-[110px] w-[110px] object-contain"
                />
            </div>
            <h2>{currentCategory.categoryInfo.name}</h2>
            <div className="mt-5 flex-grow overflow-y-auto pb-10">
                <div className="mt-5 pb-10">
                    {currentCategory.categoryInfo.content.map(item => {
                        const learnt =
                            currentCategory.categoryProgressItem.wordProgressItems.some(
                                word => word.wordId === item.id
                            );
                        return (
                            <WordItem
                                key={item.id}
                                item={item}
                                learnt={learnt}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default CategoryInfoScreen;
