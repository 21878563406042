import { createContext, type FC, ReactNode, useContext, useState } from 'react';

import { updateWordsProgressApi } from '@/api/client';
import { WordInfoLocalized } from '@/types/category';
import { CategoryProgressItem } from '@/types/progress';

import { useGameProgress } from './GameProgressContext';
import { useSettings } from './SettingsContext';

interface ReviewGameSessionContextType {
    reviewGameSession: ReviewGameSession;
    completeReviewGameSession: (
        correctWords: WordInfoLocalized[],
        allWords: WordInfoLocalized[]
    ) => Promise<void>;
    updateReviewGameSessionSteps: (
        currentStep: number,
        totalSteps: number
    ) => void;
    resetReviewGameSession: () => void;
}

interface ReviewGameSession {
    correctWords: WordInfoLocalized[];
    foundWords: WordInfoLocalized[];
    currentStep: number;
    totalSteps: number;
}

const NEW_REVIEW_GAME_SESSION = {
    correctWords: [] as WordInfoLocalized[],
    foundWords: [] as WordInfoLocalized[],
    currentStep: 1,
    totalSteps: 3
} as ReviewGameSession;

export const ReviewGameSessionContext = createContext<
    ReviewGameSessionContextType | undefined
>(undefined);

interface ReviewGameSessionProviderProps {
    children: ReactNode;
}

export const ReviewGameSessionProvider: FC<ReviewGameSessionProviderProps> = ({
    children
}) => {
    const { settings } = useSettings();
    const { updateCategoriesProgress, gameProgress, resetCategories } =
        useGameProgress();

    const [reviewGameSession, setReviewSessionProgress] =
        useState<ReviewGameSession>(NEW_REVIEW_GAME_SESSION);

    const completeReviewGameSession = async (
        correctWords: WordInfoLocalized[],
        allWords: WordInfoLocalized[]
    ) => {
        console.log('correct words', correctWords.length);
        console.log('all words', allWords.length);

        setReviewSessionProgress(prev => ({
            ...prev,
            correctWords,
            foundWords: allWords
        }));

        await updateReviewStatus(correctWords);
        await updateCategoriesProgress(settings.studyLanguage, allWords);
        resetCategories();
    };

    const updateReviewGameSessionSteps = (
        currentStep: number,
        totalSteps: number
    ) => {
        setReviewSessionProgress(prev => ({
            ...prev,
            currentStep,
            totalSteps
        }));
    };

    const resetReviewGameSession = () => {
        setReviewSessionProgress(NEW_REVIEW_GAME_SESSION);
    };

    const updateReviewStatus = async (correctWords: WordInfoLocalized[]) => {
        const currentTime = new Date();
        const categoriesProgress = gameProgress.categoriesProgress;
        const updatedCategoryProgressItems: CategoryProgressItem[] = [];

        for (const word of correctWords) {
            const categoryId = word.categoryId;
            const categoryProgress = categoriesProgress.find(
                cp => cp.categoryInfo.id === categoryId
            );

            if (categoryProgress) {
                const wordProgress =
                    categoryProgress.categoryProgressItem.wordProgressItems.find(
                        wpi => wpi.wordId === word.id
                    );

                if (wordProgress) {
                    wordProgress.box++;
                    wordProgress.movedToBoxAt = currentTime;

                    // Collect the updated category progress to be sent in bulk
                    updatedCategoryProgressItems.push(
                        categoryProgress.categoryProgressItem
                    );
                }
            }
        }

        // Send all updated category progress in one API call
        if (updatedCategoryProgressItems.length > 0) {
            await updateWordsProgressApi(
                settings.studyLanguage,
                updatedCategoryProgressItems
            );
        }

        console.log('Review status updated');
    };

    return (
        <ReviewGameSessionContext.Provider
            value={{
                reviewGameSession,
                completeReviewGameSession,
                updateReviewGameSessionSteps,
                resetReviewGameSession
            }}
        >
            {children}
        </ReviewGameSessionContext.Provider>
    );
};

export const useReviewGameSession = () => {
    const context = useContext(ReviewGameSessionContext);
    if (!context) {
        throw new Error(
            'useReviewGameSession must be used within a ReviewGameSessionProvider'
        );
    }
    return context;
};
