import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { CountUp } from 'use-count-up';

import { SoundName } from '@/audio/AudioManager';
import { Panel, TextButton } from '@/components';
import {
    useGameProgress,
    useLabels,
    useNavigation,
    useReviewGameSession,
    useSettings
} from '@/context';
import emitter from '@/events/emitter';
import { resultScreenStyles } from '@/styles/resultScreenStyles';
import { getCleanWord } from '@/utils/language';

import coinIcon from '../../../assets/images/icons/coin.png';
import xpIcon from '../../../assets/images/icons/xp.png';

const ReviewResultsScreen = () => {
    const { getLabel } = useLabels();
    const { navigate } = useNavigation();
    const { addCoins, addXp, isNoWordsToReviewWithSpacedRep } =
        useGameProgress();
    const { reviewGameSession } = useReviewGameSession();
    const { settings, gameConfig } = useSettings();

    const [coins, setCoins] = useState(0);
    const [bonusCoins, setBonusCoins] = useState(0);
    const [xp, setXp] = useState(0);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        const earnedCoins = reviewGameSession.foundWords.reduce(
            (acc, word) =>
                acc + getCleanWord(word.word, settings.studyLanguage).length,
            0
        );

        const bonusCoinsValue = reviewGameSession.correctWords.reduce(
            (acc, word) =>
                acc +
                getCleanWord(word.word, settings.studyLanguage).length * 2,
            0
        );

        const earnedXp = isNoWordsToReviewWithSpacedRep
            ? earnedCoins * gameConfig.xpPerCoinRewardForReviewWithoutSpacedRep
            : earnedCoins * gameConfig.xpPerCoinReward;

        const timeoutCoins = setTimeout(() => {
            emitter.emit('playSound', { sound: SoundName.Coin });
            setCoins(earnedCoins);
            setXp(earnedXp);
            setAnimate(true);
        }, 300);

        const timeoutBonusCoins = setTimeout(() => {
            setBonusCoins(bonusCoinsValue);
        }, 1300);

        return () => {
            clearTimeout(timeoutCoins);
            clearTimeout(timeoutBonusCoins);
        };
    }, [reviewGameSession, settings, gameConfig]);

    useEffect(() => {
        if (animate) {
            const resetAnimation = setTimeout(() => {
                setAnimate(false);
            }, 600);

            return () => clearTimeout(resetAnimation);
        }
    }, [animate]);

    const onContinuePress = () => {
        addCoins(coins + bonusCoins);
        addXp(settings.studyLanguage, xp);
        navigate('ChooseCategory');
    };

    return (
        <div className="flex h-full flex-col justify-center">
            <Panel
                className={`${resultScreenStyles.panel} flex flex-col items-center`}
            >
                <h1>{getLabel('common.great-job')}</h1>
                <div className="my-4 flex w-full justify-around px-4">
                    <div className={`${resultScreenStyles.coinsWrapper}`}>
                        <motion.img
                            src={xpIcon}
                            className={`${resultScreenStyles.icon} h-16 w-16`}
                            animate={animate ? { scale: [1, 1.05, 1] } : {}}
                            transition={animate ? { duration: 0.6 } : {}}
                            alt="XP Icon"
                        />
                        <span
                            className={`${resultScreenStyles.counter} text-white200`}
                        >
                            +
                            <CountUp
                                key={xp}
                                isCounting
                                end={xp}
                                duration={1}
                            />
                        </span>
                    </div>
                    <div className={`${resultScreenStyles.coinsWrapper}`}>
                        <motion.img
                            src={coinIcon}
                            className={`${resultScreenStyles.icon} h-16 w-16`}
                            animate={animate ? { scale: [1, 1.05, 1] } : {}}
                            transition={animate ? { duration: 0.6 } : {}}
                            alt="Coin Icon"
                        />
                        <span
                            className={`${resultScreenStyles.counter} text-white200`}
                        >
                            +
                            <CountUp
                                key={coins}
                                isCounting
                                end={coins}
                                duration={1}
                            />
                        </span>
                    </div>
                </div>
                {reviewGameSession.correctWords.length > 0 && (
                    <div className="mt-4 flex flex-col items-center">
                        <h3>
                            {getLabel(
                                'review-results-screen.bonus-for-correct-answers'
                            )}
                        </h3>
                        <div className="mt-2 flex items-center justify-center">
                            <span className="text-lg font-bold text-white200">
                                <CountUp
                                    key={bonusCoins}
                                    isCounting
                                    end={bonusCoins}
                                    duration={0.5}
                                />
                            </span>
                            <img
                                src={coinIcon}
                                className="mb-1 ml-2 h-6 w-6"
                                alt="Bonus coin Icon"
                            />
                        </div>
                    </div>
                )}
            </Panel>
            <TextButton
                onClick={onContinuePress}
                text={getLabel('button.continue')}
            />
        </div>
    );
};

export default ReviewResultsScreen;
