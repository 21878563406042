import { type FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { motion, useAnimation } from 'framer-motion';

import emitter, {
    AudioHintPlayWordPayload,
    ValidWordSelectedPayload
} from '@/events/emitter';
import { WordInfoLocalized } from '@/types/category';

import {
    BACKGROUND_COLOR,
    BACKGROUND_COLOR_FOUND,
    questionStyles,
    TEXT_COLOR,
    TEXT_COLOR_FOUND
} from './questionStyles';

interface QuestionWithTranslationProps {
    word: WordInfoLocalized;
    onQuestionPress: () => void;
}

const QuestionWithTranslation: FC<QuestionWithTranslationProps> = ({
    word,
    onQuestionPress
}) => {
    const [found, setFound] = useState(false);
    const controls = useAnimation();

    useEffect(() => {
        const handleValidWord = (payload: ValidWordSelectedPayload) => {
            if (payload.wordId !== word.id) return;

            emitter.emit('playWordAudio', { audioUrl: word.audioUrl });
            setFound(true);

            emitter.off('validWordSelected', handleValidWord);
            emitter.emit('correctAnswerGiven', { word });
        };

        const handleAudioHintPlayWord = (payload: AudioHintPlayWordPayload) => {
            if (payload.wordId !== word.id) return;

            controls.start({
                scale: [1, 1.1, 1],
                transition: { duration: 0.6 }
            });

            emitter.emit('playWordAudio', { audioUrl: word.audioUrl });
        };

        emitter.on('validWordSelected', handleValidWord);
        emitter.on('audioHintPlayWord', handleAudioHintPlayWord);

        return () => {
            emitter.off('validWordSelected', handleValidWord);
            emitter.off('audioHintPlayWord', handleAudioHintPlayWord);
        };
    }, [controls, word.id]);

    useEffect(() => {
        controls.start({
            backgroundColor: found ? BACKGROUND_COLOR_FOUND : BACKGROUND_COLOR,
            color: found ? TEXT_COLOR_FOUND : TEXT_COLOR,
            transition: { duration: 0.5 }
        });
    }, [found, controls]);

    return (
        <motion.button
            className={classNames(
                questionStyles.container,
                'relative mx-3 my-1 flex flex-col items-center p-3'
            )}
            onClick={onQuestionPress}
            animate={controls}
        >
            <motion.p
                className={classNames(questionStyles.fontBold)}
                animate={{ color: found ? word.categoryColor : TEXT_COLOR }}
            >
                {word.word}
            </motion.p>
            <motion.p
                className="text-base"
                animate={{ color: found ? TEXT_COLOR_FOUND : TEXT_COLOR }}
            >
                {word.translation}
            </motion.p>
        </motion.button>
    );
};

export default QuestionWithTranslation;
