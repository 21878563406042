import { type FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

interface CrossFadeImageProps {
    src: string;
    alt?: string;
    duration?: number;
    timingFunction?: string;
    delay?: number;
    className?: string;
    containerClass?: string;
}

// TODO: it doesn't work properly when images is changed
const CrossFadeImage: FC<CrossFadeImageProps> = ({
    src,
    alt = '',
    duration = 500,
    timingFunction = 'ease',
    delay = 0,
    className = '',
    containerClass = ''
}) => {
    const [topSrc, setTopSrc] = useState(src);
    const [bottomSrc, setBottomSrc] = useState<string | null>(null);
    const [bottomOpacity, setBottomOpacity] = useState(0);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            setBottomSrc(null);
            firstRender.current = false;
        } else if (src !== topSrc) {
            setBottomSrc(topSrc);
            setTopSrc(src);
            setBottomOpacity(0.99);
            setIsImageLoaded(false);

            timeoutRef.current = setTimeout(() => {
                setBottomOpacity(0);
            }, 20);

            return () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
            };
        }
    }, [src, topSrc]);

    const handleImageLoad = () => {
        setIsImageLoaded(true);
    };

    return (
        <div
            className={`${containerClass} relative`}
            style={{ ...defaultStyle }}
        >
            {topSrc && (
                <img
                    className={classNames(
                        'absolute inset-0 h-full w-full object-cover',
                        className
                    )}
                    style={{
                        ...defaultStyle,
                        opacity: isImageLoaded ? 1 : 0,
                        transition: `opacity ${duration / 1000}s ${timingFunction} ${delay / 1000}s`
                    }}
                    src={topSrc}
                    alt={alt}
                    onLoad={handleImageLoad}
                />
            )}
            {bottomSrc && (
                <img
                    className={classNames(
                        'absolute inset-0 h-full w-full object-cover',
                        className
                    )}
                    style={{
                        ...defaultStyle,
                        opacity: bottomOpacity,
                        transition: `opacity ${duration / 1000}s ${timingFunction} ${delay / 1000}s`
                    }}
                    src={bottomSrc}
                    alt={alt}
                />
            )}
        </div>
    );
};

const defaultStyle: React.CSSProperties = {
    maxWidth: '100%',
    maxHeight: '100%'
};

export default CrossFadeImage;
