import HeaderWithCloseAndBackButton from '@/components/HeaderWithCloseAndBackButton';
import {
    useContent,
    useGameProgress,
    useLabels,
    useNavigation,
    useProfile
} from '@/context';
import { useVibration } from '@/hooks/useVibration';

const LandscapesScreen = () => {
    const { getLabel } = useLabels();
    const { navigateToProfile, closeProfile } = useNavigation();
    const { chooseLandscape } = useProfile();
    const { landscapes } = useContent();
    const { gameProgress } = useGameProgress();
    const { vibrateLight } = useVibration();

    const availableLandscapes = landscapes.filter(landscape =>
        gameProgress.landscapesProgress.find(
            l => l.landscapeId === landscape.id
        )
    );

    const handleLandscapeClick = item => {
        vibrateLight();
        chooseLandscape(item);
        navigateToProfile('LandscapeInfo');
    };

    return (
        <div className="flex h-full flex-col">
            <HeaderWithCloseAndBackButton
                onClose={() => closeProfile()}
                onBack={() => navigateToProfile('Account')}
                backTitle={getLabel('common.back')}
            />
            <h2 className="mb-4 text-2xl font-bold">
                {getLabel('landscapes')}
            </h2>
            {availableLandscapes.length === 0 ? (
                <p className="mt-4 text-sm text-white">
                    {getLabel('profile.no-landscapes')}
                </p>
            ) : (
                <div className="grid flex-grow grid-cols-3 gap-4 overflow-y-scroll">
                    {availableLandscapes.map(item => (
                        <div
                            key={item.id}
                            className="flex cursor-pointer flex-col items-center justify-start"
                            onClick={() => handleLandscapeClick(item)}
                        >
                            <img
                                src={item.thumbnailUrl}
                                alt={item.name}
                                className="h-52 object-contain"
                            />
                            <p className="mt-1 text-center text-sm font-semibold text-white">
                                {item.name}
                            </p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LandscapesScreen;
