import { useRef } from 'react';
import { useSpring } from '@react-spring/web';

import { GridLetterTileHandle } from '@/puzzle/GridLetterTile';

const CURSOR_ANIMATION_MS = 2000;

const useCursorAnimation = () => {
    const cursorRef = useRef(null);

    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        opacity: 0,
        config: { duration: CURSOR_ANIMATION_MS }
    }));

    const moveCursor = (
        firstTileRef: React.RefObject<GridLetterTileHandle>,
        lastTileRef: React.RefObject<GridLetterTileHandle>
    ) => {
        if (
            !firstTileRef?.current ||
            !lastTileRef?.current ||
            !cursorRef.current
        )
            return;

        const parentRect =
            cursorRef.current.parentElement?.getBoundingClientRect();
        if (!parentRect) return;

        firstTileRef.current.measure((firstRect: DOMRect) => {
            const initialX = firstRect.x + firstRect.width / 2 - parentRect.x;
            const initialY = firstRect.y + firstRect.height / 2 - parentRect.y;

            // Set initial position immediately
            api.start({
                x: initialX,
                y: initialY,
                opacity: 1,
                immediate: true
            });

            lastTileRef.current.measure((lastRect: DOMRect) => {
                const targetX = lastRect.x + lastRect.width / 2 - parentRect.x;
                const targetY = lastRect.y + lastRect.height / 2 - parentRect.y;

                // Animate to the target position
                api.start({ x: targetX, y: targetY });

                // Fade out cursor after animation is complete
                setTimeout(() => {
                    api.start({ opacity: 0, config: { duration: 250 } });
                }, CURSOR_ANIMATION_MS);
            });
        });
    };

    const stopCursorAnimation = () => {
        api.start({ opacity: 0, immediate: true }); // Immediately hide the cursor
    };

    return { moveCursor, stopCursorAnimation, cursorRef, style };
};

export default useCursorAnimation;
