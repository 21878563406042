import { useCallback } from 'react';

const useClearLocalStorage = () => {
    const clearStorage = useCallback(() => {
        try {
            localStorage.clear();
            console.log('localStorage has been cleared!');
        } catch (error) {
            console.error('Error clearing localStorage:', error);
        }
    }, []);

    return clearStorage;
};

export default useClearLocalStorage;
