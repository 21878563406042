import { type FC, useState } from 'react';

import LanguageLevelButton from '@/components/buttons/LanguageLevelButton';
import TextButton from '@/components/buttons/TextButton';
import BackIcon from '@/components/icons/BackIcon';
import PanelWithText from '@/components/PanelWithText';
import { useLabels, useNavigation, useSettings } from '@/context';
import { useVibration } from '@/hooks/useVibration';
import { commonStyles } from '@/styles/commonStyles';

const ChooseLanguageLevelScreen: FC = () => {
    const { navigate } = useNavigation();
    const { getLabel } = useLabels();
    const { settings, setLanguageLevel } = useSettings();
    const { vibrateLight } = useVibration();

    const [selectedLanguageLevel, setSelectedLanguageLevel] = useState<
        string | null
    >(null);

    const toggleLevelSelection = (languageLevel: string) => {
        if (selectedLanguageLevel === languageLevel) {
            setSelectedLanguageLevel(null); // Unselect if the same language level is selected
        } else {
            setSelectedLanguageLevel(languageLevel); // Select the new language level
        }
    };

    const onContinuePress = async () => {
        if (!selectedLanguageLevel) return;

        await setLanguageLevel(settings.studyLanguage, selectedLanguageLevel);
        navigate('ContentLoading');
    };

    const onBackPress = () => {
        vibrateLight();
        navigate('ChooseStudyLanguage');
    };

    return (
        <div className={commonStyles.languageSelectionScreen}>
            <button onClick={onBackPress} className="z-10 self-start p-1">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-grey bg-opacity-75 pr-1">
                    <BackIcon width={18} height={18} color="white" />
                </div>
            </button>
            <div className="mb-12 flex flex-1 flex-col justify-center">
                <PanelWithText
                    className="mb-6"
                    text={getLabel('onboarding.how-well-do-you-speak')}
                />
                <div className="mb-4">
                    <LanguageLevelButton
                        levelName={getLabel('level.beginner')}
                        levelDescription={getLabel(
                            'onboarding.i-am-starting-from-scratch'
                        )}
                        onPress={() => toggleLevelSelection('beginner')}
                        isSelected={selectedLanguageLevel === 'beginner'}
                    />
                </div>
                <div className="mb-4">
                    <LanguageLevelButton
                        levelName={getLabel('level.intermediate')}
                        levelDescription={getLabel('common.coming-soon')}
                        onPress={() => toggleLevelSelection('intermediate')}
                        isDisabled={true}
                    />
                </div>
                <div className="mb-4">
                    <LanguageLevelButton
                        levelName={getLabel('level.advanced')}
                        levelDescription={getLabel('common.coming-soon')}
                        onPress={() => toggleLevelSelection('advanced')}
                        isDisabled={true}
                    />
                </div>
            </div>
            <TextButton
                onClick={onContinuePress}
                text={getLabel('button.continue')}
                isDisabled={!selectedLanguageLevel}
            />
        </div>
    );
};

export default ChooseLanguageLevelScreen;
