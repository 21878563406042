import {
    createContext,
    type FC,
    ReactNode,
    useContext,
    useEffect,
    useState
} from 'react';

import { useNavigation } from '@/context';
import { CategoryInfoWithProgress } from '@/types/category';
import { LandscapeInfoLocalized } from '@/types/prize';

interface ProfileContextType {
    chooseCategory: (category: CategoryInfoWithProgress) => void;
    chooseLandscape: (landscape: LandscapeInfoLocalized) => void;
    chooseNewLanguageToStudy: (language: string) => void;
    currentCategory?: CategoryInfoWithProgress;
    currentLandscape?: LandscapeInfoLocalized;
    newLanguageToStudy?: string;
}

interface ProfileProviderProps {
    children: ReactNode;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const ProfileProvider: FC<ProfileProviderProps> = ({ children }) => {
    const { currentProfileScreen } = useNavigation();

    const [currentCategory, setCurrentCategory] =
        useState<CategoryInfoWithProgress>();
    const [currentLandscape, setCurrentLandscape] =
        useState<LandscapeInfoLocalized>();
    const [newLanguageToStudy, setNewLanguageToStudy] = useState<string>();

    useEffect(() => {
        if (currentProfileScreen) return;

        setCurrentCategory(undefined);
        setCurrentLandscape(undefined);
    }, [currentProfileScreen]);

    const chooseCategory = (category: CategoryInfoWithProgress) => {
        setCurrentCategory(category);
    };

    const chooseLandscape = (landscape: LandscapeInfoLocalized) => {
        setCurrentLandscape(landscape);
    };

    const chooseNewLanguageToStudy = (language: string) => {
        setNewLanguageToStudy(language);
    };

    return (
        <ProfileContext.Provider
            value={{
                chooseCategory,
                chooseLandscape,
                chooseNewLanguageToStudy,
                currentCategory,
                currentLandscape,
                newLanguageToStudy
            }}
        >
            {children}
        </ProfileContext.Provider>
    );
};

export const useProfile = () => {
    const context = useContext(ProfileContext);
    if (!context) {
        throw new Error('useProfile must be used within a ProfileProvider');
    }
    return context;
};
