import { type FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useViewport } from '@telegram-apps/sdk-react';
import { setupWorker } from 'msw/browser';

import { BackgroundImageProvider } from '@/context/BackgroundImageContext';
import { ContentProvider } from '@/context/ContentContext';
import { GameProgressProvider } from '@/context/GameProgressContext';
import { LabelsProvider } from '@/context/LabelsContext';
import { NavigationProvider } from '@/context/NavigationContext';
import { NewWordsSessionProvider } from '@/context/NewWordsGameSession';
import { ProfileProvider } from '@/context/ProfileContext';
import AppNavigator from '@/navigation/AppNavigator';

import { handlers } from './api/mockApi';
import { audioManager } from './audio/AudioManager';
import { ReviewGameSessionProvider } from './context/ReviewGameSession';
import { SettingsProvider } from './context/SettingsContext';

// TODO: remove after integration with the real API is complete
const worker = setupWorker(...handlers);

export const App: FC = () => {
    if (process.env.NODE_ENV === 'production') {
        // TODO: move to env variables
        ReactGA.initialize('G-MJ01FV12GR');
    }

    const viewport = useViewport();
    const [mswWorkerIsReady, setMswWorkerIsReady] = useState(false);

    useEffect(() => {
        audioManager.init();
    }, []);

    useEffect(() => {
        if (viewport && !viewport.isExpanded) {
            viewport.expand();
        }
    }, [viewport]);

    useEffect(() => {
        worker
            .start({
                onUnhandledRequest: 'bypass'
            })
            .then(() => {
                setMswWorkerIsReady(true);
            });

        return () => {
            worker.stop();
        };
    }, []);

    if (!mswWorkerIsReady) return;

    return (
        <SettingsProvider>
            <NavigationProvider>
                <ProfileProvider>
                    <LabelsProvider>
                        <ContentProvider>
                            <GameProgressProvider>
                                <NewWordsSessionProvider>
                                    <ReviewGameSessionProvider>
                                        <BackgroundImageProvider>
                                            <AppNavigator />
                                        </BackgroundImageProvider>
                                    </ReviewGameSessionProvider>
                                </NewWordsSessionProvider>
                            </GameProgressProvider>
                        </ContentProvider>
                    </LabelsProvider>
                </ProfileProvider>
            </NavigationProvider>
        </SettingsProvider>
    );
};
