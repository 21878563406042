export class SelectableWord {
    word: string;
    startRow: number;
    startCol: number;
    isVertical: boolean;
    isReversed: boolean;

    constructor(
        word: string,
        startRow: number,
        startCol: number,
        isVertical: boolean,
        isReversed: boolean
    ) {
        this.word = word;
        this.startRow = startRow;
        this.startCol = startCol;
        this.isVertical = isVertical;
        this.isReversed = isReversed;
    }

    toString(): string {
        return `${this.word} row:${this.startRow} col:${this.startCol} isVert:${this.isVertical} isRev:${this.isReversed}`;
    }
}
