import { Random } from '@/utils/Random';

export class Cell {
    x: number;
    y: number;
    letter = '\0';
}

export class Board {
    xCells: number;
    yCells: number;
    grid: Cell[][];
    rand: Random;
    placedVerticals: boolean[];

    undos: Map<string, Undo>[];

    constructor() {
        this.xCells = 0;
        this.yCells = 0;
        this.grid = [];
        this.rand = new Random();
        this.placedVerticals = [];

        this.undos = [];
    }
}

export enum PlacementType {
    Horizontal,
    Vertical,
    ShiftLeft,
    ShiftRight
}

export class Placement {
    cell: Cell;
    type: PlacementType;

    constructor(cell: Cell, type: PlacementType) {
        this.cell = cell;
        this.type = type;
    }
}

export enum UndoType {
    Cell,
    PlacedVertical
}

export abstract class Undo {
    abstract get undoType(): UndoType;
}

export class CellUndo extends Undo {
    cell: Cell;
    letter: string;

    get undoType(): UndoType {
        return UndoType.Cell;
    }
}

export class PVUndo extends Undo {
    index: number;
    value: boolean;

    get undoType(): UndoType {
        return UndoType.PlacedVertical;
    }
}
