import { type FC } from 'react';
import classNames from 'classnames';

type TooltipProps = {
    title: string;
    text: string;
    className?: string;
};

const TooltipContent: FC<TooltipProps> = ({ title, text, className }) => (
    <div
        className={classNames(
            'rounded-2xl bg-white p-3 tracking-wide',
            className
        )}
    >
        <div className="mb-1 text-xl font-bold">{title}</div>
        <div>{text}</div>
    </div>
);

export default TooltipContent;
