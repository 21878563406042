import { useEffect, useState } from 'react';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import { ProgressBar } from '@/components';
import CloseIcon from '@/components/icons/CloseIcon';
import VolumeIcon from '@/components/icons/VolumeIcon';
import { useGameProgress, useLabels, useNavigation } from '@/context';
import emitter from '@/events/emitter';
import useDisableVerticalSwipe from '@/hooks/useDisableVertialSwipe';
import { useVibration } from '@/hooks/useVibration';
import SingleWordGridHandler from '@/puzzle/SingleWordGridHandler';

import WordView from './components/WordView';

import 'swiper/css';

const VERTICAL_TILES_THRESHOLD = 7;

const WordsIntroScreen = () => {
    useDisableVerticalSwipe();

    const { vibrateLight, vibrateMedium } = useVibration();

    const { navigate } = useNavigation();
    const { getLabel } = useLabels();
    const { gameProgress, currentCategory, wordsToStudy } = useGameProgress();

    const [foundWordIndex, setFoundWordIndex] = useState<number | null>(null);
    const [currentWordIndex, updateCurrentWordIndex] = useState(0);
    const [swiper, setSwiper] = useState<SwiperClass>();

    useEffect(() => {
        if (currentWordIndex < 0 || currentWordIndex >= wordsToStudy.length)
            return;

        if (wordsToStudy[currentWordIndex].audioUrl) {
            emitter.emit('playWordAudio', {
                audioUrl: wordsToStudy[currentWordIndex].audioUrl
            });
        }
    }, [currentWordIndex]);

    const onClosePress = () => {
        vibrateLight();
        navigate('ChooseCategory');
    };

    const onAudioPress = () => {
        emitter.emit('playWordAudio', {
            audioUrl: wordsToStudy[currentWordIndex].audioUrl
        });
    };

    const onGoToNextWord = () => {
        const nextIndex = currentWordIndex + 1;

        if (nextIndex === wordsToStudy.length) {
            updateCurrentWordIndex(nextIndex);
            setTimeout(() => {
                const nextScreen =
                    gameProgress.gameLevel === 1 ? 'TimeToPlay' : 'Puzzle';
                navigate(nextScreen);
            }, 750);
        } else {
            setTimeout(() => {
                updateCurrentWordIndex(nextIndex);
                swiper.slideTo(nextIndex, 500);
            }, 750);
        }
    };

    const onWordComplete = () => {
        vibrateMedium();
        setFoundWordIndex(currentWordIndex);
        onGoToNextWord();
    };

    return (
        <div className="flex h-full flex-col overflow-hidden">
            {/* Header */}
            <div className="flex items-center justify-between p-4">
                <p className="uppercase text-white">
                    {getLabel('words-intro.new-words')}
                </p>
                <button className="p-2" onClick={onClosePress}>
                    <CloseIcon width={16} height={16} color="white" />
                </button>
            </div>

            {/* Content */}
            <div className="flex h-full w-full">
                <Swiper
                    pagination={true}
                    navigation={true}
                    className="mySwiper"
                    onSwiper={swiper => setSwiper(swiper)}
                    allowTouchMove={false} // Disable manual sliding
                >
                    {wordsToStudy.map((word, index) => (
                        <SwiperSlide
                            key={word.id}
                            className="flex h-full w-full flex-col items-center justify-center"
                        >
                            <WordView
                                word={word}
                                found={foundWordIndex === index}
                                onClick={onWordComplete}
                            />
                            <SingleWordGridHandler
                                word={word}
                                onWordComplete={onWordComplete}
                                vertical={
                                    word.word.length > VERTICAL_TILES_THRESHOLD
                                }
                                showCursor={
                                    gameProgress.gameLevel === 1 &&
                                    currentWordIndex === 0 &&
                                    index === 0
                                }
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            {/* Footer */}
            <div className="flex items-start justify-between gap-2 p-4">
                <div className="flex basis-1/2 flex-wrap justify-start">
                    <div className="pr-1.5 font-nunito text-sm uppercase tracking-wide text-white">
                        {getLabel('common.topic')}:{' '}
                    </div>
                    <div className="font-nunito text-sm font-bold uppercase tracking-wide text-white">
                        {currentCategory.categoryInfo.name}
                    </div>
                </div>
                <div className="flex flex-1 items-center space-x-2">
                    <p className="text-sm uppercase text-white">
                        {currentWordIndex}/{wordsToStudy.length}
                    </p>
                    <ProgressBar
                        progressBarValue={
                            currentWordIndex / wordsToStudy.length
                        }
                        bgColor={currentCategory.categoryInfo.color}
                        borderColor="border-none"
                        unfilledColor="bg-grey600"
                        height="h-2.5"
                    />
                    <button onClick={onAudioPress} className="pl-2 pr-1">
                        <VolumeIcon width={18} height={18} color="white" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WordsIntroScreen;
