export const COLORS = {
    white: '#FFFFFF',
    white200: '#FFFDFA',
    white500: '#FAF9F6',
    black: '#000000',
    blue: '#4FC0E8',
    grey: '#242424',
    grey50: '#E5E5E5',
    grey100: '#E1E1E1',
    grey300: '#CCD0D9',
    grey400: '#AAB2BD',
    grey500: '#48535E',
    grey600: '#4B4B4B',
    grey700: '#2C2C2C',
    orange: '#FAAC38',
    green: '#9ED36F',
    greenCyan: '#8DC9BF'
};
