import { Panel, TextButton } from '@/components';
import { useLabels, useNavigation, useSettings } from '@/context';

const HelloScreen = () => {
    const { settings } = useSettings();
    const { getLabel } = useLabels();
    const { navigate } = useNavigation();

    const title = getLabel(
        `onboarding.${settings.studyLanguage}-welcome-title`
    );
    const text = getLabel(`onboarding.${settings.studyLanguage}-welcome-text`);
    const startLearning = getLabel(`common.start-learning`, true);

    const onContinuePress = () => {
        navigate('ChooseCategory');
    };

    return (
        <div className="flex h-full flex-col justify-center">
            <Panel className="mb-6">
                <h1 className="mb-4">{title}</h1>
                <p className="text-lg text-white">{text}</p>
            </Panel>
            <TextButton onClick={onContinuePress} text={startLearning} />
        </div>
    );
};

export default HelloScreen;
