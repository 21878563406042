import { useEffect } from 'react';
import { useSwipeBehavior } from '@telegram-apps/sdk-react';

const useDisableVerticalSwipe = () => {
    const swipeBehavior = useSwipeBehavior();

    useEffect(() => {
        swipeBehavior.disableVerticalSwipe();

        return () => {
            swipeBehavior.enableVerticalSwipe();
        };
    }, [swipeBehavior]);
};

export default useDisableVerticalSwipe;
