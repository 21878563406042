import { type FC, useMemo } from 'react';

import GridHandler from '@/puzzle/GridHandler';
import { BoardData } from '@/puzzle/types/BoardData';
import { LevelData } from '@/puzzle/types/LevelData';
import { LevelSaveData } from '@/puzzle/types/LevelSaveData';

import { PuzzleData } from './types';

interface PuzzleBoardProps {
    puzzleData: PuzzleData;
    studyLanguage: string;
    previousBoardData?: BoardData;
    onBoardDataReady?: (boardData: BoardData) => void;
    showCursor: boolean;
}

const PuzzleBoard: FC<PuzzleBoardProps> = ({
    studyLanguage,
    puzzleData,
    previousBoardData,
    onBoardDataReady,
    showCursor
}) => {
    const levelData = useMemo(
        () => new LevelData(puzzleData.wordsToPlay, studyLanguage),
        [puzzleData.wordsToPlay, studyLanguage]
    );

    const levelSaveData = useMemo(
        () => new LevelSaveData(levelData.Words),
        [levelData]
    );

    return (
        <div className="flex flex-1 justify-center">
            <GridHandler
                puzzleType={puzzleData.puzzleType}
                selectedTileColor={puzzleData.selectedTileColor}
                levelData={levelData}
                levelSaveData={levelSaveData}
                previousBoardData={previousBoardData}
                onBoardDataReady={onBoardDataReady}
                showCursor={showCursor}
            />
        </div>
    );
};

export default PuzzleBoard;
