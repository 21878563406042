import { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { MdSearch } from 'react-icons/md';
import Fuse, { FuseResult } from 'fuse.js';
import { CountUp } from 'use-count-up';

import coinIcon from '../../assets/images/icons/coin.png';
import ToggleSwitch from '../components/buttons/ToggleSwitch';
import HeaderWithCloseButton from '../components/HeaderWithCloseButton';
import { useGameProgress } from '../context/GameProgressContext';
import { useLabels } from '../context/LabelsContext';
import { useNavigation } from '../context/NavigationContext';
import { CategoryInfoWithProgress } from '../types/category';

import { CategoryCard } from './choose-category/components/CategoryCard';

const ChooseAnotherCategoryScreen: React.FC = () => {
    const [isUnlockedFilterEnabled, setIsUnlockedFilterEnabled] =
        useState(false);

    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState<
        FuseResult<CategoryInfoWithProgress>[]
    >([]);

    const { navigate } = useNavigation();
    const { gameProgress, chooseCategory } = useGameProgress();
    const { getLabel } = useLabels();

    const [previousCoins, setPreviousCoins] = useState(gameProgress.coins);
    const [coins, setCoins] = useState(gameProgress.coins);

    useEffect(() => {
        setCoins(gameProgress.coins);
    }, [gameProgress.coins]);

    const onCoinsCounterComplete = () => {
        setPreviousCoins(gameProgress.coins);
        setCoins(gameProgress.coins);
    };

    const onChooseCategory = (category: CategoryInfoWithProgress) => {
        // posthog.capture('another_category_chosen', {
        //     category_id: category.categoryInfo.id,
        //     category_name: category.categoryInfo.name
        // });
        chooseCategory(category);
        navigate('WordsIntro');
    };

    const renderCategoryItem = ({
        item
    }: {
        item: CategoryInfoWithProgress;
    }) => (
        <CategoryCard
            category={item}
            onChooseCategory={onChooseCategory}
            insideList
        />
    );

    const onClose = () => {
        navigate('ChooseCategory');
    };

    const sortAlphabetically = (
        a: CategoryInfoWithProgress,
        b: CategoryInfoWithProgress
    ) => {
        const nameA = a.categoryInfo.name.toLowerCase();
        const nameB = b.categoryInfo.name.toLowerCase();

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    };

    const isNotCompleted = (item: CategoryInfoWithProgress) => {
        return (
            item.categoryProgressItem.wordProgressItems.length <
            item.categoryInfo.content.length
        );
    };

    const isUnlockedIfFilterEnabled = (item: CategoryInfoWithProgress) => {
        if (!isUnlockedFilterEnabled) return true;
        return (
            item.categoryInfo.startFriendly ||
            item.categoryProgressItem.unlocked
        );
    };

    const fuseOptions = {
        keys: ['categoryInfo.name'],
        threshold: 0.3
    };

    const fuse = new Fuse(gameProgress.categoriesProgress, fuseOptions);

    useEffect(() => {
        const results = fuse.search(searchQuery);
        setSearchResults(results);
    }, [searchQuery]);

    const filterAndSort = (categories: CategoryInfoWithProgress[]) => {
        return categories
            .filter(
                item =>
                    item.categoryInfo.languageLevel === 'Beginner' &&
                    isNotCompleted(item) &&
                    isUnlockedIfFilterEnabled(item)
            )
            .sort(sortAlphabetically);
    };

    const allTopicCompleted =
        filterAndSort(gameProgress.categoriesProgress).length === 0;

    const data = filterAndSort(
        searchQuery
            ? searchResults.map(item => item.item)
            : gameProgress.categoriesProgress
    );

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    return (
        <div className="mt-5 px-4">
            <HeaderWithCloseButton title="Topics" onClose={onClose} />
            <div className="mb-5 flex items-center">
                <div className="mr-2 flex h-10 flex-grow items-center rounded-full bg-white/5 p-2">
                    <IconContext.Provider
                        value={{
                            size: '20px',
                            className: 'text-white/60 mr-2'
                        }}
                    >
                        <MdSearch />
                    </IconContext.Provider>
                    <input
                        type="text"
                        placeholder={getLabel(
                            'common.search-vocabulary-topics'
                        )}
                        value={searchQuery}
                        onChange={handleInputChange}
                        className="flex-1 bg-transparent text-white focus:outline-none"
                    />
                </div>
                <div className="flex items-center justify-center rounded-3xl bg-white/5 px-1">
                    <img src={coinIcon} alt="Coin Icon" className="h-10 w-10" />
                    <span className="text-md px-3 font-bold text-white">
                        <CountUp
                            key={coins}
                            isCounting
                            start={previousCoins}
                            end={coins}
                            duration={1.5}
                            onComplete={onCoinsCounterComplete}
                        />
                    </span>
                </div>
            </div>
            <div className="mb-5 flex items-center justify-end gap-4">
                <span className="text-white">
                    {getLabel('category.show-only-unlocked-topics')}
                </span>
                <ToggleSwitch
                    isEnabled={isUnlockedFilterEnabled}
                    setIsEnabled={setIsUnlockedFilterEnabled}
                />
            </div>
            {allTopicCompleted && (
                <span className="mb-5 text-white">
                    {getLabel('categories.all-completed')}
                </span>
            )}
            {!allTopicCompleted && (
                <div className="grid h-[calc(100vh-200px)] w-full auto-rows-max grid-cols-3 gap-6 overflow-y-scroll pb-16">
                    {data.map(item => (
                        <div key={item.categoryInfo.id} className="w-full">
                            {renderCategoryItem({ item })}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ChooseAnotherCategoryScreen;
