import { type FC } from 'react';
import { IconContext } from 'react-icons';
import { MdEdit } from 'react-icons/md';
import classNames from 'classnames';

import AnimatedButton from '@/components/buttons/AnimatedButton';
import { useLabels, useSettings } from '@/context';
import { useVibration } from '@/hooks/useVibration';

type ChangeUILanguageButtonProps = {
    onPress: () => void;
};

const ChangeUILanguageButton: FC<ChangeUILanguageButtonProps> = ({
    onPress
}) => {
    const { getLabel } = useLabels();
    const { settings } = useSettings();
    const { vibrateLight } = useVibration();

    const handlePress = () => {
        vibrateLight();
        onPress();
    };

    return (
        <AnimatedButton
            onPress={handlePress}
            showOverlay={false}
            className={classNames('mb-10 rounded-2xl px-5 py-2.5')}
        >
            <div className="relative flex items-center pr-6">
                <span className="font-nunito text-grey500 xs:text-lg">
                    {getLabel('onboarding.courses-available-in')}
                </span>
                <span className="ml-2 mr-1 pt-0.5 font-bold uppercase text-grey500">
                    {getLabel(`language.${settings.uiLanguage}`)}
                </span>
                <IconContext.Provider
                    value={{
                        size: '20px',
                        className: 'absolute right-0 text-grey500'
                    }}
                >
                    <MdEdit />
                </IconContext.Provider>
            </div>
        </AnimatedButton>
    );
};

export default ChangeUILanguageButton;
