import { LevelData } from './types/LevelData';
import { LevelSaveData } from './types/LevelSaveData';
import { SelectableWord } from './types/SelectableWord';

// Find the list of selectable words on the provided board
function findSelectableWords(
    activeLevelData: LevelData,
    activeLevelSaveData: LevelSaveData
): SelectableWord[] {
    const selectableWords: SelectableWord[] = [];

    const remainingWords = new Map<string, string[]>();
    const remainingWordsReversed = new Map<string, string[]>();

    // Get a list of words that haven't been found yet
    activeLevelData.words.forEach(word => {
        if (!activeLevelSaveData.foundLevelWords.has(word)) {
            // Add the word to remainingWords using the first letter as the key
            const firstLetter = word[0];

            if (!remainingWords.has(firstLetter)) {
                remainingWords.set(firstLetter, []);
            }

            remainingWords.get(firstLetter).push(word);

            // Add the word to remainingWordsReversed using the last letter as the key
            const lastLetter = word[word.length - 1];

            if (!remainingWordsReversed.has(lastLetter)) {
                remainingWordsReversed.set(lastLetter, []);
            }

            remainingWordsReversed.get(lastLetter).push(word);
        }
    });

    // Look for those words on the board
    for (let row = 0; row < activeLevelSaveData.boardData.rows; row++) {
        for (let col = 0; col < activeLevelSaveData.boardData.cols; col++) {
            const letter = activeLevelSaveData.boardData.board[row][col];

            let wordStart = remainingWords.has(letter);
            let reverseWordStart = remainingWordsReversed.has(letter);

            if (wordStart || reverseWordStart) {
                // Check horizontally for any word
                const selectableWordsHorizontal = checkForWords(
                    activeLevelSaveData,
                    row,
                    col,
                    wordStart ? remainingWords : null,
                    reverseWordStart ? remainingWordsReversed : null,
                    false
                );

                // Update the values because we might have found a word which would remove it from the dictionary
                wordStart = remainingWords.has(letter);
                reverseWordStart = remainingWordsReversed.has(letter);

                let selectableWordsVertical: SelectableWord[] = [];
                if (wordStart || reverseWordStart) {
                    // Check vertically for any word
                    selectableWordsVertical = checkForWords(
                        activeLevelSaveData,
                        row,
                        col,
                        wordStart ? remainingWords : null,
                        reverseWordStart ? remainingWordsReversed : null,
                        true
                    );
                }

                selectableWords.push(
                    ...selectableWordsHorizontal,
                    ...selectableWordsVertical
                );
            }
        }
    }

    return selectableWords;
}

// Checks for any of the words in remainingWords or remainingWordsReversed whose first letter is the letter at the given row/col on the board. This method
// assumes that the letter at row/col on the board has an entry in either remainingWords or remainingWordsReversed
function checkForWords(
    activeLevelSaveData: LevelSaveData,
    row: number,
    col: number,
    remainingWords: Map<string, string[]> | null,
    remainingWordsReversed: Map<string, string[]> | null,
    isVertical: boolean
): SelectableWord[] {
    const selectableWords: SelectableWord[] = [];

    const firstLetter = activeLevelSaveData.boardData.board[row][col];
    const words = remainingWords
        ? [...(remainingWords.get(firstLetter) || [])]
        : null;
    const wordsReversed = remainingWordsReversed
        ? [...(remainingWordsReversed.get(firstLetter) || [])]
        : null;

    const startRow = row;
    const startCol = col;
    const colInc = isVertical ? 0 : 1;
    const rowInc = isVertical ? 1 : 0;

    for (
        let c = startCol, r = startRow;
        c < activeLevelSaveData.boardData.cols &&
        r < activeLevelSaveData.boardData.rows;
        c += colInc, r += rowInc
    ) {
        const boardLetter = activeLevelSaveData.boardData.board[r][c];
        const letterIndex = isVertical ? r - row : c - col;

        // Check for normal words first
        if (words) {
            const [found, wordOnBoard] = checkWords(
                words,
                boardLetter,
                letterIndex,
                false
            );
            if (found) {
                const selectableWord = new SelectableWord(
                    wordOnBoard,
                    startRow,
                    startCol,
                    isVertical,
                    false
                );
                selectableWords.push(selectableWord);

                removeWordHelper(wordOnBoard, firstLetter, remainingWords);
                removeWordHelper(
                    wordOnBoard,
                    firstLetter,
                    remainingWordsReversed
                );
                break;
            }
        }

        // Do the same for reversed words
        if (wordsReversed) {
            const [found, wordOnBoard] = checkWords(
                wordsReversed,
                boardLetter,
                letterIndex,
                true
            );
            if (found) {
                const selectableWord = new SelectableWord(
                    wordOnBoard,
                    startRow,
                    startCol,
                    isVertical,
                    true
                );
                selectableWords.push(selectableWord);

                removeWordHelper(wordOnBoard, firstLetter, remainingWords);
                removeWordHelper(
                    wordOnBoard,
                    firstLetter,
                    remainingWordsReversed
                );
                break;
            }
        }

        // Stop if there are no valid words to check
        if (
            (!words || words.length === 0) &&
            (!wordsReversed || wordsReversed.length === 0)
        ) {
            break;
        }
    }

    return selectableWords;
}

// Simple helper method to remove the word from the dictionary (And remove the list or words if it's now empty)
function removeWordHelper(
    word: string,
    letter: string,
    remainingWords: Map<string, string[]> | null
): void {
    if (remainingWords && remainingWords.has(letter)) {
        const wordsList = remainingWords.get(letter);
        if (wordsList) {
            const index = wordsList.indexOf(word);
            if (index !== -1) {
                wordsList.splice(index, 1);

                if (wordsList.length === 0) {
                    remainingWords.delete(letter);
                }
            }
        }
    }
}

// Goes through each word and checks if the boardLetter matches the letter at the given letterIndex, if not it removes it from the words list. If
// the letter matches and its the last letter in the list then we return true and that word
function checkWords(
    words: string[],
    boardLetter: string,
    letterIndex: number,
    reverse: boolean
): [boolean, string | null] {
    for (let i = 0; i < words.length; i++) {
        const word = words[i];
        const wordLetter = reverse
            ? word[word.length - letterIndex - 1]
            : word[letterIndex];

        if (boardLetter !== wordLetter) {
            words.splice(i, 1); // Remove the word from the list
            i--; // Adjust the index to account for the removed item
            continue;
        }

        if (letterIndex === word.length - 1) {
            // If it's the last letter and it matches, return the word
            return [true, word];
        }
    }

    return [false, null]; // Return false with no word if no match is found
}

export { findSelectableWords };
