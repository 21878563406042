import { type FC } from 'react';
import classNames from 'classnames';

import RoundFlag from '@/components/RoundFlag';
import { useLabels } from '@/context';
import { useVibration } from '@/hooks/useVibration';
import { Language } from '@/utils/languages';

import AnimatedButton from './AnimatedButton';

interface LanguageButtonProps {
    language: Language;
    isSelected: boolean;
    onPress: (languageCode: string) => void;
}

const LanguageButton: FC<LanguageButtonProps> = ({
    language,
    isSelected,
    onPress
}) => {
    // TODO: pass label as prop instead of using context
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();

    const handlePress = () => {
        vibrateLight();
        onPress(language.languageCode);
    };

    return (
        <AnimatedButton
            onPress={handlePress}
            isSelected={isSelected}
            className={classNames('flex items-center rounded-2xl', {
                'bg-white': !isSelected,
                'bg-orange': isSelected
            })}
        >
            <div
                className="absolute rounded-full bg-grey100 p-0.5"
                style={{ left: '8px' }}
            >
                <RoundFlag isoCode={language.languageCode} size={25} />
            </div>

            <div className="w-full flex-1 text-center">
                <p
                    className={classNames('text-sm font-extrabold uppercase', {
                        'text-grey500': !isSelected,
                        'text-white': isSelected
                    })}
                >
                    {getLabel(`language.${language.languageCode}`)}
                </p>
                <p
                    className={classNames('text-xs font-bold uppercase', {
                        'text-grey500': !isSelected,
                        'text-white': isSelected
                    })}
                >
                    {language.languageNativeName.toUpperCase()}
                </p>
            </div>
        </AnimatedButton>
    );
};

export default LanguageButton;
