import { type FC } from 'react';
import classNames from 'classnames';

import AnimatedButton from '@/components/buttons/AnimatedButton';
import { useVibration } from '@/hooks/useVibration';

interface LanguageLevelButtonProps {
    levelName: string;
    levelDescription: string;
    isSelected?: boolean;
    isDisabled?: boolean;
    onPress: () => void;
}

const LanguageLevelButton: FC<LanguageLevelButtonProps> = ({
    levelName,
    levelDescription,
    isSelected,
    isDisabled,
    onPress
}) => {
    const { vibrateLight } = useVibration();

    const handlePress = () => {
        vibrateLight();
        onPress();
    };

    return (
        <AnimatedButton
            onPress={handlePress}
            isSelected={isSelected}
            isDisabled={isDisabled}
        >
            <div className="flex-1 text-center">
                <p
                    className={classNames(
                        'font-extrabold uppercase tracking-wide',
                        {
                            'text-grey500': !isSelected,
                            'text-white': isSelected
                        }
                    )}
                >
                    {levelName}
                </p>
                <p
                    className={classNames('text-xs font-bold uppercase', {
                        'text-grey500': !isSelected,
                        'text-white': isSelected
                    })}
                >
                    {levelDescription}
                </p>
            </div>
        </AnimatedButton>
    );
};

export default LanguageLevelButton;
