import { type FC } from 'react';
import classNames from 'classnames';

import { useVibration } from '@/hooks/useVibration';

interface ToggleSwitchProps {
    isEnabled: boolean;
    setIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToggleSwitch: FC<ToggleSwitchProps> = ({ isEnabled, setIsEnabled }) => {
    const { vibrateLight } = useVibration();

    const toggleSwitch = () => {
        vibrateLight();
        setIsEnabled(previousState => !previousState);
    };

    return (
        <label className="relative inline-flex cursor-pointer items-center">
            <input
                type="checkbox"
                checked={isEnabled}
                onChange={toggleSwitch}
                className="peer sr-only"
            />
            <div
                className={classNames(
                    'h-6 w-11 rounded-full transition-colors duration-200 ease-in-out',
                    isEnabled ? 'bg-green' : 'bg-grey300'
                )}
            />
            <div
                className={classNames(
                    'absolute left-0.5 top-0.5 h-5 w-5 transform rounded-full bg-white transition-transform duration-200 ease-in-out',
                    isEnabled && 'translate-x-full'
                )}
            />
        </label>
    );
};

export default ToggleSwitch;
