const removeArticleFromText = (text: string, articles: string[]): string => {
    for (const article of articles.filter(a => a.endsWith("'"))) {
        if (text.toLowerCase().startsWith(article.toLowerCase())) {
            return text.substring(article.length);
        }
    }

    const words = text.split(' ');

    if (
        words.length > 0 &&
        articles.some(
            article => words[0].toLowerCase() === article.toLowerCase()
        )
    ) {
        return words.slice(1).join(' ');
    }

    return text;
};

const removeSpanishArticle = (text: string): string =>
    removeArticleFromText(text, ['el', 'la', 'los', 'las']);
const removeEnglishArticle = (text: string): string =>
    removeArticleFromText(text, ['to']); // Adjust according to your use case
const removeItalianArticle = (text: string): string =>
    removeArticleFromText(text, ['il', "l'", 'i', 'gli', 'la', 'le']);
const removeGermanArticle = (text: string): string =>
    removeArticleFromText(text, ['der', 'die', 'das']);
const removeFrenchArticle = (text: string): string =>
    removeArticleFromText(text, ['le', 'la', "l'", 'les']);

const removeArticle = (originalWord: string, studyLanguage: string): string => {
    switch (studyLanguage) {
        case 'es':
            return removeSpanishArticle(originalWord);
        case 'en':
            return removeEnglishArticle(originalWord);
        case 'it':
            return removeItalianArticle(originalWord);
        case 'de':
            return removeGermanArticle(originalWord);
        case 'fr':
            return removeFrenchArticle(originalWord);
        default:
            return originalWord;
    }
};

const getCleanWord = (originalWord: string, languageCode: string): string => {
    const wordWithoutArticle = removeArticle(originalWord, languageCode);
    return wordWithoutArticle.replace(/\P{L}/gu, '');
};

const toUpperCaseLetter = (letter: string): string => {
    if (letter === 'ß') {
        return 'ẞ';
    }
    return letter.toUpperCase();
};

const toUpperCaseWord = (word: string): string => {
    if (!word) return '';
    return word.split('').map(toUpperCaseLetter).join('');
};

function isPunctuation(char: string) {
    return /[.,/#!$%^&*;:{}=\-_`~()]/g.test(char);
}

export {
    getCleanWord,
    isPunctuation,
    removeArticle,
    toUpperCaseLetter,
    toUpperCaseWord
};
