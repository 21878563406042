import { WordInfoLocalized } from '../../types/category';

export type PuzzleData = {
    puzzleType: PuzzleType;
    wordsToPlay: WordInfoLocalized[];
    selectedTileColor: string;
};

export enum PuzzleType {
    BasicWithTranslation,
    BasicWithoutTranslation,
    ChooseTranslation,
    Unknown
}

export function getPuzzleTypeByStepNumber(value: number): PuzzleType {
    switch (value) {
        case 1:
            return PuzzleType.BasicWithTranslation;
        case 2:
            return PuzzleType.BasicWithoutTranslation;
        case 3:
        case 4:
            return PuzzleType.ChooseTranslation;
        default:
            return PuzzleType.Unknown;
    }
}
