import { rgba } from 'polished';

import { COLORS } from '../../../utils/colors';

export const BACKGROUND_COLOR = COLORS.grey;
export const BACKGROUND_COLOR_FOUND = rgba(COLORS.white200, 0.8);
export const TEXT_COLOR = COLORS.white200;
export const TEXT_COLOR_FOUND = COLORS.grey500;

export const questionStyles = {
    container: 'm-1.5 py-1.5 px-3 flex items-center rounded-2xl',
    fontBold: 'font-bold text-base text-gray-200'
};
