import { type FC, useEffect, useState } from 'react';

import { SoundName } from '@/audio/AudioManager';
import ModalInfo from '@/components/ModalInfo';
import { useLabels } from '@/context';
import emitter from '@/events/emitter';
import { WordInfoLocalized } from '@/types/category';

import QuestionWithoutTranslation from '../questions/QuestionWithoutTranslation';
import QuestionWithTranslation from '../questions/QuestionWithTranslation';
import { PuzzleType } from '../types';

import { questionPanelStyles } from './questionsPanelStyles';

interface FindWordQuestionsPanelProps {
    words: WordInfoLocalized[];
    puzzleType: PuzzleType;
    studyLanguage: string;
}

const FindWordQuestionsPanel: FC<FindWordQuestionsPanelProps> = ({
    words,
    puzzleType,
    studyLanguage
}) => {
    const { getLabel } = useLabels();

    const [selectedWord, setSelectedWord] = useState('');
    const [isShowInstructions, setIsShowInstructions] = useState(false);

    useEffect(() => {
        const handleWordSelectionChanged = ({ word }) => {
            setSelectedWord(word);
        };

        emitter.on('wordSelectionChanged', handleWordSelectionChanged);

        return () => {
            emitter.off('wordSelectionChanged', handleWordSelectionChanged);
        };
    }, []);

    const onQuestionPress = () => {
        emitter.emit('playSound', { sound: SoundName.Card });
        setIsShowInstructions(true);
    };

    const renderBasicQuestionsWithTranslations = () => {
        return words.map(word => (
            <QuestionWithTranslation
                key={word.id}
                word={word}
                onQuestionPress={onQuestionPress}
            />
        ));
    };

    const renderBasicQuestionsWithoutTranslations = () => {
        return words.map(word => (
            <QuestionWithoutTranslation
                key={word.id}
                word={word}
                studyLanguage={studyLanguage}
                onQuestionPress={onQuestionPress}
            />
        ));
    };

    const renderQuestions = () => {
        if (puzzleType === PuzzleType.BasicWithTranslation) {
            return renderBasicQuestionsWithTranslations();
        } else if (puzzleType === PuzzleType.BasicWithoutTranslation) {
            return renderBasicQuestionsWithoutTranslations();
        }
    };

    return (
        <>
            <div className={questionPanelStyles.container}>
                <div className={questionPanelStyles.questionsContainer}>
                    {renderQuestions()}
                </div>
                <div className={questionPanelStyles.selectedWordContainer}>
                    {selectedWord && (
                        <div
                            className={questionPanelStyles.selectedWordWrapper}
                        >
                            <p className={questionPanelStyles.selectedWord}>
                                {selectedWord}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <ModalInfo
                isVisible={isShowInstructions}
                onClose={() => setIsShowInstructions(false)}
                description={getLabel('puzzle.find-words')}
            />
        </>
    );
};

export default FindWordQuestionsPanel;
