export const commonStyles = {
    fullScreen: 'absolute inset-0 h-full w-full flex flex-col',
    centeredContent:
        'relative flex h-screen flex-col items-center justify-center',
    closeButton: 'self-end text-white p-4 pr-2 z-10',
    fontRegular: 'text-white200 font-nunito text-lg',
    languageSelectionButtonView: 'mb-4',
    languageSelectionScreen: 'flex flex-1 flex-col mb-2.5 h-full',
    panelWrapper: 'mb-6 w-full',
    modalPanel: 'bg-black bg-opacity-85 rounded-2xl p-5 w-full',
    backgroundOverlay: 'absolute inset-0 bg-black bg-opacity-25',
    tooltipTitle: 'text-grey600 font-nunito font-extrabold text-lg mb-1',
    tooltipText: 'text-grey600 font-sans font-semibold text-base',
    tooltipContent: 'rounded-xl mb-1',
    tooltipView: 'p-0.5'
};
