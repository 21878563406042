import { useState } from 'react';

import { SoundName } from '@/audio/AudioManager';
import {
    Dialogue,
    HeaderWithCloseAndBackButton,
    LanguageLevelButton,
    TextButton
} from '@/components';
import { useLabels, useNavigation, useProfile, useSettings } from '@/context';
import emitter from '@/events/emitter';

const ChooseNewLanguageLevelScreen = () => {
    const { getLabel } = useLabels();
    const { newLanguageToStudy } = useProfile();
    const { closeProfile, navigate, navigateToProfile } = useNavigation();
    const { setStudyLanguage, setLanguageLevel, addNewStudyLanguage } =
        useSettings();
    const [selectedLanguageLevel, setSelectedLanguageLevel] = useState<
        string | null
    >(null);
    const [isDialogueVisible, setIsDialogueVisible] = useState(false);

    const toggleLevelSelection = (languageLevel: string) => {
        if (selectedLanguageLevel === languageLevel) {
            setSelectedLanguageLevel(null); // Unselect if the same language level is selected
        } else {
            setSelectedLanguageLevel(languageLevel); // Select the new language level
        }
    };

    const onContinuePress = () => {
        if (!selectedLanguageLevel) return;

        setIsDialogueVisible(true);
    };

    const onAddLanguage = async () => {
        if (!selectedLanguageLevel) return;

        await setStudyLanguage(newLanguageToStudy);
        await addNewStudyLanguage(newLanguageToStudy);
        await setLanguageLevel(newLanguageToStudy, selectedLanguageLevel);
        navigate('Splash');
    };

    const onDialogueClose = () => {
        setIsDialogueVisible(false);
    };

    const handleLanguageLevelPress = (level: string) => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        toggleLevelSelection(level);
    };

    return (
        <>
            <div>
                <HeaderWithCloseAndBackButton
                    backTitle=""
                    onBack={() => navigateToProfile('AddStudyLanguage')}
                    onClose={() => closeProfile()}
                />
                <h2>{getLabel('change-language.choose-language-level')}</h2>
                <div className="mb-6 mt-10 flex flex-col gap-6">
                    <LanguageLevelButton
                        levelName={getLabel('level.beginner')}
                        levelDescription={getLabel(
                            'onboarding.i-am-starting-from-scratch'
                        )}
                        onPress={() => handleLanguageLevelPress('beginner')}
                        isSelected={selectedLanguageLevel === 'beginner'}
                    />
                    <LanguageLevelButton
                        levelName={getLabel('level.intermediate')}
                        levelDescription={getLabel('common.coming-soon')}
                        onPress={() => handleLanguageLevelPress('intermediate')}
                        isDisabled={true}
                    />
                    <LanguageLevelButton
                        levelName={getLabel('level.advanced')}
                        levelDescription={getLabel('common.coming-soon')}
                        onPress={() => handleLanguageLevelPress('advanced')}
                        isDisabled={true}
                    />
                </div>
            </div>
            <TextButton
                onClick={onContinuePress}
                text={getLabel('button.continue')}
                isDisabled={!selectedLanguageLevel}
                className="w-full"
            />
            <Dialogue
                isVisible={isDialogueVisible}
                onButton1Press={onAddLanguage}
                onButton2Press={onDialogueClose}
                onClose={onDialogueClose}
                title={getLabel('change-language.add-language')}
                text={getLabel('change-language.start-new-language')}
                button1Text={getLabel('button.yes')}
                button2Text={getLabel('button.no')}
                outline
            />
        </>
    );
};

export default ChooseNewLanguageLevelScreen;
